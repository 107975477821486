import { ErrorResponse } from '@common/api/auth';
import { getHotelInboundLogs, downloadInboundLogs } from '@common/api/hotel';
import { HotelInboundApiLogsPagination } from '@common/api/hotel/types';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

type Params = {
  page: number;
  search?: string;
  date?: string;
  ordering?: string;
  category?: string;
};

export const useGetInboundApiLogs = ({ page = 1, search, date, ordering, category }: Params) => {
  const { hotelAuthToken } = useHotelStore();
  const { addNotification } = useNotificationsStore();

  const query = useQuery<HotelInboundApiLogsPagination, ErrorResponse>({
    queryKey: [HotelQueryKeys.GET_HOTEL_INBOUND_API_LOGS, page, search, date, ordering, category],
    queryFn: () => {
      return getHotelInboundLogs(page, search, date, ordering, category);
    },
    enabled: !!hotelAuthToken
  });

  if (query.isError) addNotification('fail', 'Failed to get hotel inbound API logs');
  const hotelInboundApiLogs = useMemo(() => {
    return query.data;
  }, [query.data]);

  const refetchLogs = async () => {
    await query.refetch();
    addNotification('success', 'Inbound API logs have been updated');
  };

  useEffect(() => {
    query.refetch();
  }, [ordering]);

  const isRefetchLoading = query.isLoading || query.isFetching;

  return { hotelInboundApiLogs, query, refetchLogs, isRefetchLoading };
};

export const useDownloadInboundApiLogs = () => {
  const { addNotification } = useNotificationsStore();

  const downloadLogs = async (id: string) => {
    return downloadInboundLogs(id);
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async (id: string) => {
      const response = await downloadLogs(id);
      return { response, id };
    },
    onSuccess: ({ response, id }) => {
      const fileName = `${id}-metadata.json`;
      const blob = new Blob([JSON.stringify(response)], { type: 'application/json' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onError: () => {
      addNotification('fail', 'Failed to download inbound api logs');
    }
  });

  return { downloadMetadataInboundLog: mutate, isLoading };
};
