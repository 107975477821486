import { Button } from '@common/components/atoms/Button';
import { Checkbox } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import { Page } from '@common/components/organisms/Page';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/table-core';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Modal } from '@common/components/molecules/Modal';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  deleteHotelRoom,
  savePricingSettings,
  syncHotelPmsData,
  updateHotelPmsData,
  updateHotelPmsOccupancyData
} from '@common/api/hotel';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useAuthStore, useHotelStore } from '@common/store/auth';
import { useViewStore } from '@common/store/view';
import { Drawer } from '@common/components/molecules/Drawer/Drawer';
import { AddNewRoom } from '@pages/Client/PricingStrategy/RoomSetup/components/AddNewRoom';
import { EditRoom } from '@pages/Client/PricingStrategy/RoomSetup/components/EditRoom';
import { EditReferenceRoom } from '@pages/Client/PricingStrategy/RoomSetup/components/EditReferenceRoom';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import { Badge } from '@common/components/atoms/Badge';
import { usePusherStore } from '@common/store/pusher';
import { PusherEventNames } from '@common/constants/pusher';
import { useNotificationsStore } from '@common/store/notifications';
import { uniqBy } from 'lodash-es';
import { useFeaturesStore } from '@common/store/features';
import { PricingSettings } from '@common/api/hotel/types';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { useWarnings } from '@common/store/warnings';
import { AdminSetupRoomsCard } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/AdminSetupRoomsCard';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useClipboard, useDocumentTitle } from '@mantine/hooks';
import { truncate } from 'lodash-es';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';
import { HotelQueryKeys, PricingQueryKeys, RoomsQueryKeys } from '@common/types/query-keys';

const schema = z.object({
  totalNumberOfRooms: z.number().or(z.string().transform(Number)).optional(),
  absoluteRoomDerivation: z.boolean(),
  occupancyDerivation: z.boolean().optional()
});

export const RoomSetup = () => {
  const { t } = useTranslation();
  const { hotelAuthToken } = useHotelStore();
  const { copy } = useClipboard();
  const { user } = useAuthStore();
  const { features } = useFeaturesStore();
  const { addNotification } = useNotificationsStore();
  const { createWarning } = useWarnings();
  const { view } = useViewStore();
  const { hasNoButtonRefreshRates, hasRoomOccupancyForPricing } = usePmsProvider();
  const {
    setIsAbsoluteAdjustmentToReferenceRoom,
    setIsOccupancyPercentDerivation,
    isOccupancyPercentDerivation,
    isApartment,
    setIsApartment
  } = useRoomSetupStore();
  const { channel, channelBind, channelUnbind } = usePusherStore();
  const [isHeaderFormSuccess, setIsHeaderFormSuccess] = useState(false);
  const { isOccupancyBased, isDerivedRates, isRoomOnly, isOpenApi } = useGetDetailProviderConfig();

  const initRoomSetupPusher = useCallback(() => {
    channelBind(PusherEventNames.ConnectionUpdateMessage, (data: any) => {
      if (data.success) {
        const message = 'PMS Connection Updated Successfully';
        queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_ROOMS_LIST] });
        queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_DATA_MAP] });
        addNotification('success', message);
      }

      if (data.error) {
        const message = data.message ?? 'PMS Connection Update Failed';
        addNotification('fail', message);
      }
    });
  }, [channel]);

  useEffect(() => {
    channel && initRoomSetupPusher();

    return () => {
      channelUnbind(PusherEventNames.ConnectionUpdateMessage);
    };
  }, [channel]);

  const isAdmin = view === 'admin';

  // React Query
  const queryClient = useQueryClient();
  const {
    hotelRooms,
    query: { isLoading: isHotelRoomsLoading }
  } = useHotelRoomsList();

  const {
    hotelPmsDataMap,
    query: { isLoading: isHotelPmsDataMapLoading }
  } = useHotelPmsDataMap();
  const {
    hotelDetails,
    query: { isLoading: isHotelDetailsLoading }
  } = useHotelDetails();
  useEffect(() => {
    if (!hotelDetails) return;
    setIsApartment(hotelDetails.room_apartment_space === 2);
  }, [hotelDetails]);

  const apartmentsOrRooms = isApartment ? 'Apartments' : 'Rooms';

  useDocumentTitle(t(`${apartmentsOrRooms} Setup`));

  const {
    pricingSettings,
    pricingSettingsQuery: { isLoading: isPricingSettingsLoading, refetch: refetchPricingSettings }
  } = usePricingSettings();
  const {
    pmsList,
    query: { isLoading: isPmsListLoading }
  } = useGetHotelPmsList();

  const isRoomRacoonV2 = pmsList?.find(
    (pms) => pms.provider === PmsProvider.ROOMRACCOON
  )?.use_v2_integration;

  const { mutateAsync: deleteHotelRoomMutation } = useMutation({
    mutationKey: [RoomsQueryKeys.DELETE_HOTEL_ROOM],
    mutationFn: deleteHotelRoom,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_ROOMS_LIST] });
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_DATA_MAP] });
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
    }
  });

  const { mutateAsync: refreshRoomRatesFromPms } = useMutation({
    mutationKey: [HotelQueryKeys.UPDATE_HOTEL_PMS_DATA],
    mutationFn: updateHotelPmsData,
    onSuccess: async () => {
      await syncHotelPmsData;
    }
  });

  const {
    mutateAsync: updateHotelPmsOccupancyDataMutation,
    isPending: isUpdateHotelPmsOccupancyData
  } = useMutation({
    mutationKey: [HotelQueryKeys.UPDATE_HOTEL_PMS_OCCUPANCY_DATA],
    mutationFn: updateHotelPmsOccupancyData,
    onSuccess: async () => {
      setIsHeaderFormSuccess(true);
      setTimeout(() => {
        setIsHeaderFormSuccess(false);
      }, 2000);
    }
  });

  const { mutateAsync: updatePricingSettings, isPending: isUpdatePricingSettings } = useMutation({
    mutationKey: [PricingQueryKeys.UPDATE_PRICING_SETTINGS],
    mutationFn: savePricingSettings,
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
    }
  });

  const data = hotelRooms
    ?.sort(({ is_reference_room }) => (is_reference_room ? -1 : 1))
    .map((room) => ({
      id: room.id,
      name: room.name,
      roomInPms: hotelPmsDataMap?.mapped_data.find((value) => value.id === room.pms_room)?.id,
      rateInPms: Number(
        hotelPmsDataMap?.mapped_data
          .find((value) => value.id === room.pms_room)
          ?.rates.find((rate) => rate.id === room.pms_rate)?.id
      ),
      numberOfRooms: room.is_reference_room
        ? pricingSettings?.rooms?.reference?.number_of_rooms
        : pricingSettings?.rooms?.derived?.[room.id]?.number_of_rooms,
      referenceDerived: room.is_reference_room ? t('Reference') : t('Derived'),
      pmsPriceApplicableOccupancyId: room.pms_price_applicable_occupancy,
      basePrice: Math.round(pricingSettings?.default[room.id]?.avg_price ?? 0),
      derivationFromReferenceRoom: pricingSettings?.default[room.id]?.adjustment_to_reference_room,
      defaultMinimumPrice: pricingSettings?.default[room.id]?.min_price,
      defaultMaximumPrice: pricingSettings?.default[room.id]?.max_price,
      yieldingTags: pricingSettings?.room_type_tags?.[room.id]?.tag_name,
      isReferenceRoom: room.is_reference_room,
      defaultOccupancy: room.default_occupancy,
      ignoreUploadCount: room.ignore_upload_count,
      isOccupancyBasedPricing:
        isOccupancyBased ||
        room.extra === 'occupancy price' ||
        pmsList?.find((pms) => pms.primary)?.configurations?.price_type === 'occupancy_based',
      isDerivedRates: isDerivedRates || isRoomRacoonV2,
      variableCost: room.is_reference_room
        ? pricingSettings?.rooms?.reference?.variable_cost_per_room
        : pricingSettings?.rooms?.derived?.[room.id]?.variable_cost_per_room,
      virtualRoomType: room.is_reference_room
        ? pricingSettings?.rooms?.reference?.virtual_room_type
        : pricingSettings?.rooms?.derived?.[room.id]?.virtual_room_type,
      numberOfBedsPerPhysicalRoom: room.is_reference_room
        ? pricingSettings?.rooms?.reference?.number_of_beds_per_physical_room
        : pricingSettings?.rooms?.derived?.[room.id]?.number_of_beds_per_physical_room,
      lockDerivationWhenBasePriceChanges:
        pricingSettings?.hotel.adjustment_to_reference_room_is_locked,
      roomInPmsOptions: hotelPmsDataMap?.mapped_data
        .filter(
          (pmsData) =>
            room.pms_room === pmsData.id ||
            !hotelRooms.some((otherRoom) => otherRoom.pms_room === pmsData.id)
        )
        .map((pmsData) => ({
          label: `${pmsData.name} (${pmsData.room_id})`,
          value: pmsData.id
        }))
        .flat(),
      baseRateInPmsOptions:
        hotelDetails?.pms_provider === 25 || hotelDetails?.pms_provider === 26
          ? (pmsRoom: number) =>
              hotelPmsDataMap?.mapped_data
                .filter((pmsData) => pmsData.id === pmsRoom)
                .map((pmsData) =>
                  pmsData.rates.map((rate) => ({
                    label: `${rate.name} (${rate.rate_id})`,
                    labelValue: rate.rate_id,
                    value: rate.id
                  }))
                )
                .flat()
                .sort((a, b) => +a.labelValue.split('p')[1] - +b.labelValue.split('p')[1])
                .map((rate) => ({
                  label: rate.label,
                  value: rate.value
                }))
          : (pmsRoom: number) =>
              uniqBy(
                hotelPmsDataMap?.mapped_data
                  .filter((pmsData) => pmsData.id === pmsRoom)
                  .map((pmsData) => {
                    return pmsData.rates.map((rate) => ({
                      label: `${rate.name} (${rate.rate_id})`,
                      labelValue: rate.rate_id,
                      value: rate.id
                    }));
                  })
                  .flat(),
                'labelValue'
              ).map((rate) => ({
                label: rate.label,
                value: rate.value
              })),
      roundPriceSetting: pricingSettings?.hotel.round_price_setting || null,
      priceReferenceRoomOnly: pricingSettings?.hotel.all_room_pricing === true ? false : true,
      roomOccupancyForPricing: room.pms_price_applicable_occupancy || null,
      roomOccupancyForPricingOptions: (pmsRoom: number) =>
        uniqBy(
          hotelPmsDataMap?.mapped_data
            .filter((pmsData) => pmsData.id === pmsRoom)
            .flatMap((pmsData) =>
              pmsData.applicable_occupancy
                ? pmsData.applicable_occupancy.map((item) => ({
                    label: `${item.applicable_guest}`,
                    labelValue: item.applicable_guest,
                    value: item.id
                  }))
                : []
            ),
          'labelValue'
        ).map((item) => {
          return {
            label: item.label,
            value: item.value
          };
        }),
      priceType:
        room.configurations?.price_type ?? hotelDetails?.pms_provider === 2
          ? undefined
          : room.extra,
      extraChild: room.extra_child,
      extraAdult: room.discount_per_person
    }));

  // Local state
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [roomToDelete, setRoomToDelete] = useState<{ id: number; roomName: string } | null>(null);
  const [isAddNewRoomModalOpen, setIsAddNewRoomModalOpen] = useState(false);
  const [isEditRoomModalOpen, setIsEditRoomModalOpen] = useState(false);
  const [roomToEdit, setRoomToEdit] = useState<(typeof data)[0] | null>(null);
  const [occupancyPricingModalOpen, setOccupancyPricingModalOpen] = useState(false);

  useEffect(() => {
    if (!pricingSettings) return;
    setIsAbsoluteAdjustmentToReferenceRoom(
      pricingSettings.hotel.adjustment_to_reference_room_is_absolute
    );
  }, [pricingSettings]);

  // Form config
  const { handleSubmit, control, getValues, setValue } = useForm<z.infer<typeof schema>>({
    mode: 'onSubmit',
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (!pmsList?.find((pms) => pms.primary)?.occupancy_percent_derivation) return;
    setIsOccupancyPercentDerivation(
      pmsList.find((pms) => pms.primary)?.occupancy_percent_derivation || false
    );
    setValue(
      'occupancyDerivation',
      pmsList.find((pms) => pms.primary)?.occupancy_percent_derivation || false
    );
  }, [pmsList]);

  const onSubmit = async () => {
    const { totalNumberOfRooms, absoluteRoomDerivation, occupancyDerivation } = getValues();

    const submitFn = async () => {
      try {
        await updateHotelPmsOccupancyDataMutation({
          id: hotelDetails?.pms,
          token: hotelAuthToken,
          occupancy_percent_derivation: occupancyDerivation ?? false
        });

        const referenceRoomId = pricingSettings?.rooms?.reference?.id;

        await refetchPricingSettings();

        if (!referenceRoomId) return;

        await updatePricingSettings(
          JSON.stringify({
            ...pricingSettings,
            hotel: {
              ...pricingSettings?.hotel,
              number_of_rooms: +(totalNumberOfRooms || 0),
              adjustment_to_reference_room_is_absolute: absoluteRoomDerivation
            },
            default: {
              ...pricingSettings?.default,
              ...(absoluteRoomDerivation
                ? Object.fromEntries(
                    Object.entries(pricingSettings?.default || {})
                      .filter(([key]) => +key !== referenceRoomId)
                      .map(([key, value]) => [
                        key,
                        {
                          ...value,
                          adjustment_to_reference_room:
                            value.avg_price - pricingSettings?.default?.[referenceRoomId]?.avg_price
                        }
                      ])
                  )
                : Object.fromEntries(
                    Object.entries(pricingSettings?.default || {})
                      .filter(([key]) => +key !== referenceRoomId)
                      .map(([key, value]) => [
                        key,
                        {
                          ...value,
                          adjustment_to_reference_room: Math.round(
                            ((value.avg_price -
                              pricingSettings?.default?.[referenceRoomId]?.avg_price) /
                              pricingSettings?.default?.[referenceRoomId]?.avg_price) *
                              100
                          )
                        }
                      ])
                  ))
            }
          })
        );
      } catch (error) {
        console.log(error);
      }
    };

    if (
      totalNumberOfRooms &&
      +totalNumberOfRooms !== data.reduce((acc, curr) => acc + (curr.numberOfRooms || 0), 0)
    ) {
      createWarning({
        message: t(
          `The total number of ${hotelDetails?.room_apartment_space_name.toLowerCase()}s is different from the sum of the number of  ${hotelDetails?.room_apartment_space_name.toLowerCase()}s from each  ${hotelDetails?.room_apartment_space_name.toLowerCase()} type. Are all your  ${hotelDetails?.room_apartment_space_name.toLowerCase()}s set up and visible to us? Please message us if these numbers don't match as it is important for correct pricing.`
        ),
        ignore: false,
        ignoreLabel: t('Cancel') as string,
        dismissLabel: t('Confirm') as string
      }).then(() => {
        submitFn();
      });
    } else {
      submitFn();
    }
  };

  const getYieldingTags = (id: number) => {
    if (!pricingSettings) return;

    const tag =
      pricingSettings.room_type_tags &&
      Object.keys(pricingSettings.room_type_tags).map((key) => {
        if (pricingSettings.room_type_tags?.[key].room_types.includes(id)) {
          return pricingSettings.room_type_tags[key];
        }
      });

    return tag;
  };

  const columns: any[] = [
    {
      header: t('Name') as string,
      accessorKey: 'name',
      meta: {
        showOnMobile: true
      },
      cell: ({ cell }: { cell: any }) => (
        <div className="flex flex-col gap-2">
          <Typography
            element="div"
            color={cell.row.original.isReferenceRoom ? 'darkGrey' : 'copyTextGrey'}
            variant="meta-1"
            className={clsx(cell.row.original.isReferenceRoom ? 'font-semibold' : null)}>
            {cell.getValue() as string}
          </Typography>

          {view === 'admin' && user?.is_staff ? (
            <Typography element="div" color="darkGrey" className="text-meta-1-medium">
              <TooltipProvider delayDuration={75}>
                <Tooltip>
                  <TooltipTrigger
                    className="text-left"
                    onClick={(e) => {
                      e.stopPropagation();
                      copy(cell.row.original.id.toString());
                    }}>
                    ({truncate(cell.row.original.id ?? 'n.A.', { length: 20 })})
                  </TooltipTrigger>
                  <TooltipContent side="bottom" onClick={(e) => e.stopPropagation()}>
                    {cell.row.original.id ?? 'n.A.'}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Typography>
          ) : null}
        </div>
      )
    },
    {
      header: t(
        `${isApartment ? 'Apartment' : 'Room'} in ${
          hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
        }`
      ) as string,
      accessorKey: 'roomInPms',
      cell: ({ cell }: { cell: any }) => (
        <div className="flex flex-col gap-2">
          <Typography
            color={
              cell.row.original.isReferenceRoom && cell.row.original.roomInPms
                ? 'darkGrey'
                : !cell.row.original.roomInPms
                ? 'uiRed'
                : 'copyTextGrey'
            }
            variant="meta-1"
            className={clsx(cell.row.original.isReferenceRoom ? 'font-semibold' : null)}>
            {hotelPmsDataMap?.mapped_data.find((value) => value.id === cell.row.original.roomInPms)
              ?.name || t('Please Map')}
          </Typography>

          {view === 'admin' && user?.is_staff ? (
            <Typography element="div" color="darkGrey" className="text-meta-1-medium">
              <TooltipProvider delayDuration={75}>
                <Tooltip>
                  <TooltipTrigger
                    className="text-left"
                    onClick={(e) => {
                      e.stopPropagation();
                      copy(
                        hotelPmsDataMap?.mapped_data.find(
                          (value) => value.id === cell.row.original.roomInPms
                        )?.room_id
                      );
                    }}>
                    (
                    {truncate(
                      hotelPmsDataMap?.mapped_data.find(
                        (value) => value.id === cell.row.original.roomInPms
                      )?.room_id ?? 'n.A.',
                      { length: 20 }
                    )}
                    )
                  </TooltipTrigger>
                  <TooltipContent side="bottom" onClick={(e) => e.stopPropagation()}>
                    {hotelPmsDataMap?.mapped_data.find(
                      (value) => value.id === cell.row.original.roomInPms
                    )?.room_id ?? 'n.A.'}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Typography>
          ) : null}
        </div>
      )
    },
    ...(hotelDetails?.pms_provider === 25 ||
    hotelDetails?.pms_provider === 26 ||
    hotelDetails?.pms_provider === 84
      ? [
          {
            header: t(
              `Base Rate in ${hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'}`
            ),
            accessorKey: 'rateInPms',
            cell: ({ cell }: { cell: any }) => (
              <div className="flex flex-col gap-2">
                <Typography
                  color={cell.row.original.isReferenceRoom ? 'darkGrey' : 'copyTextGrey'}
                  variant="meta-1"
                  className={clsx(cell.row.original.isReferenceRoom ? 'font-semibold' : null)}>
                  {hotelPmsDataMap?.mapped_data
                    .find((value) => value.id === cell.row.original.roomInPms)
                    ?.rates.find((rate) => rate.id === cell.row.original.rateInPms)?.name ||
                    t('Not Priced')}
                </Typography>

                {view === 'admin' && user?.is_staff ? (
                  <Typography element="div" color="darkGrey" className="text-meta-1-medium">
                    <TooltipProvider delayDuration={75}>
                      <Tooltip>
                        <TooltipTrigger
                          className="text-left"
                          onClick={(e) => {
                            e.stopPropagation();
                            copy(
                              hotelPmsDataMap?.mapped_data
                                .find((value) => value.id === cell.row.original.roomInPms)
                                ?.rates.find((rate) => rate.id === cell.row.original.rateInPms)
                                ?.rate_id
                            );
                          }}>
                          (
                          {truncate(
                            hotelPmsDataMap?.mapped_data
                              .find((value) => value.id === cell.row.original.roomInPms)
                              ?.rates.find((rate) => rate.id === cell.row.original.rateInPms)
                              ?.rate_id ?? 'n.A.',
                            { length: 20 }
                          )}
                          )
                        </TooltipTrigger>
                        <TooltipContent side="bottom" onClick={(e) => e.stopPropagation()}>
                          {hotelPmsDataMap?.mapped_data
                            .find((value) => value.id === cell.row.original.roomInPms)
                            ?.rates.find((rate) => rate.id === cell.row.original.rateInPms)
                            ?.rate_id ?? 'n.A.'}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </Typography>
                ) : null}
              </div>
            )
          }
        ]
      : isRoomOnly
      ? []
      : [
          {
            header: t(`Rate in ${hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'}`),
            accessorKey: 'rateInPms',
            cell: ({ cell }: { cell: any }) => (
              <div className="flex flex-col gap-2">
                <Typography
                  color={cell.row.original.isReferenceRoom ? 'darkGrey' : 'copyTextGrey'}
                  variant="meta-1"
                  className={clsx(cell.row.original.isReferenceRoom ? 'font-semibold' : null)}>
                  {hotelPmsDataMap?.mapped_data
                    .find((value) => value.id === cell.row.original.roomInPms)
                    ?.rates.find((rate) => rate.id === cell.row.original.rateInPms)?.name ||
                    t('Not Priced')}
                </Typography>

                {view === 'admin' && user?.is_staff ? (
                  <Typography element="div" color="darkGrey" className="text-meta-1-medium">
                    <TooltipProvider delayDuration={75}>
                      <Tooltip>
                        <TooltipTrigger
                          className="text-left"
                          onClick={(e) => {
                            e.stopPropagation();
                            copy(
                              hotelPmsDataMap?.mapped_data
                                .find((value) => value.id === cell.row.original.roomInPms)
                                ?.rates.find((rate) => rate.id === cell.row.original.rateInPms)
                                ?.rate_id
                            );
                          }}>
                          (
                          {truncate(
                            hotelPmsDataMap?.mapped_data
                              .find((value) => value.id === cell.row.original.roomInPms)
                              ?.rates.find((rate) => rate.id === cell.row.original.rateInPms)
                              ?.rate_id ?? 'n.A.',
                            { length: 20 }
                          )}
                          )
                        </TooltipTrigger>
                        <TooltipContent side="bottom" onClick={(e) => e.stopPropagation()}>
                          {hotelPmsDataMap?.mapped_data
                            .find((value) => value.id === cell.row.original.roomInPms)
                            ?.rates.find((rate) => rate.id === cell.row.original.rateInPms)
                            ?.rate_id ?? 'n.A.'}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </Typography>
                ) : null}
              </div>
            )
          }
        ]),
    {
      header: t(`Number of ${isApartment ? 'Apartments' : 'Rooms'}`) as string,
      accessorKey: 'numberOfRooms',
      cell: ({ cell }: { cell: any }) => (
        <Typography
          color={cell.row.original.isReferenceRoom ? 'darkGrey' : 'copyTextGrey'}
          variant="meta-1"
          className={clsx(cell.row.original.isReferenceRoom ? 'font-semibold' : null)}>
          {cell.getValue() as number}
        </Typography>
      ),
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: t('Reference/Derived') as string,
      accessorKey: 'referenceDerived',
      meta: {
        showOnMobile: true
      },
      cell: ({ cell }: { cell: any }) => (
        <Typography
          color={cell.row.original.isReferenceRoom ? 'darkGrey' : 'copyTextGrey'}
          variant="meta-1"
          className={clsx(cell.row.original.isReferenceRoom ? 'font-semibold' : null)}>
          {cell.getValue() as string}
        </Typography>
      )
    },
    ...(hasRoomOccupancyForPricing() && !isOpenApi
      ? [
          {
            header: t('Room Occupancy for Pricing'),
            accessorKey: 'pmsPriceApplicableOccupancyId',
            meta: {
              cellAlignment: 'right',
              showOnMobile: true
            },
            cell: ({ cell }: { cell: any }) => (
              <Typography
                color={cell.row.original.isReferenceRoom ? 'darkGrey' : 'copyTextGrey'}
                variant="meta-1"
                className={clsx(cell.row.original.isReferenceRoom ? 'font-semibold' : null)}>
                {cell.getValue() as number}
              </Typography>
            )
          }
        ]
      : []),
    {
      header: t('Base Price') as string,
      accessorKey: 'basePrice',
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      },
      cell: ({ cell }: { cell: any }) => {
        if (
          cell.getValue() === undefined ||
          cell.getValue() === null ||
          isNaN(cell.getValue() as number) ||
          (!pricingSettings?.hotel.all_room_pricing && !cell.row.original.isReferenceRoom)
        )
          return '-';
        return (
          <Typography
            color={cell.row.original.isReferenceRoom ? 'darkGrey' : 'copyTextGrey'}
            variant="meta-1"
            className={clsx(cell.row.original.isReferenceRoom ? 'font-semibold' : null)}>
            {CurrencyFormatter.format(Math.round(cell.getValue() as number))}
          </Typography>
        );
      }
    },
    {
      header: t(`Derivation from Reference ${isApartment ? 'Apartment' : 'Room'}`) as string,
      accessorKey: 'derivationFromReferenceRoom',
      cell: ({ cell }: { cell: any }) => {
        if (
          cell.getValue() === undefined ||
          cell.getValue() === null ||
          isNaN(cell.getValue() as number) ||
          cell.row.original.isReferenceRoom ||
          (!pricingSettings?.hotel.all_room_pricing && !cell.row.original.isReferenceRoom)
        )
          return '-';
        const isPositive = cell.getValue() > 0;
        return (
          <Typography
            color={cell.row.original.isReferenceRoom ? 'darkGrey' : 'copyTextGrey'}
            variant="meta-1"
            className={clsx(
              'inline-flex items-center',
              cell.row.original.isReferenceRoom ? 'font-semibold' : null
            )}>
            {pricingSettings?.hotel.adjustment_to_reference_room_is_absolute
              ? isPositive
                ? '+' + CurrencyFormatter.format(Math.round(cell.getValue() as number))
                : CurrencyFormatter.format(Math.round(cell.getValue() as number))
              : isPositive
              ? '+' + Math.round(cell.getValue() as number) + '%'
              : Math.round(cell.getValue() as number) + '%'}
            {pricingSettings?.hotel.adjustment_to_reference_room_is_locked ? (
              <Icon.Lock className="h-4 w-4" />
            ) : null}
          </Typography>
        );
      },
      meta: {
        showOnMobile: true,
        cellAlignment: 'right'
      }
    },
    ...(features?.includes(6)
      ? [
          {
            header: t('Default Minimum Price'),
            accessorKey: 'defaultMinimumPrice',
            cell: ({ cell }: { cell: any }) => {
              if (
                cell.getValue() === undefined ||
                cell.getValue() === null ||
                isNaN(cell.getValue() as number) ||
                (!pricingSettings?.hotel.all_room_pricing && !cell.row.original.isReferenceRoom)
              )
                return '-';
              return (
                <Typography
                  color={cell.row.original.isReferenceRoom ? 'darkGrey' : 'copyTextGrey'}
                  variant="meta-1"
                  className={clsx(cell.row.original.isReferenceRoom ? 'font-semibold' : null)}>
                  {CurrencyFormatter.format(cell.getValue() as number)}
                </Typography>
              );
            },
            meta: {
              showOnMobile: true,
              cellAlignment: 'right'
            }
          },
          {
            header: t('Default Maximum Price'),
            accessorKey: 'defaultMaximumPrice',
            cell: ({ cell }: { cell: any }) => {
              if (
                cell.getValue() === undefined ||
                cell.getValue() === null ||
                isNaN(cell.getValue() as number) ||
                (!pricingSettings?.hotel.all_room_pricing && !cell.row.original.isReferenceRoom)
              )
                return '-';
              return (
                <Typography
                  color={cell.row.original.isReferenceRoom ? 'darkGrey' : 'copyTextGrey'}
                  variant="meta-1"
                  className={clsx(cell.row.original.isReferenceRoom ? 'font-semibold' : null)}>
                  {CurrencyFormatter.format(cell.getValue() as number)}
                </Typography>
              );
            },
            meta: {
              showOnMobile: true,
              cellAlignment: 'right'
            }
          }
        ]
      : []),

    ...(features?.includes(9)
      ? [
          {
            header: t('Yielding Tags'),
            accessorKey: 'yieldingTags',
            meta: {
              showOnMobile: true
            },
            cell: ({ cell }: { cell: any }) => (
              <div className="flex flex-wrap gap-2">
                {getYieldingTags(cell.row.original.id)?.map((tag, index) =>
                  tag ? (
                    <Badge key={`${tag?.id}-${index}}`} background={tag?.color}>
                      {tag?.tag_name}
                    </Badge>
                  ) : null
                )}
              </div>
            )
          }
        ]
      : []),
    {
      header: t('Delete') as string,
      accessorKey: 'delete',
      cell: ({ row }: { row: any }) =>
        !row.original.isReferenceRoom ? (
          <Button
            icon
            type="button"
            onClick={() => handleDeleteModalOpen(row.original.id, row.original.name)}>
            <Icon.Delete className="text-copyTextGrey" />
          </Button>
        ) : null
    }
  ];

  const handleDeleteModalOpen = (id: number, roomName: string) => {
    setRoomToDelete({
      id,
      roomName
    });

    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = async (ok: boolean) => {
    if (!ok) {
      setIsDeleteModalOpen(false);
      return;
    }

    await deleteHotelRoomMutation(roomToDelete?.id);
    setIsDeleteModalOpen(false);
  };

  const isLoading =
    isHotelRoomsLoading ||
    isPricingSettingsLoading ||
    isHotelPmsDataMapLoading ||
    isPmsListLoading ||
    isHotelDetailsLoading;

  const handleEditRoomModalOpen = (row: Row<(typeof data)[0]>) => {
    setRoomToEdit(row.original);
    setIsEditRoomModalOpen(true);
  };

  const removeOccupancyPricingFromDates = (
    dates: PricingSettings['dates'] | undefined
  ): PricingSettings['dates'] | undefined => {
    if (!dates) return undefined;
    for (const date in dates) {
      for (const roomId in dates[date]) {
        if (Object.prototype.hasOwnProperty.call(dates[date][roomId], 'occupancy_pricing')) {
          delete dates[date][roomId]['occupancy_pricing'];

          // check if 'occupancy_pricing' was the only property in the room
          if (Object.keys(dates[date][roomId]).length === 0) {
            delete dates[date][roomId];
          }
        }
      }
      // check if the room was the only property in the date
      if (Object.keys(dates[date]).length === 0) {
        delete dates[date];
      }
    }
    return dates;
  };

  const handleOccupancyPercentDerivationChange = async (ok: boolean) => {
    if (!ok) {
      setOccupancyPricingModalOpen(false);
      return;
    }

    await refetchPricingSettings();

    const processedDates = removeOccupancyPricingFromDates(pricingSettings?.dates);

    await updatePricingSettings(
      JSON.stringify({
        ...pricingSettings,
        dates: {
          ...processedDates
        }
      })
    );

    setOccupancyPricingModalOpen(false);
  };

  return (
    <Page
      header={<Header title={t(`${apartmentsOrRooms} Setup`)} />}
      external={
        <Drawer
          isOpen={isAddNewRoomModalOpen || isEditRoomModalOpen}
          onClose={() =>
            isAddNewRoomModalOpen ? setIsAddNewRoomModalOpen(false) : setIsEditRoomModalOpen(false)
          }>
          {isEditRoomModalOpen ? (
            roomToEdit?.isReferenceRoom ? (
              <EditReferenceRoom
                defaults={{
                  pmsMapping: {
                    priceReferenceRoomOnly: roomToEdit?.priceReferenceRoomOnly,
                    baseRateInPms: +roomToEdit?.rateInPms,
                    numberOfRooms: roomToEdit?.numberOfRooms || 0,
                    roomName: roomToEdit?.name,
                    priceType: roomToEdit?.priceType,
                    roomInPms: roomToEdit?.roomInPms || null,
                    roomId: roomToEdit?.id,
                    roomOccupancyForPricing: roomToEdit?.roomOccupancyForPricing || null,
                    virtualRoomType: roomToEdit?.virtualRoomType,
                    numberOfBedsPerPhysicalRoom: roomToEdit?.numberOfBedsPerPhysicalRoom
                  },
                  pricingSetup: {
                    basePrice: roomToEdit?.basePrice || 0,
                    derivationFromReferenceRoom: roomToEdit?.derivationFromReferenceRoom || 0,
                    defaultMaxPrice: roomToEdit?.defaultMaximumPrice || 0,
                    defaultMinPrice: roomToEdit?.defaultMinimumPrice || 0,
                    dontCountUploads: roomToEdit?.ignoreUploadCount,
                    lockDerivationWhenBasePriceChanges:
                      roomToEdit?.lockDerivationWhenBasePriceChanges,
                    variableCost: roomToEdit?.variableCost,
                    roundPriceSetting: roomToEdit.roundPriceSetting
                  },
                  occupancyPricing: {
                    defaultOccupancy: roomToEdit?.defaultOccupancy,
                    additionalChildPrice: roomToEdit?.extraChild,
                    additionalAdultPrice: roomToEdit?.extraAdult
                  }
                }}
                roomInPmsOptions={roomToEdit?.roomInPmsOptions}
                baseRateInPmsOptions={roomToEdit?.baseRateInPmsOptions}
                roomOccupancyPricingOptions={
                  roomToEdit?.roomOccupancyForPricingOptions || (() => [])
                }
                isOccupancyBasedPricing={
                  roomToEdit?.isOccupancyBasedPricing ||
                  pmsList?.find((pms) => pms.primary)?.configurations?.price_type ===
                    'occupancy_based' ||
                  pmsList?.find((pms) => pms.primary)?.extra === 'occupancy_based' ||
                  false
                }
                isDerivedRates={roomToEdit?.isDerivedRates || false}
                onDrawerClose={() => setIsEditRoomModalOpen(false)}
              />
            ) : (
              <EditRoom
                defaults={{
                  pmsMapping: {
                    baseRateInPms: roomToEdit?.rateInPms || null,
                    numberOfRooms: roomToEdit?.numberOfRooms || 0,
                    roomName: roomToEdit?.name || '',
                    priceType: roomToEdit?.priceType,
                    roomInPms: roomToEdit?.roomInPms || null,
                    roomId: roomToEdit?.id,
                    roomOccupancyForPricing: roomToEdit?.roomOccupancyForPricing || null,
                    virtualRoomType: roomToEdit?.virtualRoomType,
                    numberOfBedsPerPhysicalRoom: roomToEdit?.numberOfBedsPerPhysicalRoom
                  },
                  pricingSetup: {
                    basePrice: Math.round(roomToEdit?.basePrice || 0),
                    derivationFromReferenceRoom: Math.round(
                      roomToEdit?.derivationFromReferenceRoom || 0
                    ),
                    defaultMaxPrice: Math.round(roomToEdit?.defaultMaximumPrice || 0),
                    defaultMinPrice: Math.round(roomToEdit?.defaultMinimumPrice || 0),
                    dontCountUploads: roomToEdit?.ignoreUploadCount
                  },
                  occupancyPricing: {
                    defaultOccupancy: roomToEdit?.defaultOccupancy,
                    additionalChildPrice: roomToEdit?.extraChild,
                    additionalAdultPrice: roomToEdit?.extraAdult
                  }
                }}
                referenceRoomBasePrice={data[0]?.basePrice}
                roomInPmsOptions={roomToEdit?.roomInPmsOptions}
                roomOccupancyPricingOptions={
                  roomToEdit?.roomOccupancyForPricingOptions || (() => [])
                }
                baseRateInPmsOptions={roomToEdit?.baseRateInPmsOptions || (() => [])}
                isOccupancyBasedPricing={
                  roomToEdit?.isOccupancyBasedPricing ||
                  pmsList?.find((pms) => pms.primary)?.configurations?.price_type ===
                    'occupancy_based' ||
                  pmsList?.find((pms) => pms.primary)?.extra === 'occupancy_based' ||
                  false
                }
                isDerivedRates={roomToEdit?.isDerivedRates || false}
                referenceRoomDefaultMax={data[0]?.defaultMaximumPrice}
                referenceRoomDefaultMin={data[0]?.defaultMinimumPrice}
                onDrawerClose={() => setIsEditRoomModalOpen(false)}
              />
            )
          ) : isAddNewRoomModalOpen ? (
            <AddNewRoom
              defaults={{
                pricingSetup: {
                  basePrice: Math.round(data[0]?.basePrice || 0),
                  defaultMinPrice: Math.round(data[0]?.defaultMinimumPrice || 0),
                  defaultMaxPrice: Math.round(data[0]?.defaultMaximumPrice || 0),
                  derivationFromReferenceRoom: Math.round(data[0]?.derivationFromReferenceRoom || 0)
                }
              }}
              referenceRoomBasePrice={data[0]?.basePrice}
              isOccupancyBasedPricing={
                data[0]?.isOccupancyBasedPricing ||
                pmsList?.find((pms) => pms.primary)?.configurations?.price_type ===
                  'occupancy_based' ||
                pmsList?.find((pms) => pms.primary)?.extra === 'occupancy_based' ||
                false
              }
              isDerivedRates={data[0]?.isDerivedRates || false}
              roomInPmsOptions={hotelPmsDataMap?.mapped_data
                .filter(
                  (pmsData) => !hotelRooms.some((otherRoom) => otherRoom.pms_room === pmsData.id)
                )
                .map((pmsData) => ({
                  label: `${pmsData.name} (${pmsData.id})`,
                  value: pmsData.id
                }))
                .flat()}
              baseRateInPmsOptions={data[0]?.baseRateInPmsOptions}
              roomOccupancyPricingOptions={data[0]?.roomOccupancyForPricingOptions}
              referenceRoomDefaultMax={data[0]?.defaultMaximumPrice}
              referenceRoomDefaultMin={data[0]?.defaultMinimumPrice}
              onDrawerClose={() => setIsAddNewRoomModalOpen(false)}
            />
          ) : null}
        </Drawer>
      }>
      <>
        <div className="flex flex-col gap-4">
          <form key="room-setup-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center justify-between gap-x-8">
              <div className="flex flex-wrap items-center gap-x-8 gap-y-4">
                <Controller
                  control={control}
                  name="totalNumberOfRooms"
                  defaultValue={pricingSettings?.hotel.number_of_rooms}
                  render={({ field: { name, value, onChange } }) => (
                    <Input
                      name={name}
                      value={value}
                      type="number"
                      label={t(`Total Number of ${apartmentsOrRooms}`) as string}
                      showClearButton={false}
                      onChange={onChange}
                      trailingAddon={
                        <div className="flex justify-center gap-1">
                          <Typography variant="meta-1" color="grey">
                            {t('Sum')}:{' '}
                            {data.reduce((acc, curr) => acc + (curr.numberOfRooms || 0), 0)}
                          </Typography>
                          <TooltipProvider delayDuration={75}>
                            <Tooltip>
                              <TooltipTrigger type="button">
                                <Icon.Help className="h-5 w-5 fill-grey" />
                              </TooltipTrigger>
                              <TooltipContent side="bottom" className="max-w-xs">
                                {t(
                                  `This is the total number of ${hotelDetails?.room_apartment_space_name.toLowerCase()}s in the property that you can sell to customers. We need this to judge how full you are. If you have a ${
                                    hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
                                  } integration, you need to make sure that all ${hotelDetails?.room_apartment_space_name.toLowerCase()} types are connected so that the total number of ${hotelDetails?.room_apartment_space_name.toLowerCase()}s we can read from your ${
                                    hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
                                  } is the same as the total number shown here.`
                                )}
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      }
                    />
                  )}
                />
                <div className="flex flex-wrap gap-x-8 gap-y-2">
                  <div className="flex items-center gap-1.5">
                    <Controller
                      control={control}
                      name="absoluteRoomDerivation"
                      defaultValue={pricingSettings?.hotel.adjustment_to_reference_room_is_absolute}
                      render={({ field: { name, value, onChange } }) => (
                        <>
                          <div className="grid leading-none">
                            <TooltipProvider delayDuration={75}>
                              <Tooltip>
                                <TooltipTrigger type="button">
                                  <Checkbox
                                    label={t('Absolute Room Derivation (default is %)')}
                                    id={name}
                                    name={name}
                                    checked={value}
                                    onChange={onChange}
                                  />
                                </TooltipTrigger>
                                <TooltipContent side="bottom" className="max-w-sm text-left">
                                  {t(
                                    `Switches the derivation of your derived ${hotelDetails?.room_apartment_space_name.toLowerCase()} types from percentages to absolute values. For example, you may want your single ${hotelDetails?.room_apartment_space_name.toLowerCase()} always to be $20 cheaper than your reference ${hotelDetails?.room_apartment_space_name.toLowerCase()}.`
                                  )}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  {data[0]?.isOccupancyBasedPricing ? (
                    <div className="flex items-center gap-1.5">
                      <Controller
                        control={control}
                        name="occupancyDerivation"
                        defaultValue={isOccupancyPercentDerivation}
                        render={({ field: { name, value, onChange } }) => (
                          <>
                            <Checkbox
                              label={t('% Occupancy Derivation (default is absolute)')}
                              id={name}
                              name={name}
                              checked={value}
                              onChange={(e) => {
                                onChange(e);
                                setOccupancyPricingModalOpen(true);
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <Button
                  disabled={isLoading}
                  isLoading={isLoading || isUpdatePricingSettings || isUpdateHotelPmsOccupancyData}
                  isSuccess={isHeaderFormSuccess}
                  type="submit"
                  intent="primary">
                  {t('Save')}
                </Button>
              </div>
            </div>
          </form>

          {isAdmin ? <AdminSetupRoomsCard /> : null}

          <Table
            actionComponents={
              <div className="flex items-center justify-end gap-x-8 gap-y-4">
                {hasNoButtonRefreshRates() ? null : (
                  <div>
                    <Button
                      disabled={isHotelDetailsLoading}
                      type="button"
                      intent="text"
                      className="text-indigo disabled:text-opacity-30"
                      onClick={() =>
                        refreshRoomRatesFromPms({
                          id: hotelDetails?.pms,
                          provider: hotelDetails?.pms_provider
                        })
                      }>
                      <Icon.Refresh className="h-4 w-4" />
                      {t(
                        `Refresh ${apartmentsOrRooms}/Rates From ${
                          hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
                        }`
                      )}
                    </Button>
                  </div>
                )}
                <div>
                  <Button
                    disabled={isLoading}
                    type="button"
                    intent="primary"
                    onClick={() => setIsAddNewRoomModalOpen(true)}>
                    {t(`Add New ${isApartment ? 'Apartment' : 'Room'} Type`)}
                  </Button>
                </div>
              </div>
            }
            isFetching={
              isHotelRoomsLoading ||
              isPricingSettingsLoading ||
              isHotelPmsDataMapLoading ||
              isHotelDetailsLoading
            }
            isRowClickable
            onClickRow={(cell, row) => cell.column.id !== 'delete' && handleEditRoomModalOpen(row)}
            skeletonCount={10}
            data={data}
            columns={columns}
            isHover
            isStriped
            rowsPerPage={20}
          />
        </div>

        <Modal
          open={isDeleteModalOpen}
          onClose={handleDeleteModalClose}
          okText="Delete"
          continueIntent="danger">
          <div className="flex flex-col gap-y-4">
            <Typography element="h2" variant="h6" color="darkGrey">
              {t(`Delete ${roomToDelete?.roomName}`)}
            </Typography>
            <Typography element="p" variant="paragraph-2">
              {t(`Are you sure you want to delete ${roomToDelete?.roomName}?`)}
            </Typography>
          </div>
        </Modal>

        <Modal
          size="lg"
          open={occupancyPricingModalOpen}
          onClose={handleOccupancyPercentDerivationChange}
          okText="Remove All Daily Occupancy Pricing Settings"
          continueIntent="danger">
          <div className="flex flex-col gap-y-4">
            <Typography element="h2" variant="h6" color="darkGrey">
              {t('Changing Occupancy Derivation')}
            </Typography>
            <Typography element="p" variant="paragraph-2">
              {t('This will remove all daily occupancy pricing settings.')}
            </Typography>
          </div>
        </Modal>
      </>
    </Page>
  );
};
