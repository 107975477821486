import { Icon } from '@common/components/foundations/icons';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Mark,
  Modal,
  Paper,
  Stack,
  Text,
  UnstyledButton,
  rem
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { RuleModal } from '@pages/Client/PricingStrategy/MinStay/components/RuleModal';
import useMinstay from '@pages/Client/PricingStrategy/MinStay/hooks/useMinstay';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { debounce, find, isEmpty, isNil } from 'lodash-es';
import { SyntheticEvent, useEffect, useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { MinStayRules } from '@pages/Client/PricingStrategy/MinStay/types';
import { useEventTracking } from '@common/hooks/useEventTracking';

export enum MINSTAY_STAGE {
  BETA_RELEASE = 1,
  OFC_RELEASE = 2
}

const DEBOUNCE_TIME = 200;

export const MinStayRulesCard = () => {
  const { t } = useTranslation();
  const { pricingSettings } = useRoomPrices();
  const { getRoomNameFromIds } = useMinstay();
  const { hotelDetails } = useHotelDetails();
  const { savePricingSettings, isLoading: isSavePricingLoading } = useUpdatePricingSettings();
  const [selectedRulePriority, setSelectedRulePriority] = useState<number | null>(null);
  const [ruleToDelete, setRuleToDelete] = useState<number | null>(null);
  const [mode, setMode] = useState<'add' | 'edit'>('add');
  const [existingRules, setExistingRules] = useState<MinStayRules>([]);
  const { trackEvent } = useEventTracking();

  const [isCriteriaModalOpen, { open: criteriaModalOpen, close: criteriaModalClose }] =
    useDisclosure(false);
  const [isDeleteModalOpen, { open: deleteRulesOpen, close: deleteRulesClose }] =
    useDisclosure(false);

  const debouncedSave = useCallback(
    debounce(async (newSettings) => {
      await savePricingSettings(JSON.stringify(newSettings));
    }, DEBOUNCE_TIME),
    []
  );

  const deleteRules = async (priority: number) => {
    const filteredRules =
      pricingSettings?.minstay?.rules?.filter((rule) => rule.priority !== priority) || [];
    const updatedRules = filteredRules.map((rule, index) => ({
      ...rule,
      priority: index + 1
    }));
    const newSettings = {
      ...pricingSettings,
      minstay: {
        ...pricingSettings?.minstay,
        rules: updatedRules
      }
    };
    await savePricingSettings(JSON.stringify(newSettings));
    deleteRulesClose();
    setRuleToDelete(null);
    trackEvent('MinStayAutomationRemoved');
  };

  useEffect(() => {
    if (pricingSettings) {
      setExistingRules(pricingSettings?.minstay?.rules || []);
    }
  }, [pricingSettings]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const reorderedItems = Array.from(existingRules || []);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);

    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      priority: index + 1
    }));

    const newSettings = {
      ...pricingSettings,
      minstay: {
        ...pricingSettings?.minstay,
        rules: updatedItems
      }
    };

    setExistingRules(updatedItems);
    debouncedSave(newSettings);
  };

  const handleCriteriaModalOpen = (mode: 'add' | 'edit', rulePriority?: number) => {
    if (mode === 'add') {
      setMode('add');
      setSelectedRulePriority(null);
      criteriaModalOpen();
    } else {
      if (!rulePriority) return;
      setMode('edit');
      setSelectedRulePriority(rulePriority);
      criteriaModalOpen();
    }
  };

  const handleDeleteModalOpen = (e: SyntheticEvent<HTMLButtonElement>, rulePriority: number) => {
    e.preventDefault();
    e.stopPropagation();
    setRuleToDelete(rulePriority);
    deleteRulesOpen();
  };

  return (
    <Paper p="lg" radius="md">
      <Stack>
        <Flex justify="space-between" align="center">
          <Stack gap={2}>
            <Text>
              {t('Add Criteria To Remove Minimum Stay Restrictions')}{' '}
              <TooltipIcon
                iconClassName="fill-grey"
                content={t('You can organise these criteria by dragging them up and down')}
              />
            </Text>
          </Stack>
          <ActionIcon
            loading={isSavePricingLoading}
            disabled={isSavePricingLoading}
            onClick={() => {
              setMode('add');
              criteriaModalOpen();
            }}>
            <Icon.Add />
          </ActionIcon>
        </Flex>
        <Divider />
        {!isNil(existingRules) && !isEmpty(existingRules) ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Stack>
                      {existingRules?.map((rule, index) => (
                        <Draggable
                          key={rule.name}
                          isDragDisabled={isSavePricingLoading}
                          draggableId={rule.name}
                          index={index}>
                          {(provided, snapshot) => (
                            <UnstyledButton
                              onClick={() => {
                                handleCriteriaModalOpen('edit', rule.priority);
                              }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}>
                              <Paper
                                withBorder
                                p="xs"
                                bg={snapshot.isDragging ? 'gray.1' : ''}
                                key={index}>
                                <Stack gap="xs">
                                  <Flex justify="space-between">
                                    <Text size="sm" c="dark" fw={500} truncate>
                                      {t(`${rule.name}`)}
                                    </Text>
                                    <Group gap={rem(2)}>
                                      <ActionIcon
                                        {...provided.dragHandleProps}
                                        className="hover:cursor-move"
                                        variant="transparent">
                                        <Icon.Drag />
                                      </ActionIcon>
                                      <ActionIcon
                                        onClick={(e) => handleDeleteModalOpen(e, rule.priority)}>
                                        <Icon.Delete className="h-5 w-5" />
                                      </ActionIcon>
                                    </Group>
                                  </Flex>
                                  <Text size="xs" c="gray.8" truncate>
                                    {t(`For {{apartmentOrRoom}}s`, {
                                      apartmentOrRoom: hotelDetails?.room_apartment_space_name
                                    })}
                                    :
                                  </Text>

                                  <Group gap="xs">
                                    {getRoomNameFromIds(rule.rooms, pricingSettings?.rooms).map(
                                      (room) => (
                                        <Badge key={room} tt="none" fw="normal">
                                          {room}
                                        </Badge>
                                      )
                                    )}
                                  </Group>

                                  <Text size="xs" c="gray.8" truncate>
                                    {t('Automatically remove all minimum stay restrictions when')}:
                                  </Text>
                                  {rule.fields.map((field, index) => (
                                    <Paper withBorder p="xs" key={index}>
                                      <Flex justify="space-between" align="center">
                                        <Stack gap={rem(4)}>
                                          {field.type === 'single' ? (
                                            <Text size="sm" c="gray.8">
                                              <Trans
                                                i18nKey="There are less than <b>{{days}} days</b> to go to the arrival date"
                                                components={{
                                                  b: <Mark fw={500} color="indigo.1" />
                                                }}
                                                values={{
                                                  days: field.value
                                                }}
                                              />
                                            </Text>
                                          ) : null}

                                          {field.type === 'multiple' ? (
                                            <>
                                              <Flex wrap="wrap" gap={rem(4)}>
                                                <Text size="sm" c="gray.8">
                                                  <Trans
                                                    i18nKey="The occupancy is below <b>{{occupancy}}%</b> within <b>{{lead_time}} days</b> of the arrival date"
                                                    components={{
                                                      b: <Mark color="indigo.1" fw={500} />
                                                    }}
                                                    values={{
                                                      occupancy: find(field.statement, {
                                                        field: 'occupancy'
                                                      })?.value,
                                                      lead_time: find(field.statement, {
                                                        field: 'lead_time'
                                                      })?.value
                                                    }}
                                                  />
                                                </Text>
                                              </Flex>
                                            </>
                                          ) : null}
                                        </Stack>
                                      </Flex>
                                    </Paper>
                                  ))}
                                </Stack>
                              </Paper>
                            </UnstyledButton>
                          )}
                        </Draggable>
                      ))}
                    </Stack>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        ) : (
          <Paper withBorder p={24} bg="gray.1" opacity={0.5}>
            <Center>
              <Text size="sm">{t('No Criteria Added')}</Text>
            </Center>
          </Paper>
        )}
      </Stack>

      <RuleModal
        isOpen={isCriteriaModalOpen}
        onClose={criteriaModalClose}
        priority={selectedRulePriority}
        mode={mode}
      />

      <Modal
        centered
        title={t('Delete Criteria "{{rule}}"', {
          rule: existingRules?.find((rule) => rule.priority === ruleToDelete)?.name
        })}
        opened={isDeleteModalOpen}
        onClose={deleteRulesClose}
        styles={{
          body: {
            padding: '0'
          }
        }}>
        <Stack px="md" pb="xl" gap="lg">
          <Text size="sm" c="gray.9">
            {t('Are you sure you want to delete this criteria?')}
          </Text>
        </Stack>

        <ModalFooter>
          <Button variant="subtle" type="button" onClick={deleteRulesClose}>
            {t('Cancel')}
          </Button>
          <Button
            type="button"
            onClick={() => {
              if (!ruleToDelete) return;
              deleteRules(ruleToDelete);
            }}
            color="red">
            {t('Delete')}
          </Button>
        </ModalFooter>
      </Modal>
    </Paper>
  );
};
