import { getDefaultAfter365DailyAverages } from '@common/api/pricingAlgorithm';
import { Button } from '@common/components/atoms/Button';
import { Divider } from '@common/components/atoms/Divider';
import { Input } from '@common/components/atoms/Input';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useDefaultOneYearAverages } from '@pages/Client/PricingStrategy/After365Days/hooks/useAfter365Days';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useDocumentTitle } from '@mantine/hooks';
import { UtilQueryKeys } from '@common/types/query-keys';

type monthKey = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

const schema = z.object(
  Object.fromEntries(
    Array.from({ length: 12 }, (_, index) => [
      `${index + 1}`,
      z
        .number()
        .or(z.string().transform(Number))
        .nullable()
        .optional()
        .refine((val): val is number => val === null || (val ?? 0) > 0, {
          message: 'Please enter a value greater than 0'
        })
    ])
  )
);

export const After365Days = () => {
  const { t } = useTranslation();

  const { pricingSettingsQuery } = useRoomPrices();
  const { data: pricingSettings } = pricingSettingsQuery;
  const {
    isLoading: isSavePricingLoading,
    savePricingSettings,
    isSuccess: isSaveSuccess
  } = useUpdatePricingSettings();
  const { defaultAfter365DailyAverages } = useDefaultOneYearAverages();
  useDocumentTitle(t('After 365 Days'));
  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });

  const {
    mutateAsync: getDefaultAfter365DailyAveragesMutation,
    isPending: isLoadingDefaultAfter365DailyAverages
  } = useMutation({
    mutationKey: [UtilQueryKeys.GET_DEFAULT_AFTER_365_DAILY_AVERAGES],
    mutationFn: getDefaultAfter365DailyAverages
  });

  const monthsData = [];
  for (let i = 12; i < 21; i++) {
    monthsData.push({
      key: i,
      label: dayjs().add(i, 'months').format('MMM') + ' ' + dayjs().add(i, 'months').format('YY')
    });
  }
  const valuesData: Record<string, number | null> = pricingSettings?.after_365_daily_averages
    .monthly_averages
    ? pricingSettings.after_365_daily_averages.monthly_averages
    : (defaultAfter365DailyAverages ? defaultAfter365DailyAverages?.monthly_averages : {}) || {};

  const showMonth = monthsData.map((item, index) => {
    const monthIndex = (dayjs().month() + index + 1) % 12 || 12; // calculating the correct month index
    return {
      value: valuesData[monthIndex.toString()],
      label: item.label,
      month: monthIndex
    };
  });

  const { control, setValue, handleSubmit } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (pricingSettings) {
      Object.entries(valuesData).forEach(([key, value]) => {
        setValue(key as monthKey, value as number);
      });
    }
  }, [pricingSettings, setValue, valuesData]);

  // Get Default After 365 Daily Averages
  const handleGetDefaultAfter365DailyAverages = async () => {
    await getDefaultAfter365DailyAveragesMutation().then((res) => {
      if (res) {
        Object.entries(res.monthly_averages).forEach(([key, value]) => {
          setValue(key as monthKey, value as number);
        });
      }
    });
  };

  const onSubmit = (data: z.infer<typeof schema>) => {
    const settings = JSON.stringify({
      ...pricingSettings,
      after_365_daily_averages: {
        monthly_averages: data
      }
    });
    savePricingSettings(settings);
    pricingSettingsQuery.refetch();
  };

  useEffect(() => {
    setIsSuccessRef({ current: isSaveSuccess });
    if (isSaveSuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isSaveSuccess]);

  return (
    <Page
      header={
        <Header
          title={`${t('After 365 Days')}`}
          description={
            <>
              {t(
                "As we look more than a year into the future, using market prices is less helpful as the data is unreliable. But we still want to give you the possibility to set prices further out. As a solution, we give you the opportunity to set the 'market' prices yourself. These will be replaced by real market data when reliable competitor prices become available."
              )}
              <br />
              {t(
                'To set your own starting prices, first choose your average price for each month.'
              )}
              <br />
            </>
          }
          actions={
            <>
              <Button
                intent="outline"
                onClick={handleGetDefaultAfter365DailyAverages}
                isLoading={isLoadingDefaultAfter365DailyAverages}>
                {t('Set Default')}
              </Button>
              <Button
                disabled={isSavePricingLoading}
                isSuccess={isSuccessRef.current}
                isLoading={isSavePricingLoading}
                intent="primary"
                onClick={handleSubmit(onSubmit)}>
                {t('Save')}
              </Button>
            </>
          }
        />
      }>
      <Divider />
      <div className="my-4 grid grid-cols-1 items-center gap-3 2xl:grid-cols-12 ">
        <div className="col-span-2">
          <Typography variant="paragraph-2" element="p" className="font-light" color="darkGrey">
            {t('Monthly Averages')}
          </Typography>
        </div>
        <div className="col-span-11">
          <div className="grid grid-cols-2 gap-2 md:grid-cols-3 2xl:grid-cols-9">
            {showMonth.map((item, index) => (
              <div key={index} className="w-full">
                <Controller
                  name={item.month.toString() as monthKey}
                  control={control}
                  render={({ field: { onChange, name, value }, fieldState: { error } }) => (
                    <Input
                      name={name}
                      type="number"
                      value={Math.round(value ?? 0)}
                      label={item.label}
                      placeholder={item.label}
                      onChange={onChange}
                      showClearButton={false}
                      error={!!error}
                      leadingAddon={CurrencyFormatter.currencySymbol()}
                      helperMessage={
                        error && (
                          <div className="flex items-center gap-2 text-error">
                            <Typography element="span" color="error" variant="meta-2">
                              {error.message}
                            </Typography>
                          </div>
                        )
                      }
                    />
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Divider />
    </Page>
  );
};
