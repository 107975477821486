import { ErrorResponse } from '@common/api/auth';
import { getPerformanceDashboardReservationKPI } from '@common/api/dashboard';
import { KPI, KPIData, MonthlyBookingCurve } from '@common/api/dashboard/types';
import { useNotificationsStore } from '@common/store/notifications';
import { ReportsQueryKeys } from '@common/types/query-keys';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const usePerformanceDashboardReservationKPI = (supportMultipleHotels = false) => {
  const { hotelDetails } = useHotelDetails();
  const { propertyIds } = useDashboardPageStore();

  const { addNotification } = useNotificationsStore();
  const { t } = useTranslation();

  const query = useQuery<KPI, ErrorResponse>({
    queryKey: [
      ReportsQueryKeys.GET_PERFORMANCE_DASHBOARD_RESERVATION_KPI,
      propertyIds,
      supportMultipleHotels
    ],
    queryFn: () =>
      hotelDetails
        ? getPerformanceDashboardReservationKPI({
            ...(supportMultipleHotels && !isEmpty(propertyIds?.[hotelDetails.id])
              ? {
                  filter_hotels: propertyIds?.[hotelDetails.id]
                }
              : {})
          })
        : Promise.reject('No hotel ID'),
    enabled: !!hotelDetails?.id
  });

  if (query.isError) addNotification('fail', t('Failed to fetch KPI data'));

  const performanceDashboardReservationKPI = useMemo(() => {
    const getKPITableDailyByMonth = (
      type: 'stly' | 'total',
      kpi: keyof KPIData<string>,
      date: string
    ): number => {
      const data = query.data?.kpi_table_daily?.[type]?.[kpi]?.[date];
      return data ?? 0;
    };

    const getKPITable = (view: 'monthly' | 'yearly', type: 'stly' | 'total', date: string) => {
      let data: KPIData<string> | undefined;

      if (view === 'monthly') {
        data = query.data?.kpi_table_monthly?.[type];
      } else {
        data = query.data?.kpi_table_yearly?.[type];
      }

      if (!data) return;

      const yearKey = date.toString();

      const filteredData: Partial<Record<keyof KPIData<string>, number>> | undefined = (
        Object.keys(data) as Array<keyof KPIData<string>>
      ).reduce(
        (result: Partial<Record<keyof KPIData<string>, number>>, key: keyof KPIData<string>) => {
          result[key] = data![key][yearKey] ?? NaN;
          return result;
        },
        {}
      );

      return filteredData;
    };

    const getMonthlyBookingCurve = (type: 'stly' | 'total', date: string) => {
      const data = query.data?.monthly_booking_curve?.[type];

      if (!data) return;

      const filteredData: Partial<Record<string, number>> | undefined = (
        Object.keys(data) as Array<keyof MonthlyBookingCurve>
      ).reduce((result: Partial<Record<string, number>>) => {
        result = data[date] ?? NaN;
        return result;
      }, {});

      return filteredData;
    };

    const getAllYears = <T extends string>(): number[] => {
      const data = query.data?.kpi_table_yearly?.total;
      const yearsSet = new Set<number>();

      if (!data) return [];

      for (const category in data) {
        for (const year in data[category as keyof KPIData<T>]) {
          yearsSet.add(parseInt(year));
        }
      }

      return Array.from(yearsSet).sort((a, b) => a - b);
    };

    return {
      data: query.data,
      getKPITableDailyByMonth,
      getKPITable,
      getAllYears,
      getMonthlyBookingCurve
    };
  }, [query.data]);

  return { performanceDashboardReservationKPI, query };
};
