import {
  saveHotelPricePerOccupancy,
  saveHotelPricePerRate,
  savePricingSettings,
  updateHotelRoom
} from '@common/api/hotel';
import { HotelPricePerOccupancyRequest, HotelPricePerRateRequest } from '@common/api/hotel/types';
import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { TabBar } from '@common/components/molecules/TabsBar';
import { useAppFlags } from '@common/hooks/useAppFlags';
import { useNotificationsStore } from '@common/store/notifications';
import { useViewStore } from '@common/store/view';
import { useWarnings } from '@common/store/warnings';
import { HotelQueryKeys, PricingQueryKeys, RoomsQueryKeys } from '@common/types/query-keys';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import {
  DerivationsV2Schema,
  editReferenceRoomSchema
} from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import { CurrentAverageChart } from '@pages/Client/PricingStrategy/RoomSetup/components/CurrentAverageChart';
import DerivedRates from '@pages/Client/PricingStrategy/RoomSetup/components/DerivedRates';
import {
  FormContent,
  FormFooter,
  FormHeader,
  FormWrapper
} from '@pages/Client/PricingStrategy/RoomSetup/components/FormLayout';
import OccupancyPricing from '@pages/Client/PricingStrategy/RoomSetup/components/OccupancyPricingEdit';
import PMSMappingEdit from '@pages/Client/PricingStrategy/RoomSetup/components/PmsMappingEditReferenceRoom';
import PricingSetup from '@pages/Client/PricingStrategy/RoomSetup/components/PricingSetupEditReferenceRoom';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { useCreateRateDerivation } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useRateDerivation';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

enum Tabs {
  PricingSetup,
  OccupancyPricing,
  PMSMapping,
  DerivedRates
}

export const EditReferenceRoom: React.FC<{
  defaults: Partial<z.infer<typeof editReferenceRoomSchema>>;
  isOccupancyBasedPricing: boolean;
  isDerivedRates: boolean;
  roomInPmsOptions: { label: string; value: number }[] | undefined;
  baseRateInPmsOptions: (pmsRoom: number) => { label: string; value: number }[] | undefined;
  roomOccupancyPricingOptions: (pmsRoom: number) => { label: string; value: number }[] | undefined;
  onDrawerClose: () => void;
}> = ({
  defaults,
  isOccupancyBasedPricing,
  isDerivedRates,
  baseRateInPmsOptions,
  roomOccupancyPricingOptions,
  roomInPmsOptions,
  onDrawerClose
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(Tabs.PricingSetup);
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const { createWarning } = useWarnings();
  const { newBasePrice, newMinPrice, newMaxPrice, isAbsoluteAdjustmentToReferenceRoom } =
    useRoomSetupStore();
  const { hotelPmsDataMap } = useHotelPmsDataMap();
  const {
    pricingSettings,
    pricingSettingsQuery: { refetch: refetchPricingSettings }
  } = usePricingSettings();
  const [currentAverageModalOpen, setCurrentAverageModalOpen] = useState(false);
  const { addNotification } = useNotificationsStore();
  const { view } = useViewStore();
  const queryClient = useQueryClient();
  const { pmsList } = useGetHotelPmsList();
  const getConnectedPms = pmsList?.find((item) => item.primary);
  const isIntegrationV2 = getConnectedPms?.use_v2_integration;
  const { hotelDetails } = useHotelDetails();
  // Flag to determine if the derived rates v2 feature is enabled
  const { isDerivedRatesV2Enabled } = useAppFlags();
  const { createRatePlanDerivations, isCreateRatePlanDerivations } = useCreateRateDerivation();

  const editRefRoomSchema = isDerivedRatesV2Enabled
    ? editReferenceRoomSchema.extend({
        derivedRates: DerivationsV2Schema
      })
    : editReferenceRoomSchema;

  const schema = editRefRoomSchema;

  const { mutateAsync: saveDerivedRates, isPending: isSaveDerivedRates } = useMutation({
    mutationKey: [PricingQueryKeys.SAVE_DERIVED_RATES],
    mutationFn: saveHotelPricePerRate
  });
  const { mutateAsync: updatePricingSettingsMutation, isPending: isUpdatePricingSettings } =
    useMutation({
      mutationKey: [PricingQueryKeys.UPDATE_PRICING_SETTINGS],
      mutationFn: savePricingSettings,
      onSuccess: () => {
        setIsFormSuccess(true);
        setTimeout(() => {
          setIsFormSuccess(false);
        }, 2000);
        queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
        queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.GET_HOTEL_ROOMS_LIST] });
        queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_DATA_MAP] });
        queryClient.invalidateQueries({
          queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_RATE, defaults.pmsMapping?.roomInPms]
        });
        onDrawerClose();
        addNotification('success', 'Successfully Updated Room');
      }
    });
  const { mutateAsync: updateHotelRoomMutation, isPending: isUpdateHotelRoom } = useMutation({
    mutationKey: [RoomsQueryKeys.UPDATE_HOTEL_ROOM],
    mutationFn: updateHotelRoom
  });
  const {
    mutateAsync: saveHotelPricePerOccupancyMutation,
    isPending: isSaveHotelPricePerOccupancy
  } = useMutation({
    mutationKey: [PricingQueryKeys.SAVE_HOTEL_PRICE_PER_OCCUPANCY],
    mutationFn: saveHotelPricePerOccupancy,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_OCCUPANCY, defaults.pmsMapping?.roomId]
      });
    }
  });
  const methods = useForm<z.infer<typeof schema>>({
    mode: 'onSubmit',
    defaultValues: {
      pmsMapping: {
        priceReferenceRoomOnly: defaults.pmsMapping?.priceReferenceRoomOnly || false,
        roomInPms: defaults.pmsMapping?.roomInPms || null,
        baseRateInPms: defaults.pmsMapping?.baseRateInPms || null,
        priceType: defaults.pmsMapping?.priceType || 'unit_based',
        roomOccupancyForPricing: defaults.pmsMapping?.roomOccupancyForPricing || null,
        roomName: defaults.pmsMapping?.roomName || '',
        numberOfRooms: defaults.pmsMapping?.numberOfRooms || 0,
        virtualRoomType: defaults.pmsMapping?.virtualRoomType || false,
        numberOfBedsPerPhysicalRoom: defaults.pmsMapping?.numberOfBedsPerPhysicalRoom || undefined,
        roomId: defaults.pmsMapping?.roomId || 0
      },
      pricingSetup: {
        basePrice: defaults.pricingSetup?.basePrice || 0,
        defaultMinPrice: defaults.pricingSetup?.defaultMinPrice || 0,
        defaultMaxPrice: defaults.pricingSetup?.defaultMaxPrice || 0,
        derivationFromReferenceRoom: defaults.pricingSetup?.derivationFromReferenceRoom || 0,
        lockDerivationWhenBasePriceChanges:
          defaults.pricingSetup?.lockDerivationWhenBasePriceChanges,
        variableCost: defaults.pricingSetup?.variableCost || 0,
        roundPriceSetting: defaults.pricingSetup?.roundPriceSetting || null,
        dontCountUploads: defaults.pricingSetup?.dontCountUploads || false
      },
      hotel: {
        automatic_inflation_increase: pricingSettings?.hotel?.automatic_inflation_increase || false,
        yearly_percent_increase: pricingSettings?.hotel?.yearly_percent_increase || 0
      },
      occupancyPricing: {
        defaultOccupancy: defaults.occupancyPricing?.defaultOccupancy || 0,
        additionalAdultPrice: defaults.occupancyPricing?.additionalAdultPrice || 0,
        additionalChildPrice: defaults.occupancyPricing?.additionalChildPrice || 0
      }
    },
    resolver: zodResolver(schema)
  });

  const CurrentTabContentComponent = useMemo(() => {
    switch (activeTab) {
      case Tabs.PMSMapping:
        return PMSMappingEdit;
      case Tabs.PricingSetup:
        return PricingSetup;
      case Tabs.OccupancyPricing:
        return OccupancyPricing;
      case Tabs.DerivedRates:
        return DerivedRates;
      default:
        return null;
    }
  }, [activeTab]);

  const handleCloseCurrentAverageModal = (ok: boolean) => {
    if (!ok) {
      methods.setValue('pricingSetup.basePrice', newBasePrice);
      setCurrentAverageModalOpen(false);
      return;
    }

    methods.setValue('pricingSetup.basePrice', newBasePrice);
    methods.setValue('pricingSetup.defaultMinPrice', newMinPrice);
    methods.setValue('pricingSetup.defaultMaxPrice', newMaxPrice);
    setCurrentAverageModalOpen(false);
  };

  const handleSubmit = async () => {
    // return;
    const {
      pmsMapping,
      pricingSetup,
      occupancyPricing,
      derivedRates,
      hotel: hotelPricingSetup
    } = methods.getValues();
    console.log('derivedRatesRefroom', derivedRates);
    console.log('error', methods.formState.errors);

    const derivedRatesRequest: HotelPricePerRateRequest[] =
      derivedRates
        ?.map((rate) => ({
          id: rate.id,
          derivation: rate.derivation || 0,
          is_absolute: !rate.percentDerivation || false,
          is_product_derivation: rate.productDerivation || false,
          pms_rate: rate.derivedRateInPms || null,
          hotel: hotelDetails?.id || 0,
          pms_room: defaults.pmsMapping?.roomInPms || null
        }))
        .filter((item) => item.pms_rate !== null) || [];

    const submitFn = async () => {
      if (derivedRates) {
        if (isDerivedRatesV2Enabled) {
          await createRatePlanDerivations({
            id: pmsMapping?.roomInPms,
            payload: {
              derivations: derivedRates
                .map((rate) => ({
                  target_rate_plan_id: Number(rate.derivedRateInPms),
                  derivation: rate?.derivation !== undefined ? Number(rate.derivation) : 0,
                  is_absolute:
                    rate.percentDerivation === undefined ? true : !rate.percentDerivation,
                  occupancy_derivation:
                    isNil(rate.occupancyDerivation) || rate.occupancyDerivation === 0
                      ? null
                      : Number(rate.occupancyDerivation),
                  configurations:
                    rate.minOccupancy !== undefined || rate.maxOccupancy !== undefined
                      ? {
                          min_occ: rate.minOccupancy,
                          max_occ: rate.maxOccupancy
                        }
                      : {},
                  flags: {
                    using_occupancy_constrations:
                      rate.minOccupancy !== undefined && rate.maxOccupancy !== undefined
                  }
                }))
                .filter((item) => item.target_rate_plan_id)
            }
          });
        } else {
          await saveDerivedRates({ id: pmsMapping?.roomInPms, data: derivedRatesRequest });
        }
      }

      await updateHotelRoomMutation({
        room_id: defaults.pmsMapping?.roomId,
        data: {
          name: pmsMapping.roomName || '',
          default_occupancy: occupancyPricing.defaultOccupancy || 0,
          configurations: isIntegrationV2
            ? {
                price_type: pmsMapping.priceType || 'unit_based'
              }
            : {},
          extra: !isIntegrationV2
            ? pmsMapping.priceType
            : hotelPmsDataMap?.mapped_data.find((value) => value.id === pmsMapping.roomInPms)
                ?.extra || null,
          extra_child: occupancyPricing.additionalChildPrice || null,
          discount_per_person: occupancyPricing.additionalAdultPrice ?? 0,
          ignore_upload_count: pricingSetup.dontCountUploads || false,
          pms_price_applicable_occupancy_id: pmsMapping.roomOccupancyForPricing || null,
          pms_rate_id:
            hotelPmsDataMap?.mapped_data
              .find((value) => value.id === pmsMapping.roomInPms)
              ?.rates.find((rate) => rate.id === pmsMapping.baseRateInPms)?.id || null,
          pms_room_id: pmsMapping.roomInPms || null,
          // deprecated field (used to be used for Beds24(old) PMS integration)
          use_prices_from: '0'
        }
      });

      if (occupancyPricing.occupancyDerivation) {
        await saveHotelPricePerOccupancyMutation({
          id: defaults.pmsMapping?.roomId,
          data: Object.values(occupancyPricing.occupancyDerivation).map(
            (item) =>
              ({
                occupancy: +item.occupancy,
                derivation: +item.derivation,
                room: +item.room
              }) as HotelPricePerOccupancyRequest
          )
        });
      }

      await refetchPricingSettings();

      if (!defaults.pmsMapping?.roomId) return;

      await updatePricingSettingsMutation(
        JSON.stringify({
          ...pricingSettings,
          hotel: {
            ...pricingSettings?.hotel,
            all_room_pricing: pmsMapping.priceReferenceRoomOnly === true ? false : true,
            round_price_setting:
              pricingSetup.roundPriceSetting === null ? null : +pricingSetup.roundPriceSetting,
            adjustment_to_reference_room_is_locked:
              pricingSetup.lockDerivationWhenBasePriceChanges || false,
            min_price: 0,
            automatic_inflation_increase: hotelPricingSetup?.automatic_inflation_increase || false,
            yearly_percent_increase: Number(hotelPricingSetup?.yearly_percent_increase) || 0
          },
          rooms: {
            ...pricingSettings?.rooms,
            reference: {
              ...pricingSettings?.rooms?.reference,
              name: pmsMapping.roomName || '',
              number_of_rooms: +(pmsMapping.numberOfRooms || 0),
              virtual_room_type: pmsMapping.virtualRoomType || false,
              number_of_beds_per_physical_room: pmsMapping.numberOfBedsPerPhysicalRoom
                ? pmsMapping.virtualRoomType
                  ? +pmsMapping.numberOfBedsPerPhysicalRoom
                  : undefined
                : undefined,
              variable_cost_per_room: +(pricingSetup.variableCost || 0)
            }
          },
          default: {
            ...pricingSettings?.default,
            [defaults.pmsMapping?.roomId]: {
              ...pricingSettings?.default?.[defaults.pmsMapping?.roomId],
              avg_price: +(pricingSetup.basePrice || 0),
              max_price: +(pricingSetup.defaultMaxPrice || 0),
              min_price: +(pricingSetup.defaultMinPrice || 0),
              adjustment_to_reference_room: +(pricingSetup.derivationFromReferenceRoom || 0)
            },
            ...(isAbsoluteAdjustmentToReferenceRoom
              ? Object.fromEntries(
                  Object.entries(pricingSettings?.default || {})
                    .filter(([key]) => +key !== defaults.pmsMapping?.roomId)
                    .map(([key, value]) => [
                      key,
                      {
                        ...value,
                        adjustment_to_reference_room:
                          pricingSetup.lockDerivationWhenBasePriceChanges
                            ? +value.adjustment_to_reference_room
                            : +(value.avg_price - +pricingSetup.basePrice),
                        avg_price: pricingSetup.lockDerivationWhenBasePriceChanges
                          ? +(+pricingSetup.basePrice + value.adjustment_to_reference_room)
                          : +value.avg_price
                      }
                    ])
                )
              : Object.fromEntries(
                  Object.entries(pricingSettings?.default || {})
                    .filter(([key]) => +key !== defaults.pmsMapping?.roomId)
                    .map(([key, value]) => [
                      key,
                      {
                        ...value,
                        adjustment_to_reference_room:
                          pricingSetup.lockDerivationWhenBasePriceChanges
                            ? +value.adjustment_to_reference_room
                            : Math.round(
                                ((value.avg_price - +pricingSetup.basePrice) /
                                  +pricingSetup.basePrice) *
                                  100
                              ),
                        avg_price: pricingSetup.lockDerivationWhenBasePriceChanges
                          ? +(
                              +pricingSetup.basePrice *
                              (1 + value.adjustment_to_reference_room / 100)
                            )
                          : +value.avg_price
                      }
                    ])
                ))
          }
        })
      );
    };

    const warningsToProcess: Promise<void>[] = [];

    const addWarningIfNeeded = ({
      condition,
      warningMessage,
      confirmationMessage,
      title
    }: {
      condition: boolean;
      warningMessage: string;
      confirmationMessage: string;
      title: string;
    }) => {
      if (condition) {
        warningsToProcess.push(
          createWarning({
            message: t(warningMessage),
            ignore: false,
            ignoreLabel: t('Cancel') as string,
            dismissLabel: t(confirmationMessage) as string,
            title: t(title) as string
          })
        );
      }
    };

    // Check if the base price is more than 110% or less than 90% of the default base price.
    // If this is true, add a warning message. This covers the case when the price is outside the range of 90% to 110% of the default base price.
    addWarningIfNeeded({
      condition:
        pricingSetup.basePrice > (defaults.pricingSetup?.basePrice || 0) * 1.1 ||
        pricingSetup.basePrice < (defaults.pricingSetup?.basePrice || 0) * 0.9,
      warningMessage:
        'We recommend NOT changing the base price unless you are totally sure you know how it affects prices. Changing the base price has the largest effect on your pricing of any setting. Message us if you are not sure, or just use the monthly settings to change shorter periods.',
      confirmationMessage: 'Make the Change to Base Price',
      title: 'Base Price'
    });

    // Check if the base price is within the range of 90% to 110% of the default base price, inclusive.
    // If this is true, add a different warning message. This covers the case when the price is within the range of 90% to 110% of the default base price.
    addWarningIfNeeded({
      condition:
        pricingSetup.basePrice !== (defaults.pricingSetup?.basePrice || 0) &&
        pricingSetup.basePrice <= (defaults.pricingSetup?.basePrice || 0) * 1.1 &&
        pricingSetup.basePrice >= (defaults.pricingSetup?.basePrice || 0) * 0.9 &&
        (pricingSetup.basePrice >= (defaults.pricingSetup?.basePrice || 0) * 1.02 ||
          pricingSetup.basePrice <= (defaults.pricingSetup?.basePrice || 0) * 0.98),
      warningMessage:
        'This change can move all of your pricing.  Please only use this if you are completely sure of the effect. Message us if you need help.',
      confirmationMessage: 'I want to do it anyway',
      title: 'Base Price'
    });

    addWarningIfNeeded({
      condition:
        pricingSetup.defaultMinPrice < (pricingSetup.basePrice || 0) * 0.5 &&
        pricingSetup.defaultMinPrice > (pricingSetup.basePrice || 0) * 0.3,
      warningMessage:
        'This is unusually low for a minimum price - normally we would set this to around 60% of the base price.',
      confirmationMessage: 'Confirm',
      title: 'Default Minimum Price'
    });

    addWarningIfNeeded({
      condition: pricingSetup.defaultMinPrice < (pricingSetup.basePrice || 0) * 0.3,
      warningMessage:
        'We would normally recommend not setting a minimum price this much lower than your base price. Are you sure?',
      confirmationMessage: 'Confirm',
      title: 'Default Minimum Price'
    });

    addWarningIfNeeded({
      condition:
        pricingSetup.defaultMinPrice > (pricingSetup.basePrice || 0) * 0.8 &&
        pricingSetup.defaultMinPrice < (pricingSetup.basePrice || 0) * 0.9,
      warningMessage:
        'This is unusually high for a minimum price, compared to your base price. We would recommend going lower to allow more bookings in low-demand times.',
      confirmationMessage: 'Confirm',
      title: 'Default Minimum Price'
    });

    addWarningIfNeeded({
      condition: pricingSetup.defaultMinPrice > (pricingSetup.basePrice || 0) * 0.9,
      warningMessage:
        'We would strongly recommend a lower minimum price when you have this base price set. Are you sure?',
      confirmationMessage: 'Confirm',
      title: 'Default Minimum Price'
    });

    addWarningIfNeeded({
      condition: pricingSetup.defaultMaxPrice < (pricingSetup.basePrice || 0) * 1.2,
      warningMessage:
        'We would strongly recommend a higher maximum price when you have this base price set. Are you sure?',
      confirmationMessage: 'Confirm',
      title: 'Default Maximum Price'
    });

    addWarningIfNeeded({
      condition:
        pricingSetup.defaultMaxPrice < (pricingSetup.basePrice || 0) * 1.4 &&
        pricingSetup.defaultMaxPrice >= (pricingSetup.basePrice || 0) * 1.2,
      warningMessage:
        'This is unusually low for a maximum price, compared to your base price. We would recommend going higher to allow more profit in busy times.',
      confirmationMessage: 'Confirm',
      title: 'Default Maximum Price'
    });

    addWarningIfNeeded({
      condition: pricingSetup.defaultMaxPrice > (pricingSetup.basePrice || 0) * 10,
      warningMessage:
        'This is unusually high for a maximum price and may lead to unhappy clients. Could you try a lower maximum but put a Min Stay restriction in instead?',
      confirmationMessage: 'Confirm',
      title: 'Default Maximum Price'
    });

    addWarningIfNeeded({
      condition: (pricingSetup.variableCost || 0) < pricingSetup.defaultMinPrice * 0.3,
      warningMessage:
        'This is unusually low and can result in lower prices than optimal. Normally we set this to around 50% of the minimum price.',
      confirmationMessage: 'Confirm',
      title: 'Variable Cost'
    });

    const processWarnings = async () => {
      if (warningsToProcess.length > 0) {
        try {
          await warningsToProcess.shift();
          processWarnings();
        } catch {
          console.log('User cancelled warning');
        }
      } else {
        submitFn();
      }
    };

    await processWarnings();
  };

  return (
    <FormProvider {...methods}>
      <form
        key="edit-reference-room"
        className="relative h-full"
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <FormWrapper>
          <FormHeader>
            <Typography variant="h5" element="h3" color="darkGrey">
              {t(`Edit`)} {defaults.pmsMapping?.roomName}
            </Typography>

            <div className="mt-3">
              <TabBar
                onTabClick={setActiveTab}
                options={[
                  { label: t('Pricing Setup'), value: Tabs.PricingSetup },
                  ...(isOccupancyBasedPricing ||
                  methods.watch('pmsMapping.priceType') === 'occupancy_based'
                    ? [{ label: t('Occupancy Pricing'), value: Tabs.OccupancyPricing }]
                    : []),
                  {
                    label: hotelDetails?.is_channel_manager
                      ? t('Channel Manager Mapping')
                      : t('PMS Mapping'),
                    value: Tabs.PMSMapping
                  },
                  ...(isDerivedRates
                    ? [{ label: t('Derived Rates'), value: Tabs.DerivedRates }]
                    : [])
                ]}
                activeTab={activeTab}
              />
            </div>
          </FormHeader>

          <FormContent>
            {CurrentTabContentComponent && (
              <CurrentTabContentComponent
                baseRateInPmsOptions={
                  baseRateInPmsOptions(methods.watch('pmsMapping.roomInPms') || 0) || []
                }
                roomInPmsOptions={roomInPmsOptions || []}
                roomOccupancyPricingOptions={
                  roomOccupancyPricingOptions(methods.watch('pmsMapping.roomInPms') || 0) || []
                }
              />
            )}
          </FormContent>

          <FormFooter>
            <Button type="button" intent="text" className="text-darkGrey" onClick={onDrawerClose}>
              {t('Cancel')}
            </Button>
            {activeTab === Tabs.PricingSetup ? (
              view === 'admin' ? (
                <Button
                  type="button"
                  intent="outline"
                  onClick={() => setCurrentAverageModalOpen(true)}
                >
                  {t('Get Current Average')}
                </Button>
              ) : null
            ) : null}
            <Button
              type="submit"
              intent="primary"
              isLoading={
                isSaveDerivedRates ||
                isCreateRatePlanDerivations ||
                isUpdateHotelRoom ||
                isSaveHotelPricePerOccupancy ||
                isUpdatePricingSettings
              }
              isSuccess={isFormSuccess}
            >
              {t('Save')}
            </Button>
          </FormFooter>
        </FormWrapper>
      </form>

      <Modal
        className="z-[9999]"
        open={currentAverageModalOpen}
        size="full"
        onCloseModal={() => setCurrentAverageModalOpen(false)}
        onClose={handleCloseCurrentAverageModal}
        cancelText={`${t('Apply Base Price')}`}
        okText={`${t('Apply Base, Min & Max Price')}`}
      >
        <CurrentAverageChart
          roomId={defaults.pmsMapping?.roomId}
          variableCost={defaults.pricingSetup?.variableCost}
        />
      </Modal>
    </FormProvider>
  );
};
