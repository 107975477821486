import {
  Checkbox,
  Modal,
  Text,
  Button,
  ScrollArea,
  Stack,
  SimpleGrid,
  Paper,
  Title,
  Flex,
  rem
} from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Page } from '@common/components/organisms/Page';
import {
  addFreeTrialTime,
  getHotelFreeToBookAutoUploadDisableClientAccess,
  savePricingSettings,
  updateHotelCalculateNoOfRooms,
  updateHotelRoomDerivedGenerateMaxMinPrice,
  updateHourlyExecution
} from '@common/api/hotel';
import { PricingSettings } from '@common/api/hotel/types';
import { generateDefaultValueSet } from '@common/api/pricingAlgorithm';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { DatePicker } from '@common/components/molecules/DatePicker';
import { Header } from '@common/components/molecules/Header/Header';
import { FeatureType, useFeatureDescription } from '@common/hooks/useFeatureDescription';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useWarnings } from '@common/store/warnings';
import { cn } from '@common/utils/cn';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';
import { HotelState } from '@pages/Client/hooks/useSubscription';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import {
  AccountQueryKeys,
  FeaturesQueryKeys,
  HotelQueryKeys,
  PricingQueryKeys,
  RoomsQueryKeys,
  UtilQueryKeys
} from '@common/types/query-keys';

interface GetDisabledStatusParams {
  featureId: number;
  features?: PricingSettings['features'];
  totalNumberOfRooms?: PricingSettings['hotel']['number_of_rooms'];
  isReportingAvailable?: boolean;
}

const getDisabledStatus = ({
  featureId,
  features,
  totalNumberOfRooms,
  isReportingAvailable
}: GetDisabledStatusParams) => {
  switch (featureId) {
    case Feature.PartnerSubscriptionPage:
      return features?.includes(Feature.SmallBusiness);
    case Feature.DashboardOnly:
      return !features?.includes(Feature.LimitedDashboard);
    case Feature.StarterUpload:
      return (
        features?.includes(Feature.AdvancedUpload) || features?.includes(Feature.UploadAllRuns)
      );
    case Feature.SmallBusiness:
      return features?.includes(Feature.PartnerSubscriptionPage);
    case Feature.AdvancedUpload:
      return features?.includes(Feature.StarterUpload) || features?.includes(Feature.UploadAllRuns);
    case Feature.MonthlyGroupBookings:
      return features?.includes(Feature.Segmentation);
    case Feature.LimitedDashboard:
      return !isReportingAvailable;
    case Feature.ProfessionalDashboard:
      return !features?.includes(Feature.LimitedDashboard);
    case Feature.Segmentation:
      return features?.includes(Feature.MonthlyGroupBookings);
    case Feature.UploadAllRuns:
      return (
        features?.includes(Feature.AdvancedUpload) || features?.includes(Feature.StarterUpload)
      );
    case Feature.SurgeProtection:
      return !(totalNumberOfRooms && totalNumberOfRooms >= 3);
    case Feature.SurgeProtectionPro:
      return !features?.includes(Feature.SurgeProtection);
    case Feature.MinimumStayRestrictions:
      return !features?.includes(Feature.LimitedMinimumStayRestrictions);
    case Feature.LimitedMarketInsights:
      return !features?.includes(Feature.AirBNB);
    case Feature.ProMarketInsights:
      return !features?.includes(Feature.LimitedMarketInsights);
    case Feature.PickupBoostPrice:
    case Feature.AirBNB:
      return features?.includes(Feature.NoMarketData);
    default:
      return false;
  }
};

export const Features = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Features'));
  const { addNotification } = useNotificationsStore();
  const {
    pricingSettings,
    pricingSettingsQuery: { refetch: refetchPricingSettings }
  } = usePricingSettings();
  const { hotelDetails } = useHotelDetails();
  const [isCalculateNoOfRooms, setIsCalculateNoOfRooms] = useState(false);
  const [isDefaultMinMax, setIsDefaultMinMax] = useState(false);
  const [pendingSubmit, setPendingSubmit] = useState(false);
  const [isAutoProtectionEnabled, setIsAutoProtectionEnabled] = useState(false);
  const { createWarning } = useWarnings();
  const navigate = useNavigate();
  const isReferenceRoomOnly = pricingSettings?.hotel.all_room_pricing === true ? false : true;
  const { featuresByPlan } = useFeatureDescription();

  const { features: featuresFromStore, setFeatures } = useFeaturesStore();
  const {
    hasMinStayEnabled,
    hasClosedRoomDetection,
    hasDashboardEnabled,
    shouldDisableReporting,
    hasNoMarketData
  } = usePmsProvider();

  const { handleSubmit, control, reset, formState, getValues, setValue, watch } = useForm<{
    features: { [key: string]: boolean };
    trialDate: Date;
  }>({
    mode: 'onSubmit'
  });
  const [freeTrialModalOpen, setFreeTrialModalOpen] = useState(false);
  const [defaultMinMaxModalOpen, setDefaultMinMaxModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateHotelRoomDerivedGenerateMaxMinPriceMutation,
    isPending: isLoadingUpdateHotelRoomDerivedGenerateMaxMinPrice
  } = useMutation({
    mutationKey: [HotelQueryKeys.UPDATE_HOTEL_ROOM_DERIVED_GENERATE_MAX_MIN_PRICE],
    mutationFn: updateHotelRoomDerivedGenerateMaxMinPrice,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
    }
  });
  const {
    mutateAsync: updateHotelCalculateNoOfRoomsMutation,
    isPending: isLoadingUpdateHotelCalculateNoOfRooms
  } = useMutation({
    mutationKey: [RoomsQueryKeys.UPDATE_HOTEL_CALCULATE_NO_OF_ROOMS],
    mutationFn: updateHotelCalculateNoOfRooms
  });

  const {
    mutateAsync: generateDefaultValueSetMutation,
    isPending: isLoadingGenerateDefaultValueSet
  } = useMutation({
    mutationKey: [UtilQueryKeys.GENERATE_DEFAULT_VALUE_SET],
    mutationFn: generateDefaultValueSet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
    }
  });

  const { mutateAsync: updateHourlyExecutionMutation, isPending: isUpdatingHourlyExecution } =
    useMutation({
      mutationKey: [UtilQueryKeys.UPDATE_HOURLY_EXECUTION],
      mutationFn: updateHourlyExecution
    });
  const { mutateAsync: updateFeatures, isPending: isUpdatingFeatures } = useMutation({
    mutationKey: [FeaturesQueryKeys.UPDATE_FEATURES],
    mutationFn: savePricingSettings,
    onSuccess: (data) => {
      addNotification('success', 'Updated features successfully');
      setIsDefaultMinMax(false);
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
      setFeatures(data.data.features);
    }
  });
  const { mutateAsync: getHotelFreeToBookAutoUploadDisableClientAccessMutation } = useMutation({
    mutationKey: [HotelQueryKeys.GET_HOTEL_FREE_TO_BOOK_AUTO_UPLOAD_DISABLE_CLIENT_ACCESS],
    mutationFn: getHotelFreeToBookAutoUploadDisableClientAccess
  });
  const { mutateAsync: addFreeTrialTimeMutation } = useMutation({
    mutationKey: [AccountQueryKeys.ADD_FREE_TRIAL_TIME],
    mutationFn: addFreeTrialTime
  });

  const featureValues = watch('features');

  useEffect(() => {
    if (!pricingSettings || !hotelDetails || !featuresFromStore) return;
    // Initialize an empty object to hold the form values
    const formValues: { [key: string]: boolean | undefined } = {};

    const featureGroups = featuresByPlan;
    for (const group in featureGroups) {
      const nestedGroups = featureGroups[group];

      for (const nestedGroup in nestedGroups) {
        const features = nestedGroups[nestedGroup];

        for (const feature of features) {
          // Add a field to formValues for the current feature
          formValues[feature.id.toString()] = featuresFromStore?.includes(feature.id);
        }
      }
    }
    // Set auto protection
    setIsAutoProtectionEnabled(pricingSettings?.surge_protection?.auto_price_protection);
    // Update all form values at once using reset
    reset({
      features: formValues,
      trialDate: dayjs(hotelDetails?.free_trial_end).toDate()
    });
  }, [pricingSettings, featuresFromStore, hotelDetails]);

  const onSubmit = async (defaultMinMax: boolean) => {
    try {
      const features = getValues('features');
      const shouldOpenModal =
        !featuresFromStore?.includes(Feature.IndividualMinMax) &&
        features[Feature.IndividualMinMax] &&
        !isDefaultMinMax;

      if (shouldOpenModal && !pendingSubmit) {
        setDefaultMinMaxModalOpen(true);
        setPendingSubmit(true);
        return;
      }

      const enabledFeatures = Object.entries(features)
        ?.map(([key, value]) => ({ id: key, value }))
        .filter((feature) => feature.value === true)
        .map((feature) => Number(feature.id));

      await refetchPricingSettings();

      const updatedSettings = {
        ...pricingSettings,
        features: enabledFeatures,
        ...(enabledFeatures.includes(Feature.MinimumStayRestrictions)
          ? {
              default: {
                ...pricingSettings?.default,
                ...Object.fromEntries(
                  Object.entries(pricingSettings?.default || {}).map(([key, value]) => [
                    key,
                    {
                      ...value,
                      is_orphan_gap_correction_enabled: false
                    }
                  ])
                )
              }
            }
          : {}),
        surge_protection: {
          ...pricingSettings?.surge_protection,
          auto_price_protection: isAutoProtectionEnabled
        }
      };
      await updateFeatures(JSON.stringify(updatedSettings));

      if (defaultMinMax) {
        await updateHotelRoomDerivedGenerateMaxMinPriceMutation(true);
        await refetchPricingSettings();
      }

      if (isCalculateNoOfRooms) {
        await updateHotelCalculateNoOfRoomsMutation(isCalculateNoOfRooms);
      }

      const include_in_hourly_execution = enabledFeatures.includes(Feature.LimitedDashboard);
      await updateHourlyExecutionMutation(include_in_hourly_execution);

      if (enabledFeatures.includes(Feature.DisableClientAccess)) {
        await getHotelFreeToBookAutoUploadDisableClientAccessMutation();
      }
      if (enabledFeatures.includes(Feature.EighteenMonthsPricing)) {
        await generateDefaultValueSetMutation();
      }
    } catch (error) {
      addNotification('fail', 'Error updating features');
    }
  };

  const handleCloseFreeTrialModal = () => {
    reset({ trialDate: dayjs(hotelDetails?.free_trial_end).toDate() });
    setFreeTrialModalOpen(false);
  };

  const handleSubmitFreeTrialModal = async () => {
    await addFreeTrialTimeMutation({
      free_trial_end: dayjs(getValues('trialDate')).format('YYYY-MM-DD')
    });
    // Submit feature update
    await onSubmit(true);
    setFreeTrialModalOpen(false);
  };

  const handleCloseDefaultMinMaxModal = () => {
    setIsDefaultMinMax(false);
    if (pendingSubmit) {
      setPendingSubmit(false);
    }
    setDefaultMinMaxModalOpen(false);
  };

  const handleSubmitDefaultMinMaxModal = async (ok: boolean) => {
    setIsDefaultMinMax(ok);
    if (pendingSubmit) {
      await onSubmit(ok);
      setPendingSubmit(false);
    }
    setDefaultMinMaxModalOpen(false);
  };

  const isDisabled = useCallback(
    (featureId: number) => {
      if (!featureValues) return false;
      const features = Object.keys(featureValues)
        ?.filter((id) => featureValues[id])
        .map(Number);
      const numberOfRooms = pricingSettings?.hotel?.number_of_rooms;

      return getDisabledStatus({
        featureId,
        features,
        totalNumberOfRooms: numberOfRooms,
        isReportingAvailable: hasDashboardEnabled() && !shouldDisableReporting()
      });
    },
    [pricingSettings, featureValues]
  );

  const isLoading =
    isLoadingUpdateHotelRoomDerivedGenerateMaxMinPrice ||
    isUpdatingFeatures ||
    isUpdatingHourlyExecution ||
    isLoadingUpdateHotelCalculateNoOfRooms ||
    isLoadingGenerateDefaultValueSet ||
    isLoadingUpdateHotelCalculateNoOfRooms;

  interface Section {
    title: string;
    features: FeatureType[];
    height?: string;
  }

  interface Group {
    title: string;
    alignment: 'row' | 'column';
    grid?: number;
    sections: Section[];
  }

  interface FeatureGroups {
    [key: string]: Group[];
  }

  const SECTION_HEIGHT = rem(450);

  const featureGroups: FeatureGroups = {
    row1: [
      {
        title: 'General Feature Options',
        alignment: 'row',
        grid: 3,
        sections: [
          {
            title: 'General Settings',
            features: featuresByPlan.general?.generalSettings
          },
          {
            title: 'Lead Generation Products',
            features: featuresByPlan.general?.leadGeneration
          }
        ]
      }
    ],
    row2: [
      {
        title: 'Starter Plan',
        alignment: 'column',
        sections: [
          {
            title: 'Pricing Features',
            features: featuresByPlan.starter?.pricingFeatures,
            height: SECTION_HEIGHT
          },
          {
            title: 'Data Insights',
            features: featuresByPlan.starter?.dataInsights
          }
        ]
      },
      {
        title: 'Advanced Plan',
        alignment: 'column',
        sections: [
          {
            title: 'Pricing Features',
            features: featuresByPlan.advanced?.pricingFeatures,
            height: SECTION_HEIGHT
          },
          {
            title: 'Data Insights',
            features: featuresByPlan.advanced?.dataInsights
          }
        ]
      },
      {
        title: 'Professional Plan',
        alignment: 'column',
        sections: [
          {
            title: 'Pricing Features',
            features: featuresByPlan.professional?.pricingFeatures,
            height: SECTION_HEIGHT
          },
          {
            title: 'Data Insights',
            features: featuresByPlan.professional?.dataInsights
          }
        ]
      }
    ]
  };

  const renderFeatureGroup = (group: Group) => {
    return (
      <>
        <Title order={3} variant="h6" fw="500" c="dark.9">
          {group.title}
        </Title>
        <Paper shadow="xs" p="md" className="flex-1">
          <Stack gap="lg" pb="xs" className="content-start">
            <SimpleGrid
              cols={{
                base: 1,
                md: group.grid ? group.grid : 1
              }}
              spacing="lg">
              {group.sections.map((section) => (
                <Stack
                  gap="lg"
                  key={section.title}
                  h={section?.height}
                  className={cn(section?.height ? 'border-b border-lightGrey' : undefined)}>
                  <Text size="sm" fw="500" component="h2" c="dark.9">
                    {section.title}
                  </Text>
                  {section.features?.map((feature) => {
                    const disabledStatus = isDisabled(feature.id);

                    useEffect(() => {
                      if (disabledStatus) {
                        setValue(`features.${feature.id.toString()}`, false);
                      }
                    }, [disabledStatus]);

                    if (
                      hotelDetails &&
                      feature.id === Feature.ClosedRoomDetection &&
                      !hasClosedRoomDetection()
                    )
                      return null;

                    if (
                      (feature.id === Feature.MinimumStayRestrictions ||
                        feature.id === Feature.LimitedMinimumStayRestrictions) &&
                      !hasMinStayEnabled()
                    ) {
                      return false;
                    }

                    if (feature.id === Feature.NoMarketData && !hasNoMarketData()) {
                      return false;
                    }

                    const handleChange = (isChecked: boolean, onChange: any) => {
                      if (
                        feature.id === Feature.DisableClientAccess &&
                        !isChecked &&
                        hotelDetails?.state === HotelState.FREE_TRIAL
                      ) {
                        setFreeTrialModalOpen(true);
                      }

                      if (feature.id === Feature.YieldTag) {
                        setIsCalculateNoOfRooms(true);
                      }
                      // Prevent combo feature enable if reference room only is enabled
                      if (feature.id === Feature.YieldTag && isReferenceRoomOnly && isChecked) {
                        createWarning({
                          message: t(
                            'You need to disable the Price Reference Room Only feature before enabling this.'
                          ),
                          ignore: false,
                          ignoreLabel: t('Cancel') as string,
                          dismissLabel: `${t('Go to Room Setup')}`
                        })
                          .then(() => {
                            navigate(`/client/room-setup/${hotelDetails?.id}`);
                          })
                          .catch(() => {
                            onChange();
                          });
                      }
                      if (!isChecked && feature.id === Feature.SurgeProtectionPro) {
                        setIsAutoProtectionEnabled(false);
                      }
                    };

                    const renderLabel = () => {
                      return (
                        <Flex align="center" gap="xs" miw="0" w="100%">
                          <Text
                            size="sm"
                            c="inherit"
                            lineClamp={2}
                            miw="0"
                            flex="1"
                            className="flex-shrink"
                            title={feature.name}>
                            {feature.name}
                          </Text>
                          <Flex gap="xs" align="center" wrap="nowrap">
                            {import.meta.env.DEV ? `(${feature.id})` : null}
                            {feature?.tooltip ? <TooltipIcon content={feature.tooltip} /> : null}
                            {feature.id === Feature.MinimumStayRestrictions ? (
                              <Typography
                                variant="meta-2"
                                className="inline-flex items-center gap-0.5 text-orange">
                                <Icon.WarningOutline className="h-4 w-4" /> Beta
                              </Typography>
                            ) : null}
                          </Flex>
                        </Flex>
                      );
                    };

                    return (
                      <Controller
                        key={feature.id}
                        control={control}
                        name={`features.${feature.id.toString()}`}
                        defaultValue={featuresFromStore?.includes(feature.id)}
                        render={({ field: { value, onChange } }) => (
                          <div className="flex items-center gap-1.5">
                            <Checkbox
                              label={renderLabel()}
                              id={feature.id.toString()}
                              checked={value}
                              onChange={(e) => {
                                onChange(e);
                                handleChange(e.target.checked, onChange);
                              }}
                              disabled={disabledStatus}
                            />
                          </div>
                        )}
                      />
                    );
                  })}
                </Stack>
              ))}
            </SimpleGrid>
          </Stack>
        </Paper>
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit(() => onSubmit(isDefaultMinMax))}>
      <Page
        header={
          <Header
            title="Features"
            actions={
              <Button
                type="submit"
                disabled={!formState.isDirty || isLoading}
                loading={isUpdatingFeatures}>
                Save Changes
              </Button>
            }
          />
        }>
        {Object.entries(featureGroups).map(([rowKey, rows]) => (
          <Flex
            key={rowKey}
            direction={{
              base: 'column',
              lg: 'row'
            }}
            gap="xl">
            {rows.map((group) => (
              <Stack key={group.title} mb="lg" gap="md" mt="sm" className="flex-1">
                {renderFeatureGroup(group)}
              </Stack>
            ))}
          </Flex>
        ))}
        <Modal.Root
          centered
          size="xs"
          opened={freeTrialModalOpen}
          onClose={handleCloseFreeTrialModal}
          scrollAreaComponent={ScrollArea.Autosize}>
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header bg="white">
              <Modal.Title>Edit Free Trial Date</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>
              <Controller
                control={control}
                name="trialDate"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    ISOWeek={hotelDetails?.starts_monday}
                    selectedDate={value || dayjs().add(7, 'day').toDate()}
                    onDateChange={(date) => {
                      onChange(date);
                    }}
                    timezone={hotelDetails?.timezone}
                    closeOnSelect
                  />
                )}
              />
            </Modal.Body>
            <ModalFooter>
              <Button variant="subtle" onClick={handleCloseFreeTrialModal}>
                Cancel
              </Button>
              <Button onClick={handleSubmitFreeTrialModal}>Save</Button>
            </ModalFooter>
          </Modal.Content>
        </Modal.Root>
        <Modal.Root
          centered
          opened={defaultMinMaxModalOpen}
          onClose={handleCloseDefaultMinMaxModal}
          scrollAreaComponent={ScrollArea.Autosize}>
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header bg="white">
              <Modal.Title>Set default values for min/max?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Text size="sm" component="p">
                Do you want to set the default min/max prices for the derived room types, which are
                derived from the min/max of the reference room?
              </Text>
            </Modal.Body>
            <ModalFooter>
              <Button variant="subtle" onClick={() => handleSubmitDefaultMinMaxModal(false)}>
                No Default, Set Myself
              </Button>
              <Button onClick={() => handleSubmitDefaultMinMaxModal(true)}>Set Default</Button>
            </ModalFooter>
          </Modal.Content>
        </Modal.Root>
      </Page>
    </form>
  );
};
