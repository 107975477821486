import { updateProfile, resetPassword } from '@common/api/account';
import { UpdateProfile, ChangePassword } from '@common/api/account/types';
import { setAuthToken } from '@common/api/axiosInstance';
import { useAuthStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useErrorProfileChangePasswordStore } from '@pages/Client/Account/Profile/store/profile';
import { useMutation } from '@tanstack/react-query';

export const useUpdateProfile = () => {
  const { addNotification } = useNotificationsStore();
  const { token, user, setUser } = useAuthStore();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: async (data: UpdateProfile) => {
      setAuthToken(token);
      const response = await updateProfile(data);
      if (user) {
        setUser({ ...user, ...response.data });
      }
      return response;
    },
    onSuccess: () => {
      addNotification('success', 'Successfully updated profile');
    },
    onError: () => {
      addNotification('fail', 'Failed to update profile');
    }
  });

  return {
    updateProfile: mutate,
    isLoading,
    isSuccess
  };
};

export const useChangePassword = () => {
  const { addNotification } = useNotificationsStore();
  const { token } = useAuthStore();
  const { setError, setMessage, setOldPasswordError, setOldPasswordMessage } =
    useErrorProfileChangePasswordStore();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (data: ChangePassword) => {
      setAuthToken(token);
      return resetPassword(data);
    },
    onSuccess: () => {
      addNotification('success', 'Successfully change password');
    },
    onError: (error: any) => {
      if (error.response.data.password) {
        setError(true);
        setMessage(error.response.data.password.join(','));
      }
      if (error.response.data.authorization) {
        setOldPasswordError(true);
        setOldPasswordMessage(error.response.data.authorization.message);
      }
      addNotification('fail', 'Failed to change password');
    }
  });

  return {
    changePassword: mutate,
    isLoading,
    isSuccess
  };
};
