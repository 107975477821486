export enum Flags {
  MinStayV2 = 'min-stay-v-2',
  PmsConfigFromBackend = 'pms-config-from-backend',
  TenPercentFixedPricesThreshold = 'ten-percent-fixed-prices-threshold',
  AIChatBot = 'ai-chat-bot',
  ClerkSelectiveAuth = 'clerk-selective-auth',
  ShowClerkLogin = 'show-clerk-login',
  ShowClerkComponent = 'show-clerk-components',
  ReportingDashboardV2 = 'reporting-dashboard-v-2',
  HomeScreen = 'home-screen',
  MinStayMultipleRatePlans = 'minstay-multiple-rate-plans',
  DerivedRatesV2 = 'new-rate-plan-derivation'
}
