import { Button } from '@common/components/atoms/Button';
import { useSurgeManageModal } from '@common/store/surgeModal';
import { useBulkEditsStore } from '@pages/Client/Calendar/components/BulkEdit/store/editAdjustmentsStore';
import { BulkEditTabs } from '@pages/Client/Calendar/components/BulkEditTab/BulkEditTabs';
import {
  MappedSurgeEvent,
  SurgeBulkManage
} from '@pages/Client/PricingStrategy/SurgeProtection/SurgeBulkManage';
import { useTranslation } from 'react-i18next';
import { API_DATE_FORMAT } from '@common/constants/date';
import { deactivateSurgeEvents, SurgeDismissalReason } from '@common/api/pricingAlgorithm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotificationsStore } from '@common/store/notifications';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { applyIndividualAdjustment, savePricingSettings } from '@common/api/hotel';
import { produce } from 'immer';
import dayjs from 'dayjs';
import { first, isNil, last, map } from 'lodash-es';
import { PricingSettings } from '@common/api/hotel/types';
import { useState } from 'react';
import { PricingQueryKeys, SurgeEventQueryKeys } from '@common/types/query-keys';

export function BulkSurges() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { pricingSettings, cachePriceQuery, pricingSettingsQuery } = useRoomPrices();
  const { sortedHotelRooms } = useHotelRoomsList();
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotificationsStore();
  const { eventsToDismiss, clearEventsToDismiss } = useSurgeManageModal();
  const { setBulkEditDrawerState, setDateRangeError, setBulkEditViewingDates } =
    useBulkEditsStore();

  const dismissSurgeEvents = useMutation({
    mutationKey: [SurgeEventQueryKeys.DEACTIVATE_SURGE_EVENT],
    mutationFn: deactivateSurgeEvents,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SurgeEventQueryKeys.GET_SURGE_EVENTS] });
      queryClient.invalidateQueries({ queryKey: [SurgeEventQueryKeys.GET_SURGE_LOGS] });
    }
  });

  const removeSurgePrices = useMutation({
    mutationKey: [SurgeEventQueryKeys.REMOVE_SURGE_PRICES],
    mutationFn: savePricingSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
    }
  });

  function handleClose() {
    clearEventsToDismiss();
    setBulkEditDrawerState(false);
    setDateRangeError(undefined);
    setBulkEditViewingDates(undefined);
  }

  async function removeSurgePricesFromSettings(
    eventsToDismiss: MappedSurgeEvent[]
  ): Promise<PricingSettings> {
    return new Promise((resolve, reject) => {
      try {
        const updatedPricingSettings = produce(pricingSettings, (draft) => {
          map(eventsToDismiss, (event) => {
            const formattedDate = dayjs(event.date).format(API_DATE_FORMAT);
            map(sortedHotelRooms, (room) => {
              if (draft?.dates?.[formattedDate]?.[room.id]) {
                delete draft.dates[formattedDate][room.id].surge_protection;
              }
            });
          });
        }) as PricingSettings;
        resolve(updatedPricingSettings);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function handleClick() {
    try {
      setIsLoading(true);
      const newPrices = await removeSurgePricesFromSettings(eventsToDismiss);
      const startDate = first(eventsToDismiss)?.date;
      const endDate = last(eventsToDismiss)?.date;
      if (!startDate || !endDate) throw new Error('Invalid date range');
      await dismissSurgeEvents.mutateAsync({
        ids: map(eventsToDismiss, 'id'),
        dismissReason: SurgeDismissalReason.BULK_DISMISS
      });
      const stringNewPrices = JSON.stringify(newPrices);
      if (isNil(stringNewPrices)) throw new Error('Invalid new prices');
      await removeSurgePrices.mutateAsync(JSON.stringify(newPrices));
      await applyIndividualAdjustment({
        is_write_to_cache: true,
        start_date: startDate,
        end_date: endDate,
        json_settings: JSON.stringify(newPrices)
      });
      await cachePriceQuery.refetch();
      await pricingSettingsQuery.refetch();
      handleClose();
      setIsLoading(false);
      addNotification('success', 'Events dismissed successfully');
    } catch (error) {
      addNotification('fail', 'Failed to dismiss the events');
      setIsLoading(false);
    }
  }

  return (
    <div className="h-full px-5 pb-10 md:px-10">
      <div className="h-full overflow-auto px-0.5">
        <div className="sticky top-0 z-10 mb-5 bg-white bg-opacity-70 backdrop-blur">
          <BulkEditTabs />
        </div>

        <SurgeBulkManage isLoading={isLoading} />
      </div>
      <div className="absolute bottom-0 right-0 w-full rounded-bl-xl border-t border-grey-reduced bg-lightGrey p-5 text-right">
        <div className="flex justify-end gap-2">
          <Button intent="text" onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button
            isLoading={isLoading}
            onClick={handleClick}
            disabled={eventsToDismiss.length === 0}>
            {t('Dismiss Events')}
          </Button>
        </div>
      </div>
    </div>
  );
}
