import { ErrorResponse } from '@common/api/auth';
import { getDashboardDailyReports } from '@common/api/explorer';
import {
  DashboardDailyReportsPayload,
  DashboardDailyReportsResponse
} from '@common/api/explorer/types';
import { useNotificationsStore } from '@common/store/notifications';
import { DashboardDailyReportsQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useDashboardDailyReports = ({
  hotel_id,
  date_from,
  date_to
}: DashboardDailyReportsPayload) => {
  const { addNotification } = useNotificationsStore();

  const query = useQuery<DashboardDailyReportsResponse, ErrorResponse>({
    queryKey: [
      DashboardDailyReportsQueryKeys.GET_DASHBOARD_DAILY_REPORTS,
      hotel_id,
      date_from,
      date_to
    ],
    queryFn: () =>
      getDashboardDailyReports({
        hotel_id,
        date_from,
        date_to
      }),
    enabled: false
  });

  if (query.isError) addNotification('fail', 'Failed to fetch dashboard daily reports');

  const dashboardDailyReports = useMemo(() => {
    return query.data?.data;
  }, [query.data]);

  return { dashboardDailyReports, query };
};
