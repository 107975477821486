import { getErrorTicketList } from '@common/api/errors';
import { ErrorTicketList, ParamsErrorTickets } from '@common/api/errors/types';
import { useNotificationsStore } from '@common/store/notifications';
import { ErrorResponse } from '@common/api/auth';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { isNumber } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { ErrorTicketsQueryKeys } from '@common/types/query-keys';


export const useErrorTicketList = ({
  page = 1,
  ticket_type = 1,
  ticket_status = 1,
  start_date,
  end_date,
  search,
  assignee,
  ticket_tag,
  provider,
  ordering,
  open_api_partner_id
}: ParamsErrorTickets) => {
  const { addNotification } = useNotificationsStore();

  const defaultParams: Partial<ParamsErrorTickets> = {
    page,
    ticket_type,
    ticket_status,
    search,
    ordering,
    assignee
  };

  const query = useQuery<ErrorTicketList, ErrorResponse>({
    queryKey: [
      ErrorTicketsQueryKeys.ERROR_TICKET_LIST,
      page,
      ticket_type,
      ticket_status,
      start_date,
      end_date,
      search,
      assignee,
      ticket_tag,
      provider,
      ordering,
      open_api_partner_id
    ],
    queryFn: () => {
      const getErrorTicketListWithParams = (params: Partial<ParamsErrorTickets>) => {
        return getErrorTicketList({ ...defaultParams, ...params });
      };

      if (start_date && end_date) {
        return getErrorTicketListWithParams({ start_date, end_date, ticket_tag });
      }
      if (open_api_partner_id) {
        return getErrorTicketListWithParams({ open_api_partner_id });
      }
      if (isNumber(provider)) {
        return getErrorTicketListWithParams({ provider });
      }

      return getErrorTicketListWithParams({ ticket_tag });
    },
    retry: !!start_date && !!end_date,
    placeholderData: keepPreviousData
  });

  if (query.isError) addNotification('fail', 'Failed to fetch error ticket list');

  const errorTicketList = useMemo(() => {
    return query.data;
  }, [query.data]);

  useEffect(() => {
    query.refetch();
  }, [ordering]);

  return { errorTicketList, query };
};
