import { getMultiPropertyFilters } from '@common/api/dashboard';
import { useNotificationsStore } from '@common/store/notifications';
import { PropertyQueryKeys } from '@common/types/query-keys';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { useGetAccountHotel } from '@pages/Client/hooks/useGetAccountHotel';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useQuery } from '@tanstack/react-query';
import { isEmpty, map } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useMultiPropertyFilters = () => {
  const { t } = useTranslation();
  const { data: hotelAccountData } = useGetAccountHotel();
  const { addNotification } = useNotificationsStore();
  const { propertyIds } = useDashboardPageStore();
  const { hotelDetails } = useHotelDetails();

  const query = useQuery({
    queryKey: [PropertyQueryKeys.GET_MULTI_PROPERTY_FILTERS],
    queryFn: () => getMultiPropertyFilters()
  });
  if (query.isError) addNotification('fail', t('Failed to fetch multiproperty filters'));
  const dashboardHotelsMap = useMemo(() => {
    return query.data?.data?.hotels;
  }, [query]);

  const shouldHighlightPropertyFilter = () => {
    if (!hotelDetails?.id) return;
    return propertyIds[hotelDetails.id]?.some((id) => id !== hotelDetails.id);
  };

  return {
    query,
    propertyIds,
    shouldHighlightPropertyFilter,
    isMultipleHotels: !isEmpty(dashboardHotelsMap),
    dashboardHotels: useMemo(() => {
      const mappedHotels = map(dashboardHotelsMap || {}, (value, key) => ({
        id: parseInt(key),
        name: value
      }));
      if (
        hotelAccountData?.hotel &&
        !mappedHotels.some((hotel) => hotel.id === hotelAccountData?.hotel?.id)
      ) {
        // Add the current hotel if it does not exist
        mappedHotels.unshift({
          id: hotelAccountData?.hotel?.id,
          name: hotelAccountData?.hotel?.name
        });
      }
      return mappedHotels;
    }, [dashboardHotelsMap, hotelAccountData?.hotel])
  };
};
