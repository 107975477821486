import { Button } from '@common/components/atoms/Button';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useUserRole } from '@common/hooks/useUserRole';
import { useFeaturesStore } from '@common/store/features';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDocumentTitle } from '@mantine/hooks';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { identity, mapValues, pickBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import * as z from 'zod';

const WEIGHT = ['0', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const schema = z.object({
  competitor_model_weight: z.number().or(z.string()).optional(),
  pubp_model_weight: z.number().or(z.string()).optional(),
  airDNA_model_weight: z.number().or(z.string()).optional()
});

const defaultValues = {
  competitor_model_weight: 10,
  pubp_model_weight: '0',
  airDNA_model_weight: '0'
};

export const MarketIntelligence = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Competitor Set'));
  const { features } = useFeaturesStore();
  const { isPartner } = useUserRole();
  const { pricingSettingsQuery } = useRoomPrices();
  const { hotelDetails } = useHotelDetails();
  const allowedPartner = [7, 9].includes(hotelDetails?.reseller?.id as number);
  const isAllowedPartner = isPartner && allowedPartner;
  const { data: pricingSettings } = pricingSettingsQuery;
  const {
    isLoading: isSavePricingLoading,
    savePricingSettings,
    isSuccess: isSaveSuccess
  } = useUpdatePricingSettings();
  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });

  const { control, handleSubmit } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: pricingSettings?.model_weights
      ? {
          competitor_model_weight:
            pricingSettings?.model_weights?.competitor_model_weight === 0
              ? pricingSettings?.model_weights?.competitor_model_weight.toString()
              : pricingSettings?.model_weights?.competitor_model_weight,
          pubp_model_weight:
            pricingSettings?.model_weights?.pubp_model_weight === 0
              ? pricingSettings?.model_weights?.pubp_model_weight.toString()
              : pricingSettings?.model_weights?.pubp_model_weight,
          airDNA_model_weight:
            pricingSettings?.model_weights?.airDNA_model_weight === 0
              ? pricingSettings?.model_weights?.airDNA_model_weight.toString()
              : pricingSettings?.model_weights?.airDNA_model_weight
        }
      : {
          ...defaultValues
        }
  });

  const subMenuLink = [
    {
      id: 'competitor-list',
      label: t('Competitor List'),
      to: 'competitor-list'
    },
    {
      id: 'analytics',
      label: t('Analytics'),
      to: 'analytics'
    },
    {
      id: 'map',
      label: t('Map'),
      to: 'map'
    },
    {
      id: 'pickup-boost-price',
      label: t('Pick-Up Boost Price'),
      to: 'pickup-boost-price'
    },
    {
      id: 'airbnb',
      label: t('Airbnb'),
      to: 'airbnb'
    }
  ];

  const WEIGHT_COMPETITOR_OPTION = WEIGHT.map((item) => ({
    label: item.toString(),
    value: item
  }));

  const onSubmit = (data: z.infer<typeof schema>) => {
    const modelWeights = pickBy(data, identity);
    const settings = {
      ...pricingSettings,
      model_weights: mapValues(modelWeights, (value) => parseInt(value?.toString()))
    };
    savePricingSettings(JSON.stringify(settings));
  };

  useEffect(() => {
    setIsSuccessRef({ current: isSaveSuccess });
    if (isSaveSuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isSaveSuccess]);

  const isPickupBoostPriceFeatureEnable = features?.includes(22);
  const isAirbnbFeatureEnable = features?.includes(26);

  const MarketIntelligenceActions = () => {
    return (
      <div className="grid grid-cols-3 items-end gap-3 ">
        {isPartner ? null : (
          <>
            <div>
              <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                {t('Competitor Model Weight')}
              </Typography>
              <Controller
                control={control}
                name="competitor_model_weight"
                render={({ field: { value, name, onChange } }) => (
                  <SelectDropdown
                    fullWidth
                    name={name}
                    key={name}
                    value={value}
                    placeholder="Select Weight"
                    options={WEIGHT_COMPETITOR_OPTION}
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
              />
            </div>
            {isPickupBoostPriceFeatureEnable ? (
              <div>
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Pick-Up Model Weight')}
                </Typography>
                <Controller
                  control={control}
                  name="pubp_model_weight"
                  render={({ field: { value, name, onChange } }) => (
                    <SelectDropdown
                      fullWidth
                      name={name}
                      key={name}
                      value={value}
                      placeholder="Select Weight"
                      options={WEIGHT_COMPETITOR_OPTION}
                      onChange={(value) => {
                        onChange(value);
                      }}
                    />
                  )}
                />
              </div>
            ) : null}

            {isAirbnbFeatureEnable ? (
              <div>
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Airbnb Model Weight')}
                </Typography>
                <Controller
                  control={control}
                  name="airDNA_model_weight"
                  render={({ field: { value, name, onChange } }) => (
                    <SelectDropdown
                      fullWidth
                      name={name}
                      key={name}
                      value={value}
                      placeholder="Select Weight"
                      options={WEIGHT_COMPETITOR_OPTION}
                      onChange={(value) => {
                        onChange(value);
                      }}
                    />
                  )}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  };

  const defaultTabs = (
    isAirbnbFeatureEnable ? subMenuLink : subMenuLink.filter((item) => item.id !== 'airbnb')
  ).filter((item) => (isPickupBoostPriceFeatureEnable ? true : item.id !== 'pickup-boost-price'));

  const partnerUserTabs = subMenuLink.filter((item) => item.id === 'competitor-list');
  const shsPartnerTabs = subMenuLink.filter(
    (item) => item.id === 'competitor-list' || item.id === 'map'
  );

  const tabs =
    isPartner && !allowedPartner
      ? partnerUserTabs
      : isAllowedPartner
      ? shsPartnerTabs
      : defaultTabs;

  return (
    <Page
      header={
        <Header
          title="Market Intelligence"
          tabs={tabs}
          additionalInfoDescription={<MarketIntelligenceActions />}
          actions={
            isPartner ? null : (
              <Button
                disabled={isSavePricingLoading}
                isSuccess={isSuccessRef.current}
                isLoading={isSavePricingLoading}
                intent="primary"
                onClick={handleSubmit(onSubmit)}>
                {t('Save Model Weights')}
              </Button>
            )
          }
        />
      }>
      <Outlet />
    </Page>
  );
};
