import { ErrorResponse } from '@common/api/auth';
import { getPickup } from '@common/api/dashboard';
import { Pickup, NumberOfBookings, PickupData } from '@common/api/dashboard/types';
import { useNotificationsStore } from '@common/store/notifications';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const usePickup = (supportMultipleHotels = false) => {
  const { hotelDetails } = useHotelDetails();
  const { propertyIds } = useDashboardPageStore();
  const { addNotification } = useNotificationsStore();
  const { t } = useTranslation();

  const query = useQuery<Pickup, ErrorResponse>({
    queryKey: [UtilQueryKeys.GET_PICKUP, propertyIds, supportMultipleHotels],
    queryFn: () =>
      hotelDetails
        ? getPickup({
            ...(supportMultipleHotels && !isEmpty(propertyIds?.[hotelDetails.id])
              ? {
                  filter_hotels: propertyIds?.[hotelDetails.id]
                }
              : {})
          })
        : Promise.reject('No hotel ID'),
    enabled: !!hotelDetails?.id
  });

  if (query.isError) addNotification('fail', t('Failed to fetch pickup data'));

  const pickup = useMemo(() => {
    const getPickup = (
      view: 'monthly' | 'yearly',
      type: 'stly' | 'total',
      pickupTime: keyof NumberOfBookings<string>,
      date?: string
    ) => {
      const lookup = {
        monthly_stly: query.data?.pickup_monthly_stly,
        monthly_total: query.data?.pickup_monthly_total,
        yearly_stly: query.data?.pickup_yearly_stly,
        yearly_total: query.data?.pickup_yearly_total
      };

      const data = lookup[`${view}_${type}`];

      if (!data) return;

      const filteredData: Partial<Record<keyof PickupData<string>, number>> = (
        Object.keys(data) as Array<keyof PickupData<string>>
      ).reduce(
        (
          result: Partial<Record<keyof PickupData<string>, number>>,
          key: keyof PickupData<string>
        ) => {
          const value = data![key][pickupTime];

          if (typeof value === 'object') {
            result[key] = value[date!] ?? 0;
          } else if (typeof value === 'number') {
            result[key] = value;
          } else {
            result[key] = 0;
          }
          return result;
        },
        {}
      );

      return filteredData;
    };

    return { data: query.data, getPickup };
  }, [query.data]);

  return { pickup, query };
};
