import { useAuthStore } from '@common/store/auth';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Paper,
  Title,
  Text,
  Space,
  Group,
  Skeleton,
  Stack,
  Grid,
  TextInput,
  ActionIcon,
  ThemeIcon,
  SegmentedControl,
  Flex,
  Button,
  Box,
  SemiCircleProgress,
  Badge,
  Card
} from '@mantine/core';
import { usePerformanceDashboardReservationKPI } from '@pages/Client/Dashboard/hooks/usePerformanceDashboardReservationKPI';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Sparkline } from '@mantine/charts';
import { KPIData } from '@common/api/dashboard/types';
import { LastYearFilter } from '@pages/Client/Dashboard/store/dashboard';
import { isLeapYear } from 'date-fns';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelEventsByStartDateAndEndDate } from '@pages/Client/Calendar/hooks/useHotelEvents';
import { API_DATE_FORMAT, DATE_FORMAT_WITH_DAY } from '@common/constants/date';
import { EventCard } from '@pages/Client/Home/components/event-card';
import { Icon } from '@common/components/foundations/icons';
import { IconChartAreaFilled, IconChartBar, IconHeartbeat } from '@tabler/icons-react';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Feature, useFeaturesStore } from '@common/store/features';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { inRange, isEmpty, map, replace, size, take } from 'lodash-es';
import { localeDateFormat } from '@pages/Client/Calendar/utils/localeDateFormat';
import { formatDateWithoutTime } from '@common/utils/formatDateWithoutTime';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { Link, useNavigate } from 'react-router-dom';
import {
  BulkEditTab,
  useBulkEditsStore
} from '@pages/Client/Calendar/components/BulkEdit/store/editAdjustmentsStore';
import { useEffect, useState } from 'react';
import { calculatePercentageIncreaseWithMessage } from '@pages/Client/Dashboard/utils/diff';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { BarChart, Bar, ResponsiveContainer, XAxis, ReferenceLine } from 'recharts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flags } from '@common/constants/flags';
import { useDate } from '@common/hooks/useDate';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';

const EVENT_COUNT_WHILE_LOADING = 5;
const SURGE_EVENTS_DISPLAY_CUT_OFF = 5;
const DEFAULT_NUMBER_OF_DAYS = '14';

export const HomePage = () => {
  const { t } = useTranslation();
  const flags = useFlags();
  const navigate = useNavigate();
  const { hotelDetails } = useHotelDetails();

  useEffect(() => {
    if (hotelDetails && !flags[Flags.HomeScreen]) navigate(`/client/calendar/${hotelDetails.id}`);
  }, [flags, hotelDetails]);

  const [numberOfDays, setNumberOfDays] = useState(DEFAULT_NUMBER_OF_DAYS);
  const [startDate] = useState(dayjs().startOf('day').toDate());
  const [endDate, setEndDate] = useState(
    dayjs().add(Number(DEFAULT_NUMBER_OF_DAYS), 'days').endOf('day').toDate()
  );
  const { user } = useAuthStore();
  const {
    performanceDashboardReservationKPI,
    query: { isLoading }
  } = usePerformanceDashboardReservationKPI();
  const {
    roomPrices,
    referenceRoom,
    cachePriceQuery: { isLoading: isCachePriceLoading }
  } = useRoomPrices();
  const {
    events,
    query: { isFetching: isFetchingHotelEventsForMonth },
    sumOfAllEvents
  } = useHotelEventsByStartDateAndEndDate({
    startDate: dayjs(startDate).toDate(),
    endDate: dayjs(endDate).toDate()
  });
  const { setDrawerState, setViewingDate } = usePriceDrawerStore();

  const { hasDashboardFeature, features } = useFeaturesStore();
  const { hasDashboardEnabled } = usePmsProvider();
  const { surgeDays, surgeEvents } = useSurgeEvents();
  const { setBulkEditTab, setBulkEditDrawerState } = useBulkEditsStore();

  const isSurgeEvents =
    surgeEvents?.results.some((surgeEvent) => surgeEvent.active) &&
    features?.includes(Feature.SurgeProtection);

  const onBulkSurgeEdit = () => {
    navigate(`/client/calendar/${hotelDetails?.id}/pricing`);
    setBulkEditTab(BulkEditTab.SURGES);
    setBulkEditDrawerState(true);
  };

  const eventsByDay = Object.fromEntries(
    Object.entries(events ?? {}).filter(([date]) =>
      dayjs(date).startOf('day').isSameOrAfter(dayjs().startOf('day').tz(hotelDetails?.timezone))
    )
  );

  const currentMonth = dayjs().format('MMMM');

  const getDailyKPIValues = (kpi: keyof KPIData<string>, type: LastYearFilter = 'DTD') => {
    const currentMonth = dayjs().tz().format('YYYY-MM');
    const lastYear = dayjs().tz().subtract(1, 'year').format('YYYY-MM');
    function getAllDaysOfMonth(month: string) {
      const startDate = dayjs(month).startOf('month');
      const endDate = dayjs(month).endOf('month');
      const days = [];

      let currentDate = startDate;
      while (currentDate.isSame(endDate) || currentDate.isBefore(endDate)) {
        days.push(currentDate.format(API_DATE_FORMAT));
        currentDate = currentDate.add(1, 'day');
      }

      return days;
    }

    function getKPIData(dates: string[], kpiType: 'stly' | 'total', kpi: keyof KPIData<string>) {
      return dates.map((day) => {
        // Take next day to apply "DOW: Day of the Week" comparison
        let addDays = 1;

        if (isLeapYear(dayjs(day).add(1, 'year').year()) && dayjs(day).month() > 1) {
          // For leap years later than February, adjust the day addition to 2 days
          addDays = 2;
        }
        const valueDate =
          type === 'DOW' ? dayjs(day).add(addDays, 'day').format(API_DATE_FORMAT) : day;

        return {
          date: day,
          value: performanceDashboardReservationKPI.getKPITableDailyByMonth(kpiType, kpi, valueDate)
        };
      });
    }

    const total = getKPIData(getAllDaysOfMonth(currentMonth), 'total', kpi);
    const stly = getKPIData(getAllDaysOfMonth(lastYear), 'stly', kpi);
    const tly = getKPIData(getAllDaysOfMonth(lastYear), 'total', kpi);

    return { total, stly, tly };
  };

  const currentMonthYear = dayjs().format('YYYY-MM');
  const lastMonthYear = dayjs().subtract(1, 'year').format('YYYY-MM');

  const revenue = Math.round(
    performanceDashboardReservationKPI.getKPITable('monthly', 'total', currentMonthYear)?.REVENUE ??
      0
  );

  const revenueStly = Math.round(
    performanceDashboardReservationKPI.getKPITable('monthly', 'stly', lastMonthYear)?.REVENUE ?? 0
  );

  const occupancy =
    performanceDashboardReservationKPI.getKPITable('monthly', 'total', currentMonthYear)
      ?.OCCUPANCY ?? 0;

  const occupancyStly =
    performanceDashboardReservationKPI.getKPITable('monthly', 'stly', lastMonthYear)?.OCCUPANCY ??
    0;

  const adr = Math.round(
    performanceDashboardReservationKPI.getKPITable('monthly', 'total', currentMonthYear)?.ADR ?? 0
  );

  const adrStly = Math.round(
    performanceDashboardReservationKPI.getKPITable('monthly', 'stly', lastMonthYear)?.ADR ?? 0
  );

  const revPar = Math.round(
    performanceDashboardReservationKPI.getKPITable('monthly', 'total', currentMonthYear)?.REV_PAR ??
      0
  );

  const revParStly = Math.round(
    performanceDashboardReservationKPI.getKPITable('monthly', 'stly', lastMonthYear)?.REV_PAR ?? 0
  );

  // an array of 30 days from today
  const days = Array.from({ length: 30 }, (_, i) => dayjs().add(i, 'day').format(API_DATE_FORMAT));

  const roomsSoldNext30Days = days.map((day) => {
    const totalRooms = roomPrices?.prices.data?.[day]?.property?.number_of_rooms ?? 0;
    const inventoryRemaining = roomPrices?.prices.data?.[day]?.property?.inventory_remaining ?? 0;
    return Math.max(0, totalRooms - inventoryRemaining);
  });

  const pickupNext30Days = days.map(
    (day) => roomPrices?.pickup_boost_prices?.[day]?.arguments?.roomsleft_change_vs_yesterday ?? 0
  );

  const positivePickupNext30Days = pickupNext30Days.map((pickup) => {
    if (pickup > 0) {
      return pickup;
    }
    return null;
  });
  const negativePickupNext30Days = pickupNext30Days.map((pickup) => {
    if (pickup < 0) {
      return pickup;
    }
    return null;
  });

  const occupancyNext30Days = days.map((_day, index) =>
    Math.min(roomsSoldNext30Days[index], roomsSoldNext30Days[index] - pickupNext30Days[index])
  );

  const capacityNext30Days = days
    .map((day) => roomPrices?.prices.data?.[day]?.property?.number_of_rooms ?? 0)
    .reduce((acc, curr) => acc + curr, 0);

  const sumRoomsLeftNext30Days = days
    .map((day) => roomPrices?.prices.data?.[day]?.property?.inventory_remaining ?? 0)
    .reduce((acc, curr) => acc + curr, 0);

  const sumRoomsSoldNext30Days = capacityNext30Days - sumRoomsLeftNext30Days;
  const sumPickupNext30Days = pickupNext30Days.reduce((acc, curr) => acc + curr, 0);

  const sumPercentagePickupNext30Days = (sumPickupNext30Days / sumRoomsSoldNext30Days) * 100;

  const avgOccupancyNext30Days = Math.round((sumRoomsSoldNext30Days / capacityNext30Days) * 100);

  const expectedOccupancyAlignment = () => {
    const sixMonthDays = Array.from({ length: 180 }, (_, i) =>
      dayjs().add(i, 'day').format(API_DATE_FORMAT)
    );
    const roomSoldData = sixMonthDays.map((day) =>
      Math.max(
        0,
        (roomPrices?.prices.data?.[day]?.property?.number_of_rooms ?? 0) -
          (roomPrices?.prices.data?.[day]?.property?.inventory_remaining ?? 0)
      )
    );

    const alignedDays = roomSoldData.filter((roomSold, index) => {
      const propertyData = roomPrices?.prices.data?.[sixMonthDays[index]]?.property;
      return inRange(
        roomSold,
        propertyData?.exproomssold_l2 ?? 0,
        propertyData?.exproomssold_u2 ?? 0
      );
    });

    const alignmentPercentage = (alignedDays.length / sixMonthDays.length) * 100;
    return Math.round(alignmentPercentage);
  };

  const data = days.map((day, index) => ({
    date: day,
    positivePickup: positivePickupNext30Days[index],
    negativePickup: negativePickupNext30Days[index],
    occupancy: occupancyNext30Days[index]
  }));

  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '12px';
  const fontWeight = 400;

  const CustomTick: React.FC<any> = ({ x, y, payload, index }) => {
    const { isToday, isWeekend } = useDate();
    const date = new Date(payload.value);

    if (index === 0 || index === data.length - 1) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={10}
            y={10}
            dy={0}
            textAnchor="end"
            fill={isToday(date) ? 'var(--mantine-color-dark-9)' : 'var(--mantine-color-gray-6)'}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={isToday(date) ? 'bold' : fontWeight}
            textDecoration={isWeekend(date) ? 'underline' : 'none'}
            transform="rotate(-45 0 4)">
            {dayjs(payload.value).format('MMM DD')}
          </text>
        </g>
      );
    }

    return null;
  };

  const handlePriceDrawerOpen = (date: string) => {
    setViewingDate(dayjs(date).toDate());
    setDrawerState(true);
  };

  return (
    <Page header={<Header title={t('Welcome Back, {{name}}!', { name: user?.first_name })} />}>
      <Grid columns={8}>
        <Grid.Col span={2}>
          <Card shadow="xs" p="md" h="100%">
            <Group gap="xs">
              <ThemeIcon variant="transparent" c="dimmed">
                <IconHeartbeat />
              </ThemeIcon>
              <Title order={4} c="dark">
                {t('Property Health')}
              </Title>
            </Group>
            <Stack align="center" justify="center" h="100%">
              <SemiCircleProgress
                transitionDuration={500}
                fillDirection="left-to-right"
                orientation="up"
                filledSegmentColor={expectedOccupancyAlignment() >= 50 ? 'indigo' : 'indigo.2'}
                thickness={20}
                value={expectedOccupancyAlignment()}
                label={
                  <Stack gap="1">
                    <Text size="sm" c="dimmed">
                      {expectedOccupancyAlignment()}%
                    </Text>
                    <Text size="xl" c="dark" ff="heading" fw={500}>
                      {expectedOccupancyAlignment() >= 50 ? t('Good') : t('Fair')}
                    </Text>
                  </Stack>
                }
              />
              <Group gap="xs">
                <Text size="sm">{t('Expected Occupancy Alignment')}</Text>
                <TooltipIcon
                  content={t(
                    'Expected Occupancy Alignment is the percentage of days in a 180 day period where the occupancy is within the expected range of rooms sold.'
                  )}
                />
              </Group>
            </Stack>
            <Group justify="flex-end">
              <Button
                component={Link}
                to={`/client/calendar/${hotelDetails?.id}/occupancy`}
                variant="subtle"
                size="xs"
                rightSection={<Icon.ArrowLongRight />}>
                {t('Go to Occupancy Calendar')}
              </Button>
            </Group>
          </Card>
        </Grid.Col>
        {hasDashboardFeature() && hasDashboardEnabled() ? (
          <Grid.Col span={2}>
            <Card shadow="xs" h="100%">
              <Group gap="xs">
                <ThemeIcon variant="transparent" c="dimmed">
                  <IconChartAreaFilled />
                </ThemeIcon>
                <Title order={4}>
                  {currentMonth} {t('KPIs')}
                </Title>
              </Group>
              <Space h="md" />
              <Group justify="space-between">
                <Text size="sm">{t('Revenue')}</Text>
                <Skeleton visible={isLoading} w="fit-content" miw="50px">
                  <Group gap="0">
                    <Text size="md" ff="heading" ta="right" c="indigo">
                      {CurrencyFormatter.format(revenue)}
                    </Text>
                    <Badge
                      size="xs"
                      radius="xl"
                      variant="transparent"
                      c={
                        calculatePercentageIncreaseWithMessage(revenue, revenueStly).isNegative
                          ? 'red.9'
                          : 'green.9'
                      }
                      leftSection={
                        calculatePercentageIncreaseWithMessage(revenue, revenueStly).isNegative ? (
                          <Icon.TrendingDown className="h-3 w-3 fill-uiRed" />
                        ) : (
                          <Icon.TrendingUp className="h-3 w-3 fill-uiGreen" />
                        )
                      }>
                      {calculatePercentageIncreaseWithMessage(revenue, revenueStly).content}
                    </Badge>
                  </Group>
                </Skeleton>
              </Group>
              <Card.Section>
                <Sparkline
                  withGradient
                  w="100%"
                  h={50}
                  data={getDailyKPIValues('REVENUE').total.map((item) => item.value)}
                  color="#5B48EE"
                  curveType="linear"
                  strokeWidth={1.5}
                  fillOpacity={0.2}
                  areaProps={{
                    fillOpacity: 0.6,
                    strokeOpacity: 0.5
                  }}
                />
              </Card.Section>
              <Stack gap="4" mt="lg" justify="flex-end" h="100%">
                <Group justify="space-between">
                  <Text size="sm">{t('Occupancy')}</Text>
                  <Skeleton visible={isLoading} w="fit-content" miw="50px">
                    <Group gap="0">
                      <Text ff="heading" ta="right" c="indigo">
                        {formattedPercentage(occupancy)}
                      </Text>
                      <Badge
                        size="xs"
                        radius="xl"
                        variant="transparent"
                        c={
                          calculatePercentageIncreaseWithMessage(occupancy, occupancyStly)
                            .isNegative
                            ? 'red.9'
                            : 'green.9'
                        }
                        leftSection={
                          calculatePercentageIncreaseWithMessage(occupancy, occupancyStly)
                            .isNegative ? (
                            <Icon.TrendingDown className="h-3 w-3 fill-uiRed" />
                          ) : (
                            <Icon.TrendingUp className="h-3 w-3 fill-uiGreen" />
                          )
                        }>
                        {formattedPercentage(occupancy - occupancyStly)}
                      </Badge>
                    </Group>
                  </Skeleton>
                </Group>
                <Group justify="space-between">
                  <Text size="sm">{t('ADR')}</Text>
                  <Skeleton visible={isLoading} w="fit-content" miw="50px">
                    <Group gap="0">
                      <Text ff="heading" ta="right" c="indigo">
                        {CurrencyFormatter.format(adr)}
                      </Text>
                      <Badge
                        size="xs"
                        radius="xl"
                        variant="transparent"
                        c={
                          calculatePercentageIncreaseWithMessage(adr, adrStly).isNegative
                            ? 'red.9'
                            : 'green.9'
                        }
                        leftSection={
                          calculatePercentageIncreaseWithMessage(adr, adrStly).isNegative ? (
                            <Icon.TrendingDown className="h-3 w-3 fill-uiRed" />
                          ) : (
                            <Icon.TrendingUp className="h-3 w-3 fill-uiGreen" />
                          )
                        }>
                        {calculatePercentageIncreaseWithMessage(adr, adrStly).content}
                      </Badge>
                    </Group>
                  </Skeleton>
                </Group>
                <Group justify="space-between">
                  <Text size="sm">{t('RevPAR')}</Text>
                  <Skeleton visible={isLoading} w="fit-content" miw="50px">
                    <Group gap="0">
                      <Text ff="heading" ta="right" c="indigo">
                        {CurrencyFormatter.format(revPar)}
                      </Text>
                      <Badge
                        size="xs"
                        radius="xl"
                        variant="transparent"
                        c={
                          calculatePercentageIncreaseWithMessage(revPar, revParStly).isNegative
                            ? 'red.9'
                            : 'green.9'
                        }
                        leftSection={
                          calculatePercentageIncreaseWithMessage(revPar, revParStly).isNegative ? (
                            <Icon.TrendingDown className="h-3 w-3 fill-uiRed" />
                          ) : (
                            <Icon.TrendingUp className="h-3 w-3 fill-uiGreen" />
                          )
                        }>
                        {calculatePercentageIncreaseWithMessage(revPar, revParStly).content}
                      </Badge>
                    </Group>
                  </Skeleton>
                </Group>
              </Stack>
              <Group justify="flex-end" mt="xl">
                <Button
                  component={Link}
                  to={`/client/dashboard/${hotelDetails?.id}`}
                  variant="subtle"
                  size="xs"
                  rightSection={<Icon.ArrowLongRight />}>
                  {t('Go to Reporting')}
                </Button>
              </Group>
            </Card>
          </Grid.Col>
        ) : null}
        <Grid.Col span={hasDashboardFeature() && hasDashboardEnabled() ? 4 : 6}>
          <Card shadow="xs" p="md">
            <Group gap="xs">
              <ThemeIcon variant="transparent" c="dimmed">
                <IconChartBar />
              </ThemeIcon>
              <Title order={4}>{t('Occupancy & Pickup for the next 30 days')}</Title>
            </Group>
            <ResponsiveContainer width="100%" height={150}>
              <BarChart
                data={data}
                margin={{ top: 20, right: 10, left: 10, bottom: 0 }}
                barGap={-16}
                barSize={16}>
                <XAxis
                  tickMargin={-10}
                  axisLine={false}
                  height={30}
                  tickLine={false}
                  fontFamily={fontFamily}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                  dataKey="date"
                  tick={<CustomTick />}
                  interval={0}
                />
                <ReferenceLine y={0} stroke="var(--mantine-color-gray-9)" strokeDasharray="2" />
                <Bar
                  radius={4}
                  dataKey="occupancy"
                  fill="var(--mantine-color-indigo-2)"
                  stackId="a"
                  name="Occupancy"
                  onClick={(props) => handlePriceDrawerOpen(props.date)}
                />
                <Bar
                  radius={4}
                  dataKey="positivePickup"
                  fill="var(--mantine-color-indigo-4)"
                  stackId="a"
                  name="Positive Pickup"
                  onClick={(props) => handlePriceDrawerOpen(props.date)}
                />
                <Bar
                  radius={4}
                  dataKey="negativePickup"
                  fill="var(--mantine-color-indigo-4)"
                  stackId="b"
                  name="Negative Pickup"
                  onClick={(props) => handlePriceDrawerOpen(props.date)}
                />
              </BarChart>
            </ResponsiveContainer>
            <Stack gap="1" mt="md">
              <Group justify="space-between">
                <Text size="sm">{t('Average Occupancy')}</Text>
                <Skeleton visible={isLoading} w="fit-content" miw="50px">
                  <Text ff="heading" ta="right" c="indigo">
                    {avgOccupancyNext30Days}%
                  </Text>
                </Skeleton>
              </Group>
              <Group justify="space-between">
                <Text size="sm">{t('Pick-Up Since Yesterday')}</Text>
                <Skeleton visible={isLoading} w="fit-content" miw="50px">
                  <Text ff="heading" ta="right" c="indigo">
                    {sumRoomsSoldNext30Days} {t('Rooms Sold')} (
                    {formattedPercentage(sumPercentagePickupNext30Days)})
                  </Text>
                </Skeleton>
              </Group>
            </Stack>
            <Group justify="flex-end" mt="xl">
              <Button
                component={Link}
                to={`/client/calendar/${hotelDetails?.id}/pickup`}
                variant="subtle"
                size="xs"
                rightSection={<Icon.ArrowLongRight />}>
                {t('Go to Pickup Calendar')}
              </Button>
            </Group>
          </Card>
        </Grid.Col>
        {surgeEvents && isSurgeEvents && !isCachePriceLoading ? (
          <Grid.Col span={8}>
            <Paper shadow="xs" p="md" className="border-darkGreen" withBorder>
              <div className="flex items-start justify-between gap-2 rounded-md bg-white">
                <div className="flex items-center rounded-full bg-darkGreen bg-opacity-10 p-3">
                  <Icon.Ripple className="h-6 w-6 text-darkGreen" aria-hidden="true" />
                </div>
                <div className="w-full">
                  <Text size="xs" fw={500} className="text-darkGreen">
                    {t('Surge Events & Alerts')}
                  </Text>
                  <Text size="sm">
                    {surgeDays.some((surgeDay) => surgeDay.hasSurgePrices)
                      ? t(
                          'Temporary high protection prices have been set for the highlighted dates. Click on the date to fix the price:'
                        )
                      : surgeDays.every((surgeDay) => surgeDay.hasSurgePrices)
                      ? t(
                          'Temporary high protection prices have been set for the following dates. Click on the date to fix the price:'
                        )
                      : t(
                          'More than expected bookings detected. Click on the date to check your prices:'
                        )}
                  </Text>
                  <Flex wrap="wrap" mt="xs" w="100%" align="center" rowGap="sm" columnGap="xs">
                    {!isEmpty(surgeDays) ? (
                      <>
                        {map(take(surgeDays, SURGE_EVENTS_DISPLAY_CUT_OFF), (surgeDay) => (
                          <Box key={`surgeDay-button-${surgeDay.date}`}>
                            <Button
                              variant="outline"
                              fw={surgeDay.hasSurgePrices ? 'bold' : 'normal'}
                              td={surgeDay.hasSurgePrices ? 'underline' : 'none'}
                              onClick={() => handlePriceDrawerOpen(surgeDay.date)}>
                              {hotelDetails?.prefered_date_format
                                ? `${dayjs(surgeDay.date).format('ddd')}, `
                                : null}
                              {localeDateFormat(
                                dayjs(surgeDay.date).toDate(),
                                dayjs(surgeDay.date).format(
                                  formatDateWithoutTime(
                                    hotelDetails?.prefered_date_format ?? DATE_FORMAT_WITH_DAY
                                  )
                                )
                              )}
                            </Button>
                          </Box>
                        ))}
                        {size(surgeDays) > SURGE_EVENTS_DISPLAY_CUT_OFF ? (
                          <Button
                            variant="subtle"
                            component={Link}
                            to={replace(
                              '/client/surge-event/:id/logs',
                              ':id',
                              String(hotelDetails?.id)
                            )}>
                            {t(`+${size(surgeDays) - SURGE_EVENTS_DISPLAY_CUT_OFF} ${t('more')}`)}
                          </Button>
                        ) : null}
                      </>
                    ) : null}
                  </Flex>
                </div>

                <Flex gap="sm" align="center">
                  {size(surgeDays) > SURGE_EVENTS_DISPLAY_CUT_OFF ? (
                    <>
                      <Button
                        variant="outline"
                        loading={isCachePriceLoading}
                        disabled={isCachePriceLoading}
                        onClick={onBulkSurgeEdit}>
                        {t('Bulk Dismiss')}
                      </Button>
                    </>
                  ) : null}
                </Flex>
              </div>
            </Paper>
          </Grid.Col>
        ) : null}
        <Grid.Col span={8}>
          <Paper shadow="xs" p="md">
            <Group justify="space-between">
              <Group gap="xl">
                <Group gap="xs">
                  <ThemeIcon variant="transparent" c="dimmed">
                    <Icon.Calendar />
                  </ThemeIcon>
                  <Title order={4}>{t('Events ({{count}})', { count: sumOfAllEvents })}</Title>
                </Group>
                <SegmentedControl
                  data={[
                    { label: 'Next Up', value: '14' },
                    { label: 'Top Events', value: '5' }
                  ]}
                  defaultValue={DEFAULT_NUMBER_OF_DAYS}
                  value={numberOfDays}
                  onChange={(value) => {
                    setNumberOfDays(value);
                    setEndDate(dayjs().add(Number(value), 'day').toDate());
                  }}
                />
              </Group>
              <Group gap="xs">
                <TextInput placeholder="Search" variant="filled" />
                <ActionIcon>
                  <Icon.FilterList className="h-5 w-5" />
                </ActionIcon>
                <ActionIcon variant="filled" color="indigo" c="white">
                  <Icon.Add className="h-5 w-5" />
                </ActionIcon>
              </Group>
            </Group>
            <Stack gap="xs" mt="xl">
              {isFetchingHotelEventsForMonth
                ? Array.from({ length: EVENT_COUNT_WHILE_LOADING }).map((_, index) => (
                    <Skeleton key={index} height={195} />
                  ))
                : Object.entries(eventsByDay ?? {}).map(([date, events]) => (
                    <EventCard
                      key={date}
                      date={date}
                      events={events}
                      referenceRoom={referenceRoom}
                    />
                  ))}
            </Stack>
          </Paper>
        </Grid.Col>
      </Grid>
    </Page>
  );
};
