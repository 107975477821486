import React, { useEffect, useMemo } from 'react';

import dayjs from 'dayjs';

import { useHotelEvents } from '@pages/Client/Calendar/hooks/useHotelEvents';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { CalendarView } from '@pages/Client/Calendar/components/BigCalendar/CalendarView';
import { Typography } from '@common/components/foundations/Typography';
import { useHotelRoomNotes } from '@pages/Client/Calendar/hooks/useHotelRoomNotes';
import { RevenueCellContent } from './components/RevenueCellContent';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { usePerformanceDashboardReservationKPI } from '@pages/Client/Dashboard/hooks/usePerformanceDashboardReservationKPI';
import { API_DATE_FORMAT } from '@common/constants/date';
import { Icon } from '@common/components/foundations/icons';
import { useTranslation } from 'react-i18next';
import { getADRKey, getRevParKey, getRevenueKey } from '@pages/Client/Calendar/utils/lookupKeys';
import { usePerformanceFilter } from '@pages/Client/Dashboard/hooks/usePerformanceFilter';
import { NO_MARKET_DATA } from '@pages/Client/Calendar/constants';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { Dropdown } from '@common/components/molecules/Dropdown/Dropdown';
import { useDocumentTitle } from '@mantine/hooks';
import { round } from 'lodash-es';

export const Revenue = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Revenue Calendar'));
  const { setDrawerState, setViewingDate } = usePriceDrawerStore();
  const {
    liliusInstance: lilius,
    isTableView,
    setIsTableView,
    setSelectedHotelRoomId,
    initialRoomId
  } = useCalendarPageStore();
  const {
    roomPrices,
    pricingSettings,
    cachePriceQuery: { isFetching: isCachePriceLoading }
  } = useRoomPrices();
  const { getEventNameByDay } = useHotelEvents();
  const { getNoteByDay } = useHotelRoomNotes();
  const {
    performanceFilter,
    performanceOptions,
    performanceOptionsDisabled,
    onPerformanceOptionChange
  } = usePerformanceFilter();
  const { getSurgePriceForDay } = useSurgePrice();
  const { getSurgeEventByDay } = useSurgeEvents();

  useEffect(() => {
    if (isTableView) {
      setIsTableView(false);
      if (initialRoomId) {
        setSelectedHotelRoomId(initialRoomId);
      }
      return;
    }
  }, []);

  const monthKey = useMemo(() => {
    return dayjs(lilius?.viewing).format('YYYY-MM');
  }, [lilius?.viewing]);

  const {
    performanceDashboardReservationKPI: { getKPITableDailyByMonth, getKPITable }
  } = usePerformanceDashboardReservationKPI();

  const getPricingCellContent = (
    day: Date
  ): React.ComponentProps<typeof RevenueCellContent>['content'] => {
    const formattedDate = dayjs(day).startOf('day').format(API_DATE_FORMAT);

    const isClosed =
      roomPrices?.prices?.data?.[formattedDate]?.property?.closed_rooms ===
      pricingSettings?.hotel.number_of_rooms;

    const revenue =
      getKPITableDailyByMonth('total', getRevenueKey(performanceFilter), formattedDate) ?? 0;
    const adrValue =
      getKPITableDailyByMonth('total', getADRKey(performanceFilter), formattedDate) ?? 0;
    const occupancy = getKPITableDailyByMonth('total', 'OCCUPANCY', formattedDate) ?? 0;

    return {
      occupancy: formattedPercentage(occupancy),
      secondaryLabel: CurrencyFormatter.format(parseInt(String(adrValue))),
      primaryLabel: CurrencyFormatter.format(parseInt(String(revenue))),
      isClosed
    };
  };

  const getNoteAndEvent = (day: Date): string | null => {
    const formattedDate = dayjs(day).startOf('day').format(API_DATE_FORMAT);
    const noMarketData =
      roomPrices?.prices.data?.[formattedDate]?.property?.note === NO_MARKET_DATA
        ? t('No Market Data')
        : null;
    const note = getNoteByDay(day);
    const event = getEventNameByDay(day);
    const isSurgeEvent = getSurgeEventByDay(day)?.active;

    const surgeEventNote = isSurgeEvent ? t('Surge Event') : null;

    return noMarketData ?? surgeEventNote ?? note ?? event ?? null;
  };

  const getOccupancy = () => {
    return Number(getKPITable('monthly', 'total', monthKey)?.OCCUPANCY) ?? 'n.A';
  };

  const getADR = () => {
    const rawAdr = getKPITable('monthly', 'total', monthKey)?.[getADRKey(performanceFilter)];
    return rawAdr ? CurrencyFormatter.format(round(rawAdr)) : 'n.A';
  };

  const getRevenue = () => {
    const rawRevenue = getKPITable('monthly', 'total', monthKey)?.[
      getRevenueKey(performanceFilter)
    ];
    return rawRevenue ? CurrencyFormatter.format(round(rawRevenue)) : 'n.A';
  };

  const getRevPar = () => {
    const rawRevPar = getKPITable('monthly', 'total', monthKey)?.[getRevParKey(performanceFilter)];
    return rawRevPar ? CurrencyFormatter.format(round(rawRevPar)) : 'n.A';
  };

  if (!roomPrices?.prices?.data && !isCachePriceLoading)
    return (
      <div className="mx-auto max-w-md sm:max-w-3xl">
        <div className="w-full rounded-lg border-2 border-dashed border-mediumGrey p-12">
          <div className="flex flex-col items-center gap-4">
            <Icon.Calendar className="text-copyTextGrey" />
            <div className="flex flex-col items-center gap-1">
              <Typography element="span" variant="paragraph-1" color="copyTextGrey">
                {t('Revenue data not yet available')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <div className="my-2 grid gap-4 xl:grid-cols-2">
        <div className="flex h-full flex-col justify-between">
          <Typography variant="meta-2">{t('Monthly Performance')}</Typography>
          <div className="flex items-center gap-2">
            <div className="rounded-sm bg-white px-2 py-[1px] shadow-sm">
              <span className="text-meta-1 font-semibold">
                {formattedPercentage(getOccupancy())}
              </span>
              <span className="text-meta-2 text-grey">&nbsp;{t('Occupancy')}</span>
            </div>

            <div className="h-4 border border-mediumGrey"></div>

            <div className="rounded-sm bg-white px-2 py-[1px] shadow-sm">
              <span className="text-meta-1 font-semibold">{getRevenue()}</span>
              <span className="text-meta-2 text-grey">&nbsp;{t('Revenue')}</span>
            </div>

            <div className="rounded-sm bg-white px-2 py-[1px] shadow-sm">
              <span className="text-meta-1 font-semibold">{getADR()}</span>
              <span className="text-meta-2 text-grey">&nbsp;{t('ADR')}</span>
            </div>

            <div className="rounded-sm bg-white px-2 py-[1px] shadow-sm">
              <span className="text-meta-1 font-semibold">{getRevPar()}</span>
              <span className="text-meta-2 text-grey">&nbsp;{t('RevPar')}</span>
            </div>
          </div>
        </div>

        <div className="flex xl:justify-end">
          <div className="w-56">
            <Dropdown
              label={`${t('Tax Mode')}`}
              onOptionClick={(option) => onPerformanceOptionChange(option.value)}
              options={performanceOptions.map((option) => ({
                label: t(option.label) as string,
                value: option.value
              }))}
              selectedOption={performanceOptions.find(
                (option) => option.value === performanceFilter
              )}
              disableSelector={performanceOptionsDisabled}
            />
          </div>
        </div>
      </div>

      <div>
        <CalendarView
          context="revenue"
          topRightCellContent={(day) => <>{getNoteAndEvent(day)}</>}
          onCellClick={(day) => {
            setDrawerState(true);
            setViewingDate(day);
          }}
          dateCellContent={(day) => <RevenueCellContent content={getPricingCellContent(day)} />}
          pricingSettings={pricingSettings}
          selectedRoomId={pricingSettings?.rooms?.reference?.id}
          roomPrices={roomPrices}
          liliusInstance={lilius!}
          isLoading={isCachePriceLoading}
          surgeEvent={(day) => {
            const isSurgeEvent = getSurgeEventByDay(day)?.active ?? false;
            const surgePriceForDay = getSurgePriceForDay(
              dayjs(day).format(API_DATE_FORMAT),
              pricingSettings?.rooms?.reference?.id
            );
            const hasSurgePrice = surgePriceForDay?.isSurgePrice;
            return { isSurgeEvent, hasSurgePrice };
          }}
          tooltipExtraContent={(day) => [
            {
              title: t('Financials'),
              breakdownContent: [
                {
                  leftText: t('ADR'),
                  rightText: CurrencyFormatter.format(
                    getKPITableDailyByMonth(
                      'total',
                      getADRKey(performanceFilter),
                      dayjs(day).startOf('day').format(API_DATE_FORMAT)
                    ) ?? 0
                  )
                },
                {
                  leftText: t('Revenue'),
                  rightText: CurrencyFormatter.format(
                    getKPITableDailyByMonth(
                      'total',
                      getRevenueKey(performanceFilter),
                      dayjs(day).startOf('day').format(API_DATE_FORMAT)
                    ) ?? 0
                  )
                }
              ]
            }
          ]}
        />
      </div>
    </div>
  );
};
