import { Input } from '@common/components/atoms/Input';
import { Controller, useFormContext } from 'react-hook-form';
import { editSchema } from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import { z } from 'zod';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { Typography } from '@common/components/foundations/Typography';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { useHotelPricePerOccupancy } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPricePerOccupancy';
import { useEffect, useMemo } from 'react';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Icon } from '@common/components/foundations/icons';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useTranslation } from 'react-i18next';
import { cn } from '@common/utils/cn';

export const OccupancyPricingEdit: React.FC = () => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors, isSubmitting }
  } = useFormContext<z.infer<typeof editSchema>>();
  const { hotelPmsDataMap } = useHotelPmsDataMap();
  const { hotelDetails } = useHotelDetails();
  const { pmsMapping, pricingSetup } = getValues();
  const defaultOccupancy = watch('occupancyPricing.defaultOccupancy');
  const { hotelPricePerOccupancy } = useHotelPricePerOccupancy(pmsMapping.roomId);
  const { isOccupancyPercentDerivation } = useRoomSetupStore();
  const basePrice = pricingSetup?.basePrice;

  const mappedDataItem = hotelPmsDataMap?.mapped_data.find(
    (item) => item.id === pmsMapping.roomInPms
  );

  const minOccupancy = mappedDataItem?.min_occupancy;
  const maxOccupancy = mappedDataItem?.max_occupancy;

  const occupancies = useMemo(() => {
    if (minOccupancy !== undefined && maxOccupancy !== undefined) {
      return Array.from({ length: maxOccupancy - minOccupancy + 1 }, (_, i) => minOccupancy + i);
    } else {
      return [];
    }
  }, [minOccupancy, maxOccupancy, defaultOccupancy]);

  useEffect(() => {
    if (defaultOccupancy === undefined || minOccupancy === undefined || maxOccupancy === undefined)
      return;
    if (defaultOccupancy < minOccupancy || defaultOccupancy > maxOccupancy) {
      setError('occupancyPricing.defaultOccupancy', {
        type: 'manual',
        message: `Default Occupancy must be between ${minOccupancy} and ${maxOccupancy}`
      });
    } else {
      clearErrors('occupancyPricing.defaultOccupancy');
    }
  }, [defaultOccupancy, minOccupancy, maxOccupancy, setError, clearErrors]);

  useEffect(() => {
    if (!hotelPricePerOccupancy) return;

    occupancies.map((item) => {
      const hotelPricePerOccupancyItem = hotelPricePerOccupancy?.find(
        (occupancyPrice) => occupancyPrice.occupancy === item
      );

      if (!hotelPricePerOccupancyItem) {
        setValue(`occupancyPricing.occupancyDerivation.occupancy-${item}`, {
          derivation: 0,
          room: pmsMapping.roomId as number,
          occupancy: item
        });
        return;
      }

      setValue(`occupancyPricing.occupancyDerivation.occupancy-${item}`, {
        derivation: hotelPricePerOccupancyItem.derivation,
        room: hotelPricePerOccupancyItem.room,
        occupancy: hotelPricePerOccupancyItem.occupancy
      });
    });
  }, [hotelPricePerOccupancy]);

  const isReconline = hotelDetails?.pms_provider === 44;
  const isWebhotelier = hotelDetails?.pms_provider === 28;
  const isOperaCloud = hotelDetails?.pms_provider === 61;
  const isSeekom = hotelDetails?.pms_provider === 24;

  return (
    <>
      <div className="flex w-full flex-col gap-y-6 md:w-2/3">
        <Controller
          control={control}
          name="occupancyPricing.defaultOccupancy"
          rules={{
            validate: (value) => {
              if (!value) return;
              return value >= (minOccupancy || 0) && value <= (maxOccupancy || 0);
            }
          }}
          render={({ field: { value, onChange, name }, fieldState: { invalid } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting}
              type="number"
              label={`${t('Default Occupancy')}`}
              placeholder={`${t('Please enter the default occupancy')}`}
              background="grey"
              name={name}
              value={value}
              onChange={onChange}
              helperMessage={
                errors?.occupancyPricing?.defaultOccupancy && (
                  <InputHelperMessage
                    message={errors?.occupancyPricing?.defaultOccupancy?.message}
                    icon={<Icon.WarningOutline className="h-4 w-4" />}
                  />
                )
              }
              error={invalid}
            />
          )}
        />
      </div>

      <LineTable>
        <thead>
          <tr>
            <th>{t('Occupancy (Persons)')}</th>
            <th>{t('Price Difference from Default Occupancy Price')}</th>
          </tr>
        </thead>

        <tbody>
          {occupancies.map((occupancy) => {
            return (
              <tr
                key={occupancy}
                className={cn(
                  occupancy === defaultOccupancy ? 'bg-indigo bg-opacity-[0.06]' : null
                )}>
                <td align="right">{occupancy}</td>
                <td>
                  {occupancy === defaultOccupancy ? (
                    <Typography color="darkGrey">
                      {t('Base Price')}: {CurrencyFormatter.currencySymbol()}
                      {basePrice}
                    </Typography>
                  ) : (
                    <div className="w-full md:w-2/3">
                      <Controller
                        control={control}
                        name={`occupancyPricing.occupancyDerivation.occupancy-${occupancy}.derivation`}
                        render={({
                          field: { value, onChange, name },
                          fieldState: { error, invalid }
                        }) => (
                          <Input
                            disabled={isSubmitting}
                            leadingAddon={
                              (defaultOccupancy || 0) < occupancy
                                ? `${
                                    isOccupancyPercentDerivation
                                      ? '%'
                                      : CurrencyFormatter.currencySymbol()
                                  }+`
                                : `${
                                    isOccupancyPercentDerivation
                                      ? '%'
                                      : CurrencyFormatter.currencySymbol()
                                  }-`
                            }
                            background="grey"
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                              setValue(
                                `occupancyPricing.occupancyDerivation.occupancy-${occupancy}.room`,
                                pmsMapping.roomId as number
                              );
                              setValue(
                                `occupancyPricing.occupancyDerivation.occupancy-${occupancy}.occupancy`,
                                occupancy
                              );
                            }}
                            error={invalid}
                            helperMessage={error?.message}
                            showClearButton={false}
                          />
                        )}
                      />
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </LineTable>

      {isOperaCloud || isSeekom ? (
        <div className="flex w-full flex-col gap-y-6 md:w-2/3">
          <Controller
            control={control}
            name="occupancyPricing.additionalAdultPrice"
            render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
              <Input
                showClearButton={false}
                background="grey"
                leadingAddon={CurrencyFormatter.currencySymbol()}
                label={t('Additional Price Per Person') as string}
                name={name}
                value={value}
                onChange={onChange}
                error={invalid}
                helperMessage={
                  invalid ? (
                    <InputHelperMessage
                      icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                      message={error?.message}
                    />
                  ) : null
                }
              />
            )}
          />
        </div>
      ) : null}

      {isReconline ? (
        <div className="flex w-full flex-col gap-y-6 md:w-2/3">
          <Controller
            control={control}
            name="occupancyPricing.additionalChildPrice"
            render={({ field: { value, onChange, name } }) => (
              <Input
                disabled={isSubmitting}
                label="+1 Child Additional Fee (2 Adults)"
                placeholder="Please enter the additional child fee"
                background="grey"
                leadingAddon={CurrencyFormatter.currencySymbol()}
                name={name}
                value={value}
                onChange={onChange}
                showClearButton={false}
              />
            )}
          />
        </div>
      ) : null}

      {isWebhotelier ? (
        <div className="flex w-full flex-col gap-y-6 md:w-2/3">
          <Controller
            control={control}
            name="occupancyPricing.additionalAdultPrice"
            render={({ field: { value, onChange, name } }) => (
              <Input
                disabled={isSubmitting}
                label="Extra Adult"
                placeholder="Please enter the extra child fee"
                background="grey"
                leadingAddon={CurrencyFormatter.currencySymbol()}
                name={name}
                value={value}
                onChange={onChange}
                showClearButton={false}
              />
            )}
          />
          <Controller
            control={control}
            name="occupancyPricing.additionalChildPrice"
            render={({ field: { value, onChange, name } }) => (
              <Input
                disabled={isSubmitting}
                label="Extra Child"
                placeholder="Please enter the extra child fee"
                background="grey"
                leadingAddon={CurrencyFormatter.currencySymbol()}
                name={name}
                value={value}
                onChange={onChange}
                showClearButton={false}
              />
            )}
          />
        </div>
      ) : null}
    </>
  );
};
