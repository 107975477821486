import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { Feature, useFeaturesStore } from '@common/store/features';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Flex } from '@mantine/core';
import { useDefaultSurgeConfig } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useDefaultSurgeConfig';
import { useFormSchema } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useFormSchema';
import {
  Tabs,
  useSurgeProtectionStore
} from '@pages/Client/PricingStrategy/SurgeProtection/store/surgeProtection';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { Spotlight } from '@mantine/spotlight';
import { useDisclosure } from '@mantine/hooks';
import { SimulateSurgeEventsModal } from '@pages/Client/PricingStrategy/SurgeProtection/components/SimulateSurgeEventsModal';
import { IconSearch } from '@tabler/icons-react';
import { useViewStore } from '@common/store/view';
import { useAuthStore } from '@common/store/auth';

// TODO: Add auto price protection back, when it's ready
export const SurgeProtectionLayout = () => {
  const { t } = useTranslation();
  const { features } = useFeaturesStore();
  const { pathname } = useLocation();
  const [opened, { open, close }] = useDisclosure();
  const { user } = useAuthStore();
  const isPartnerUser = user?.sub_account_type === 2;
  const isSalesUser = user?.sub_account_type === 1;
  const { view } = useViewStore();

  const { isDontSendEmail, setIsAutoProtectionCheckboxEnable, setPricingTypeTabValue } =
    useSurgeProtectionStore();

  const {
    pricingSettings,
    pricingSettingsQuery: { isLoading: isPricingSettingsLoading }
  } = usePricingSettings();
  const { isLoading: isSavePricingLoading, savePricingSettings } = useUpdatePricingSettings();

  const { defaultConfig, isLoading: isDefaultConfigLoading } = useDefaultSurgeConfig();

  const { schema } = useFormSchema();

  const methods = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (!isPricingSettingsLoading) {
      methods.setValue('threshold', pricingSettings?.surge_protection?.threshold as number);
      methods.setValue('days_ahead', pricingSettings?.surge_protection?.days_ahead as number);
      methods.setValue('fix_price', pricingSettings?.surge_protection?.fix_price as number);
      methods.setValue(
        'duration_in_days',
        pricingSettings?.surge_protection?.duration_in_days as number
      );
      methods.setValue('decay_step', pricingSettings?.surge_protection?.decay_step as number);
      methods.setValue(
        'user_recipients',
        pricingSettings?.surge_protection?.user_recipients as number[]
      );
      methods.setValue(
        'auto_price_protection',
        pricingSettings?.surge_protection?.auto_price_protection as boolean
      );
      setIsAutoProtectionCheckboxEnable(
        pricingSettings?.surge_protection?.auto_price_protection as boolean
      );
      if (pricingSettings?.surge_protection?.decay_step) {
        setPricingTypeTabValue(Tabs.DECAY_PRICE);
      }
    }
  }, [isPricingSettingsLoading]);

  const onSubmit = async (data: z.infer<typeof schema>) => {
    if (isDontSendEmail) {
      data.user_recipients = [];
    }
    const newSettings = {
      ...pricingSettings,
      surge_protection: {
        ...pricingSettings?.surge_protection,
        ...data
      }
    };
    await savePricingSettings(JSON.stringify(newSettings));
  };

  const subMenuLink = [
    {
      id: 'settings',
      label: t('Surge Settings'),
      to: 'settings'
    },
    ...(features?.includes(Feature.SurgeProtectionPro)
      ? [
          {
            id: 'price-settings',
            label: t('Protection Price Settings'),
            to: 'price-settings'
          }
        ]
      : []),
    {
      id: 'logs',
      label: t('Surge Event Logs'),
      to: 'logs'
    }
  ];

  return (
    <>
      <Page
        header={
          <Header
            tabs={subMenuLink}
            actions={
              <Flex justify="end" gap="sm">
                <Button
                  variant="outline"
                  loading={isDefaultConfigLoading}
                  disabled={isDefaultConfigLoading || pathname.includes('logs')}
                  onClick={() => {
                    methods.reset({
                      threshold: defaultConfig?.threshold,
                      days_ahead: defaultConfig?.days_ahead,
                      fix_price: defaultConfig?.fix_price,
                      duration_in_days: defaultConfig?.duration_in_days,
                      auto_price_protection: methods.watch('auto_price_protection'),
                      user_recipients: [],
                      decay_step: defaultConfig?.decay_step
                    });
                    if (defaultConfig?.decay_step) {
                      setPricingTypeTabValue(Tabs.DECAY_PRICE);
                    } else {
                      setPricingTypeTabValue(Tabs.FIXED_PRICE);
                    }
                  }}>
                  {t('Set Default')}
                </Button>

                <Button
                  type="submit"
                  loading={isSavePricingLoading}
                  disabled={isDefaultConfigLoading || pathname.includes('logs')}
                  onClick={methods.handleSubmit(onSubmit)}>
                  {t('Save')}
                </Button>
              </Flex>
            }
            title={t('Surge Protection')}
          />
        }>
        <FormProvider {...methods}>
          <Outlet />
        </FormProvider>
      </Page>
      {view === 'admin' && (!isSalesUser || !isPartnerUser) ? (
        <>
          <Spotlight
            searchProps={{
              leftSection: <IconSearch />,
              placeholder: 'Search'
            }}
            overlayProps={{
              blur: 0
            }}
            actions={[
              {
                id: 'simulate-surge',
                label: 'Simulate Surge Events',
                onClick: () => {
                  open();
                }
              }
            ]}
          />

          <SimulateSurgeEventsModal opened={opened} close={close} />
        </>
      ) : null}
    </>
  );
};
