import { ModalFooter } from '@common/mantine/components/modal-footer';
import { Button, Modal, Stack } from '@mantine/core';
import {
  MinstaySchema,
  PartialPricingSettingsSchema,
  RuleSchema
} from '@pages/Client/Calendar/utils/validateBaseSettings';
import { t } from 'i18next';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { zodResolver } from '@hookform/resolvers/zod';
import { RuleForm } from '@pages/Client/PricingStrategy/MinStay/components/RuleForm';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import {
  MinStay,
  MinStayRule,
  MinStayRules,
  ValidatedRule
} from '@pages/Client/PricingStrategy/MinStay/types';
import { useEventTracking } from '@common/hooks/useEventTracking';

type RuleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  mode: 'add' | 'edit';
  priority?: number | null;
};

export const createUniqueRuleSchema = (
  currentRules: MinStayRule[],
  editMode: boolean,
  currentPriority?: number
) => {
  return RuleSchema.extend({
    name: z
      .string()
      .nonempty('Please enter a name')
      .refine(
        (name) => {
          return !currentRules.some(
            (rule) => rule.name === name && (!editMode || rule.priority !== currentPriority)
          );
        },
        { message: 'This name is already in use' }
      )
  });
};

export const createUniqueMinstaySchema = (
  currentRules: MinStayRule[],
  editMode: boolean,
  currentPriority?: number
) => {
  const UniqueRuleSchema = createUniqueRuleSchema(currentRules, editMode, currentPriority);
  return MinstaySchema.extend({
    rules: z.array(UniqueRuleSchema).optional()
  });
};

export const RuleModal = ({ isOpen, onClose, mode, priority }: RuleModalProps) => {
  const { pricingSettings } = usePricingSettings();

  const { savePricingSettings, isLoading: isSavePricingLoading } = useUpdatePricingSettings();
  const { trackEvent } = useEventTracking();

  const rules = pricingSettings?.minstay?.rules || [];
  // Applicable when mode is 'edit'
  const ruleToEdit = rules.find((rule) => rule.priority === priority);

  const uniqueMinstaySchema = createUniqueMinstaySchema(
    rules,
    mode === 'edit',
    ruleToEdit?.priority
  );

  const methods = useForm<MinStay>({
    resolver: zodResolver(uniqueMinstaySchema)
  });

  const { fields: fieldFields, remove: removeField } = useFieldArray({
    control: methods.control,
    name: 'rules.0.fields'
  });

  const updateRuleByPriority = (
    rules: MinStayRules,
    priority: number,
    updates: ValidatedRule
  ): ValidatedRule[] => {
    if (!rules) return [updates];

    return rules?.map((rule) => {
      if (rule.priority === priority) {
        return { ...rule, ...updates };
      }
      return rule as ValidatedRule;
    });
  };

  const handleClose = () => {
    methods.reset();
    fieldFields.forEach((_, fieldIndex) => removeField(fieldIndex));
    onClose();
  };

  const saveRules = async (data: MinStay) => {
    if (!data.rules || data.rules.length === 0) {
      return;
    }

    const validatedRule = RuleSchema.parse(data.rules[0]);

    const newSettings: z.infer<typeof PartialPricingSettingsSchema> = {
      ...pricingSettings,
      minstay: {
        ...pricingSettings?.minstay,
        rules:
          mode === 'edit' && priority
            ? updateRuleByPriority(pricingSettings?.minstay?.rules || [], priority, validatedRule)
            : pricingSettings?.minstay?.rules
            ? [...pricingSettings.minstay.rules, validatedRule]
            : [validatedRule]
      }
    };

    await savePricingSettings(JSON.stringify(newSettings));
    trackEvent('MinStayAutomationApplied', {
      tags: {
        mode,
        type: validatedRule.fields[0].type === 'single' ? 'single' : 'multiple',
        field: validatedRule.fields[0].type === 'single' ? validatedRule.fields[0].field : ''
      }
    });
    handleClose();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={
        mode === 'add'
          ? t('Removal Criteria')
          : t('Edit Removal Criteria "{{ruleName}}"', { ruleName: ruleToEdit?.name })
      }
      size="lg"
      centered
      styles={{
        body: {
          padding: '0'
        }
      }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(saveRules)}>
          <Stack px="md" pb="md" gap="lg">
            <RuleForm ruleToEdit={ruleToEdit} mode={mode} />
          </Stack>
          <ModalFooter>
            <Button onClick={handleClose} variant="subtle">
              {t('Cancel')}
            </Button>
            <Button type="submit" loading={isSavePricingLoading}>
              {t('Save')}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};
