import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash-es';
import { ReactNode } from 'react';
import { Colors, backgroundColors } from '@common/components/foundations/colorVariants';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';

export function Card({
  children,
  backgroundColor,
  className
}: {
  children: React.ReactNode;
  backgroundColor: Colors;
  className?: string;
}) {
  return (
    <div className={clsx('h-full rounded-lg shadow', backgroundColors[backgroundColor], className)}>
      <div className="grid h-full grid-flow-row p-4">{children}</div>
    </div>
  );
}

export function PerformanceCard({
  topLeftValue,
  topLeftText,
  topLeftTooltipIcon,
  diff,
  sameTimeLastYear,
  sameTimeLastYearTooltipIcon,
  totalLastYear,
  totalLastYearTooltipIcon,
  negative,
  highlight,
  small,
  isLoading,
  forecast,
  forecastTooltipIcon,
  budget,
  budgetTooltipIcon,
  showNegativeBg,
  chart
}: {
  topLeftValue: string | number;
  topLeftText: string;
  topLeftTooltipIcon?: ReactNode;
  sameTimeLastYear: string | number;
  sameTimeLastYearTooltipIcon?: ReactNode;
  totalLastYear?: string | number;
  totalLastYearTooltipIcon?: ReactNode;
  diff?: string;
  small?: boolean;
  isLoading?: boolean;
  negative?: boolean;
  highlight?: boolean;
  forecast?: string;
  forecastTooltipIcon?: ReactNode;
  budget?: string;
  budgetTooltipIcon?: ReactNode;
  showNegativeBg?: boolean;
  chart?: React.ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <Card
      backgroundColor={
        showNegativeBg && negative && highlight
          ? 'uiRed'
          : !negative && highlight
          ? 'darkGreen'
          : 'white'
      }
      className={clsx(
        'relative grid min-h-[200px] w-full min-w-[230px] max-w-sm snap-start',
        isLoading ? 'animate-pulse bg-grey-reduced' : null
      )}>
      {chart ? (
        <div className="absolute left-0 right-0 top-16 w-full opacity-60">{chart}</div>
      ) : null}
      <div className="grid h-full">
        <div className="grid grid-flow-col justify-between">
          <div className="flex flex-col">
            {isLoading ? (
              <Skeleton className="h-7 w-16" />
            ) : (
              <Typography
                element="span"
                variant="h6"
                color={!showNegativeBg && highlight ? 'white' : 'indigo'}>
                {topLeftValue}
              </Typography>
            )}
            <div className="flex items-center gap-1">
              <Typography
                element="span"
                variant="meta-2"
                color={!showNegativeBg && highlight ? 'white' : 'copyTextGrey'}>
                {topLeftText}
              </Typography>
              {topLeftTooltipIcon}
            </div>
          </div>
          {isLoading ? (
            <Skeleton className="h-5 w-14 rounded-full" />
          ) : diff ? (
            <div className="flex h-6 items-center gap-x-1 rounded-full bg-white px-2 py-1">
              {negative ? (
                <Icon.TrendingDown className="h-3 w-3 fill-uiRed" />
              ) : (
                <Icon.TrendingUp className="h-3 w-3 fill-uiGreen" />
              )}
              <Typography
                element="span"
                variant="meta-2"
                color={negative ? 'uiRed' : 'uiGreen'}
                >
                {diff}
              </Typography>
            </div>
          ) : null}
        </div>

        <div
          className={clsx(
            'mt-8 grid place-content-end justify-stretch gap-y-2',
            forecast && budget ? 'grid-cols-2 ' : 'grid-cols-1'
          )}>
          <div className="flex flex-col justify-between gap-x-5">
            <div className="flex flex-col">
              {isLoading ? (
                <Skeleton className="h-5 w-12" />
              ) : (
                <Typography
                  element="span"
                  variant="paragraph-2"
                  color={!showNegativeBg && highlight ? 'white' : 'darkGrey'}>
                  {sameTimeLastYear}
                </Typography>
              )}
              <div className="flex items-center gap-1">
                <Typography
                  element="span"
                  variant="meta-2"
                  color={!showNegativeBg && highlight ? 'white' : 'copyTextGrey'}
                  className={clsx(forecast && budget ? 'truncate' : null)}>
                  {t('Same Time Last Year')}
                </Typography>
                {sameTimeLastYearTooltipIcon}
              </div>
            </div>

            {small ? null : (
              <div className="flex flex-col">
                {isLoading ? (
                  <Skeleton className="h-5 w-12" />
                ) : totalLastYear ? (
                  <Typography
                    element="span"
                    variant="paragraph-2"
                    color={!showNegativeBg && highlight ? 'white' : 'darkGrey'}>
                    {totalLastYear}
                  </Typography>
                ) : null}
                <div className="flex items-center gap-1">
                  <Typography
                    element="span"
                    variant="meta-2"
                    color={!showNegativeBg && highlight ? 'white' : 'copyTextGrey'}>
                    {t('Total Last Year')}
                  </Typography>
                  {totalLastYearTooltipIcon}
                </div>
              </div>
            )}
          </div>
          {forecast && budget ? (
            <>
              <div className="flex flex-col justify-between gap-x-5">
                <div className="flex flex-col items-end text-right">
                  {isLoading ? (
                    <div className="flex w-full justify-end">
                      <Skeleton className="h-5 w-12" />
                    </div>
                  ) : (
                    <Typography
                      element="span"
                      variant="paragraph-2"
                      color={!showNegativeBg && highlight ? 'white' : 'darkGrey'}>
                      {forecast}
                    </Typography>
                  )}
                  <div className="flex items-center gap-1">
                    <Typography
                      element="span"
                      variant="meta-2"
                      color={!showNegativeBg && highlight ? 'white' : 'copyTextGrey'}>
                      {t('Forecast')}
                    </Typography>
                    {forecastTooltipIcon}
                  </div>
                </div>

                <div className="flex flex-col items-end text-right">
                  {isLoading ? (
                    <div className="flex w-full justify-end">
                      <Skeleton className="h-5 w-12" />
                    </div>
                  ) : (
                    <Typography
                      element="span"
                      variant="paragraph-2"
                      color={!showNegativeBg && highlight ? 'white' : 'darkGrey'}>
                      {budget}
                    </Typography>
                  )}
                  <div className="flex items-center gap-1">
                    <Typography
                      element="span"
                      variant="meta-2"
                      color={!showNegativeBg && highlight ? 'white' : 'copyTextGrey'}>
                      {t('Budget')}
                    </Typography>
                    {budgetTooltipIcon}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </Card>
  );
}

export function AnalyticsCard({
  total,
  totalLabel,
  totalTooltipIcon,
  sortByIcon,
  segment,
  isLoading
}: {
  total: string;
  totalLabel: string;
  totalTooltipIcon?: ReactNode;
  sortByIcon?: ReactNode;
  segment: {
    id: number;
    segment: string;
    value: string;
  }[];
  isLoading?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Card
      backgroundColor="white"
      className={clsx(
        'min-h-[180px] w-full min-w-[230px] max-w-xs snap-start',
        isLoading ? 'animate-pulse bg-grey-reduced' : null
      )}>
      <div className="grid w-full space-y-3">
        <div className="flex flex-1 items-start">
          <div className="flex flex-col">
            {isLoading ? (
              <Skeleton className="h-5 w-12" />
            ) : (
              <Typography element="span" variant="h6" color="indigo">
                {total}
              </Typography>
            )}
            <div className="flex items-center gap-1">
              <Typography element="span" variant="meta-2" color="copyTextGrey">
                {truncate(totalLabel, { length: 30 })}
              </Typography>
              {totalTooltipIcon}
              {sortByIcon}
            </div>
          </div>
        </div>

        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} className="h-5 w-full" />
            ))
          : segment.map((item) => (
              <div
                key={item.id}
                className="flex max-w-full flex-1 items-center gap-x-2 overflow-hidden"
                title={item.segment}>
                <Typography
                  element="span"
                  variant="meta-2"
                  color="copyTextGrey"
                  className="truncate">
                  {t(item.segment)}
                </Typography>
                <div className="flex-grow" />
                <Typography
                  element="span"
                  variant="paragraph-2"
                  className="shrink-0 text-right text-paragraph-1-medium"
                  color="darkGrey">
                  {item.value}
                </Typography>
              </div>
            ))}
      </div>
    </Card>
  );
}
