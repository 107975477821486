import { Button } from '@common/components/atoms/Button';
import { Divider } from '@common/components/atoms/Divider';
import { Input } from '@common/components/atoms/Input';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useViewStore } from '@common/store/view';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { DayOfTheWeekAdjustmentsChart } from '@pages/Client/PricingStrategy/DayOfTheWeekAdjustments/components/DayOfTheWeekAdjustmentsChart';
import { formattedCurrency } from '@pages/Client/PricingStrategy/DayOfTheWeekAdjustments/utils/formattedCurrency';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useTranslation } from 'react-i18next';
import { useWarnings } from '@common/store/warnings';
import { useDocumentTitle } from '@mantine/hooks';

const Percent = () => {
  return <>%</>;
};

interface DayData {
  standard: number;
}

interface TransformedData {
  day: string;
  value: number;
}

function transformDataAdjustment(data: Record<string, DayData>): TransformedData[] {
  const dayMapping: Record<string, string> = {
    monday: '1',
    tuesday: '2',
    wednesday: '3',
    thursday: '4',
    friday: '5',
    saturday: '6',
    sunday: '7'
  };

  return Object.entries(data).map(([day, values]) => ({
    day: dayMapping[day],
    value: values.standard
  }));
}

function getFullDayName(day: string): string {
  const dayMapping: { [key: string]: string } = {
    Sun: 'sunday',
    Mon: 'monday',
    Tue: 'tuesday',
    Wed: 'wednesday',
    Thu: 'thursday',
    Fri: 'friday',
    Sat: 'saturday'
  };

  return dayMapping[day] || '';
}

function transformDataChart(data: Record<string, any>[]): TransformedData[] {
  const dayMapping: Record<string, string> = {
    '1': 'Mon',
    '2': 'Tue',
    '3': 'Wed',
    '4': 'Thu',
    '5': 'Fri',
    '6': 'Sat',
    '7': 'Sun'
  };
  return data.map((item) => ({
    day: dayMapping[item.day] || '',
    value: item.value
  }));
}

function sortKeysByPriority(data: Record<string, any> | undefined, priorityKey: string): string[] {
  if (data === undefined) {
    return [];
  }
  const keys = Object.keys(data);
  const priorityIndex = keys.indexOf(priorityKey);

  if (priorityIndex !== -1) {
    const sortedKeys = [...keys];
    sortedKeys.splice(priorityIndex, 1);
    sortedKeys.unshift(priorityKey);
    return sortedKeys;
  }
  return keys;
}

function moveKeyToIndexZero(obj: Record<string, any>): Record<string, number> {
  const entries = Object.entries(obj);
  const index7 = entries.findIndex(([key]) => key === '7');
  if (index7 > -1) {
    const [entry7] = entries.splice(index7, 1);
    entries.unshift(entry7);
  }
  return Object.fromEntries(entries);
}

function sortArrayByDay(array: any[]): any[] {
  const dayOrder = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return array.sort((a, b) => {
    const dayA = a.day;
    const dayB = b.day;
    const orderA = dayOrder.indexOf(dayA);
    const orderB = dayOrder.indexOf(dayB);
    return orderA - orderB;
  });
}

const isWithinRange = (val: number | string | undefined) => {
  if (val === undefined || val === '') {
    return false;
  }
  const numValue = typeof val === 'string' ? Number(val) : val;
  return numValue >= -50 && numValue <= 80;
};
const validationMessage = 'Please enter a value between -50% and 80%.';
const validationEmptyMessage = 'Please enter a value';

const adjustmentErrorMap: z.ZodErrorMap = (error) => {
  switch (error.code) {
    case 'invalid_type':
      return { message: validationEmptyMessage };

    default:
      return { message: validationEmptyMessage };
  }
};

const schema = z.object({
  adjustment: z.object({
    weekday: z.object({
      monday: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(
            z
              .string()
              .nonempty({ message: validationEmptyMessage })
              .transform((val) => Number(val))
          )
          .refine(isWithinRange, { message: validationMessage })
      }),
      tuesday: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(
            z
              .string()
              .nonempty({ message: validationEmptyMessage })
              .transform((val) => Number(val))
          )
          .refine(isWithinRange, { message: validationMessage })
      }),
      wednesday: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      thursday: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      friday: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      saturday: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      sunday: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      })
    })
  })
});

export const DayOfTheWeekAdjustments = () => {
  const { t } = useTranslation();
  const { createWarning } = useWarnings();
  const { cachePriceQuery, pricingSettingsQuery } = useRoomPrices();
  const { features } = useFeaturesStore();
  const { data: pricingSettings, isLoading: isPricingLoading } = pricingSettingsQuery;
  const { data: cachePrice, isLoading: isPriceCacheLoading } = cachePriceQuery;
  const {
    isLoading: isSavePricingLoading,
    savePricingSettings,
    isSuccess: isSaveSuccess
  } = useUpdatePricingSettings();
  useDocumentTitle(t('Day-of-the-Week Adjustments'));

  const { view } = useViewStore();
  const { hotelDetails } = useHotelDetails();

  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });

  const DATA_AVERAGE_BASE_PRICE_NEXT_3_MONTH = cachePrice
    ? cachePrice?.data?.prices?.average_price_week_month?.comp_adjusted_price_averages
        ?.quarter_weekdays
    : {};

  const sortedAverageBasePriceNext3Month = sortKeysByPriority(
    DATA_AVERAGE_BASE_PRICE_NEXT_3_MONTH,
    '7'
  );
  const averageBasePriceNext3Month = sortedAverageBasePriceNext3Month.map((key) => ({
    day: key,
    value: parseFloat(DATA_AVERAGE_BASE_PRICE_NEXT_3_MONTH[key])
  }));

  const DATA_AVERAGE_PMS_PRICE = cachePrice
    ? cachePrice?.data?.prices?.average_price_week_month?.pms_price_averages?.quarter_weekdays
    : {};
  const sortedAveragePmsPrice = sortKeysByPriority(DATA_AVERAGE_PMS_PRICE, '7');
  const averagePmsPrice = sortedAveragePmsPrice.map((key) => ({
    day: key,
    value: parseFloat(DATA_AVERAGE_PMS_PRICE[key])
  }));

  const DATA_AVERAGE_PICKUP_BOOST = cachePrice
    ? cachePrice?.data?.prices?.average_price_week_month?.pickupboostprice_averages
        ?.quarter_weekdays
    : {};
  const sortedAveragePickupBoost = DATA_AVERAGE_PICKUP_BOOST
    ? sortKeysByPriority(DATA_AVERAGE_PICKUP_BOOST, '7')
    : [];
  const averagePickupBoost = sortedAveragePickupBoost.map((key) => ({
    day: key,
    value: parseFloat(DATA_AVERAGE_PICKUP_BOOST[key])
  }));

  const DATA_ADJUSTMENT = pricingSettings ? pricingSettings?.adjustment?.weekday : {};
  const formatAdjustment = transformDataAdjustment(DATA_ADJUSTMENT);
  const sortedAdjustment = transformDataChart(
    Object.values(moveKeyToIndexZero(formatAdjustment) as any)
  );

  const startMondaySort = (a: { day: string }, b: { day: string }): number => {
    const days: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const startIndex: number = hotelDetails?.starts_monday ? 1 : 0; // Adjust the start index
    return (
      ((days.indexOf(a.day) - startIndex + 7) % 7) - ((days.indexOf(b.day) - startIndex + 7) % 7)
    );
  };

  const defaultWeekday = sortArrayByDay(sortedAdjustment).reduce(
    (acc, { day, value }) => {
      acc.weekday[getFullDayName(day)] = { standard: value };
      return acc;
    },
    { weekday: {} }
  );

  const {
    watch,
    handleSubmit,
    getValues,
    control,
    formState: { errors }
  } = useForm<z.infer<typeof schema>>({
    defaultValues: {
      adjustment: {
        ...defaultWeekday
      }
    },
    resolver: zodResolver(schema)
  });
  const [skeletonLoading, setSkeletonLoading] = useState(true);

  useEffect(() => {
    if (!isPricingLoading && !isPriceCacheLoading) {
      setSkeletonLoading(false);
    }
  }, [isPricingLoading, isPriceCacheLoading]);

  const calculateAdjustedData = (
    inputData: TransformedData[]
  ): { day: string; value: number }[] => {
    const formValue = watch() ? watch() : getValues();
    return inputData.map((item) => {
      const { day, value } = item;
      const standard =
        formValue.adjustment.weekday[
          getFullDayName(day) as keyof typeof formValue.adjustment.weekday
        ].standard;

      const calculatedValue = (standard * value) / 100 + value;
      const roundedValue = Math.round(calculatedValue);

      return {
        day,
        value: roundedValue
      };
    });
  };

  const onSubmit = async (data: any) => {
    const warningsToProcess: Promise<void>[] = [];

    const addWarningIfNeeded = (condition: boolean, warningMessage: string) => {
      if (condition) {
        warningsToProcess.push(createWarning({ message: t(warningMessage) }));
      }
    };

    const isAllAbove10 = Object.values(data.adjustment.weekday).every(
      (item: any) => item.standard > 10
    );

    const isAllBelowNegative5 = Object.values(data.adjustment.weekday).every(
      (item: any) => item.standard < -5
    );

    const settings = JSON.stringify({
      ...pricingSettings,
      adjustment: {
        weekday: data.adjustment.weekday,
        lead_time: pricingSettings?.adjustment.lead_time,
        monthly: pricingSettings?.adjustment.monthly
      }
    });

    addWarningIfNeeded(
      isAllAbove10,
      'When you have set all daily adjustments to positive it means your base price may be too low. Are you sure?'
    );

    addWarningIfNeeded(
      isAllBelowNegative5,
      'When you have set all daily adjustments to negative it means your base price may be too high. Are you sure?'
    );

    const processWarnings = async () => {
      if (warningsToProcess.length > 0) {
        try {
          await warningsToProcess.shift();
          processWarnings();
        } catch (error) {
          console.log('User cancelled warning');
        }
      } else {
        savePricingSettings(settings);
      }
    };

    await processWarnings();
  };

  useEffect(() => {
    setIsSuccessRef({ current: isSaveSuccess });
    if (isSaveSuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isSaveSuccess]);

  const IsAvgPickupBoostEnable = features?.includes(22);

  return (
    <Page
      header={
        <Header
          title={t('Day-of-the-Week Adjustments')}
          description={
            features?.includes(Feature.NoMarketData)
              ? `${t(
                  'Use Day-of-the-week Adjustments to adjust price recommendations to suit your property.'
                )}`
              : `${t(
                  "We check the rates of other properties that represent your local 'market'. Next, we estimate what they would charge if they had the same base price as you. Below, we show these 'market' prices for the different weekdays. However, every property is different, use Day-of-the-week Adjustments to adjust price recommendations to suit your property."
                )}`
          }
          actions={
            <Button
              disabled={isSavePricingLoading}
              isSuccess={isSuccessRef.current}
              isLoading={isSavePricingLoading}
              onClick={handleSubmit(onSubmit)}>
              {t('Save')}
            </Button>
          }
        />
      }>
      <div className="flex flex-col gap-4">
        <div className="mb-3">
          <DayOfTheWeekAdjustmentsChart
            abpnm={calculateAdjustedData(transformDataChart(averageBasePriceNext3Month))}
            pmsprice={transformDataChart(averagePmsPrice)}
            llm={transformDataChart(averageBasePriceNext3Month)}
            startMonday={hotelDetails?.starts_monday}
          />
        </div>
        <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-8">
          {isPricingLoading && isPriceCacheLoading && skeletonLoading ? (
            <>
              {[...Array(8)].map((_, index) => (
                <Skeleton key={index} className="max-w-10 h-10" />
              ))}
            </>
          ) : (
            <>
              <div>
                <Typography color="darkGrey" variant="meta-2" element="p">
                  {t('Adjust')}
                  <br />
                  {t('Price by')}
                </Typography>
              </div>
              {sortArrayByDay(sortedAdjustment)
                .sort(startMondaySort)
                .map((item) => (
                  <div key={item.day}>
                    <Controller
                      name={`adjustment.weekday.${getFullDayName(item.day)}.standard` as any}
                      control={control}
                      defaultValue={parseInt(item.value)}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          name={`adjustment.weekday.${getFullDayName(item.day)}.standard`}
                          type="number"
                          value={value}
                          label={`${t(`${item.day}`)}`}
                          placeholder={item.day}
                          onChange={onChange}
                          showClearButton={false}
                          error={!!error}
                          trailingAddon={<Percent />}
                          helperMessage={
                            (
                              errors.adjustment?.weekday?.[
                                getFullDayName(item.day) as keyof typeof errors.adjustment.weekday
                              ] as FieldError & { standard?: any }
                            )?.standard && (
                              <div className="flex items-center gap-2 text-error">
                                <Typography element="span" color="error" variant="meta-2">
                                  {(
                                    errors?.adjustment?.weekday?.[
                                      getFullDayName(
                                        item.day
                                      ) as keyof typeof errors.adjustment.weekday
                                    ] as FieldError & { standard?: any }
                                  )?.standard.message?.toString()}
                                </Typography>
                              </div>
                            )
                          }
                        />
                      )}
                    />
                  </div>
                ))}
            </>
          )}
        </div>
        <Divider />
        <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-8">
          {skeletonLoading ? (
            <>
              {[...Array(8)].map((_, index) => (
                <Skeleton key={index} className="max-w-10 h-8" />
              ))}
            </>
          ) : (
            <>
              <div>
                <Typography color="darkGrey" variant="meta-2" element="p">
                  {t('Average Base Price Next 3 Months')}
                </Typography>
              </div>
              {calculateAdjustedData(transformDataChart(averageBasePriceNext3Month))
                .sort(startMondaySort)
                .map((item) => (
                  <div className="text-right" key={item.day}>
                    <Typography
                      color="copyTextGrey"
                      variant="meta-1"
                      element="p"
                      className="font-light">
                      <span className="md:sr-only">{`${item.day} : `}</span>{' '}
                      {formattedCurrency(item.value)}
                    </Typography>
                  </div>
                ))}
            </>
          )}
        </div>
        {view === 'admin' && IsAvgPickupBoostEnable && view === 'admin' ? (
          <>
            <Divider />
            <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-8">
              {skeletonLoading && DATA_AVERAGE_PICKUP_BOOST ? (
                <>
                  {[...Array(8)].map((_, index) => (
                    <Skeleton key={index} className="max-w-10 h-8" />
                  ))}
                </>
              ) : (
                <>
                  <div className="max-w-[80px]">
                    <Typography color="darkGrey" variant="meta-2" element="p">
                      {t('Average Pickup Boost')}
                    </Typography>
                  </div>
                  {transformDataChart(averagePickupBoost)
                    .sort(startMondaySort)
                    .map((item) => (
                      <div className="text-right" key={item.day}>
                        <Typography
                          color="copyTextGrey"
                          variant="meta-1"
                          element="p"
                          className="font-light">
                          <span className="md:sr-only">{`${item.day} : `}</span>{' '}
                          {formattedCurrency(item.value)}
                        </Typography>
                      </div>
                    ))}
                </>
              )}
            </div>
          </>
        ) : null}
      </div>
    </Page>
  );
};
