import { useCallback } from 'react';
import {
  keyBy,
  map,
  isEmpty,
  result,
  isObject,
  filter,
  flatMap,
  toPairs,
  isArray,
  isNil
} from 'lodash-es';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import {
  AdjustmentSchemaKeys,
  BulkRemoveSchema,
  TransformSchema
} from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import {
  ADJUSTMENT,
  ADJUSTMENT_DB
} from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { current, produce, createDraft, finishDraft } from 'immer';
import { API_DATE_FORMAT } from '@common/constants/date';
import dayjs from 'dayjs';
import { generateDateRange } from '@pages/Client/Calendar/components/BulkEdit/helpers/generateDateRange';
import { transformPercentAdjustment } from '@pages/Client/Calendar/components/BulkEdit/helpers/schema/transformPercentAdjustment';
import { transformFixedPrices } from '@pages/Client/Calendar/components/BulkEdit/helpers/schema/transformFixedPrices';
import { transformMedianWindow } from '@pages/Client/Calendar/components/BulkEdit/helpers/schema/transformMedianWindow';
import { transformAggressiveness } from '@pages/Client/Calendar/components/BulkEdit/helpers/schema/transformAggressiveness';
import { transformClosedSpaces } from '@pages/Client/Calendar/components/BulkEdit/helpers/schema/transformClosedSpaces';
import { transformDerivation } from '@pages/Client/Calendar/components/BulkEdit/helpers/schema/transformDerivation';
import { transformMinMax } from '@pages/Client/Calendar/components/BulkEdit/helpers/schema/transformMinMax';
import { transformOccupancy } from '@pages/Client/Calendar/components/BulkEdit/helpers/schema/transformOccupancy';
import { transformMinStay } from '@pages/Client/Calendar/components/BulkEdit/helpers/schema/transformMinStay';
import { PricingDatesSchema, PricingSettings } from '@common/api/hotel/types';
import { PercentAdjustment } from '@pages/Client/Calendar/components/BulkEdit/types/schema/percentageAdjustmentSchema';
import { FixedPrices } from '@pages/Client/Calendar/components/BulkEdit/types/schema/fixedPricesSchema';
import { ClosedSpaces } from '@pages/Client/Calendar/components/BulkEdit/types/schema/closedSpacesSchema';
import { Derivation } from '@pages/Client/Calendar/components/BulkEdit/types/schema/derivationSchema';
import { Occupancy } from '@pages/Client/Calendar/components/BulkEdit/types/schema/occupancySchema';
import { MinStay } from '@pages/Client/Calendar/components/BulkEdit/types/schema/minStaySchema';
import { MinMax } from '@pages/Client/Calendar/components/BulkEdit/types/schema/minMaxSchema';
import { removeEmptyObjects } from '@pages/Client/Calendar/components/BulkEdit/helpers/removeEmptyObjects';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';

type TransformDataReturn = { newPricing: PricingSettings | null; dates: string[] };

export function useTransformData() {
  const { pricingSettings } = usePricingSettings();

  async function transformData(
    formDataRaw: Partial<TransformSchema>
  ): Promise<TransformDataReturn> {
    try {
      if (isEmpty(pricingSettings)) return { newPricing: null, dates: [] };
      const rgpDefaults = pricingSettings[ADJUSTMENT_DB.RPG_ARGUMENTS_KEY];
      const dateArray = formDataRaw?.isBulkEdit
        ? generateDateRange(formDataRaw?.dateRange, formDataRaw.weekDays)
        : [dayjs(formDataRaw.editDate).startOf('day').format(API_DATE_FORMAT)];

      const newSettingsDraft = createDraft(pricingSettings);
      for (const rawDate of dateArray as string[]) {
        const date = dayjs(rawDate).startOf('day').format(API_DATE_FORMAT);
        for (const [key, formData] of toPairs(formDataRaw)) {
          if (isArray(formData)) {
            if (key === ADJUSTMENT.CLOSED) {
              const formDataById = keyBy(formData, 'id') as Record<string, ClosedSpaces>;
              await transformClosedSpaces(newSettingsDraft, formDataById, date);
            }
            if (key === ADJUSTMENT.DERIVATION) {
              const formDataById = keyBy(formData, 'id') as Record<string, Derivation>;
              await transformDerivation(newSettingsDraft, formDataById, date);
            }
            if (key === ADJUSTMENT.FIX_PRICES) {
              const formDataById = keyBy(formData, 'id') as Record<string, FixedPrices>;
              await transformFixedPrices(newSettingsDraft, formDataById, date);
            }
            if (key === ADJUSTMENT.MIN_MAX) {
              const formDataById = keyBy(formData, 'id') as Record<string, MinMax>;
              await transformMinMax(newSettingsDraft, formDataById, date);
            }
            if (key === ADJUSTMENT.MIN_STAY) {
              const formDataById = keyBy(formData, 'id') as Record<string, MinStay>;
              await transformMinStay(newSettingsDraft, formDataById, date);
            }
            if (key === ADJUSTMENT.OCCUPANCY) {
              const formDataById = formData as Occupancy[][];
              await transformOccupancy(newSettingsDraft, formDataById, date);
            }
            if (key === ADJUSTMENT.PERCENT) {
              const formDataById = keyBy(formData, 'id') as Record<string, PercentAdjustment>;
              await transformPercentAdjustment(newSettingsDraft, formDataById, date);
            }
          }
        }
        await transformMedianWindow(newSettingsDraft, rgpDefaults, formDataRaw, date);
        await transformAggressiveness(newSettingsDraft, rgpDefaults, formDataRaw, date);
      }
      removeEmptyObjects(newSettingsDraft.dates);
      const newSettings = finishDraft(newSettingsDraft);
      return { newPricing: newSettings, dates: dateArray };
    } catch (error) {
      console.error(error);
      return { newPricing: null, dates: [] };
    }
  }

  return { transformData };
}

export const useBulkRemoveTransformData = () => {
  const { pricingSettings } = usePricingSettings();
  const { pmsMinStayConfig } = usePmsProvider();
  const transformData = useCallback(
    async (formDataRaw: Partial<BulkRemoveSchema>) => {
      try {
        const propertyLevel = [ADJUSTMENT_DB.AGGRESSIVENESS_KEY, ADJUSTMENT_DB.MEDIAN_KEY];
        const roomLevel = [
          ADJUSTMENT_DB.PERCENT_KEY,
          ADJUSTMENT_DB.MIN_PRICE_KEY,
          ADJUSTMENT_DB.MAX_PRICE_KEY,
          ADJUSTMENT_DB.DERIVATION_KEY,
          ADJUSTMENT_DB.FIX_PRICES_KEY,
          ADJUSTMENT_DB.MIN_STAY_KEY,
          ADJUSTMENT_DB.IS_SKIP_MIN_STAY_RULE,
          ADJUSTMENT_DB.CLOSED_KEY,
          ADJUSTMENT_DB.OCCUPANCY_KEY
        ];
        const pricingSettingsDates = result(pricingSettings, 'dates', {});
        const dateArray = generateDateRange(formDataRaw?.dateRange, formDataRaw.weekDays);
        const adjustmentsToRemove: AdjustmentSchemaKeys[] = flatMap(
          filter(formDataRaw.adjustments, 'value'),
          'meta'
        );
        const newSettings: PricingDatesSchema = produce(
          pricingSettingsDates,
          (draft: PricingDatesSchema) => {
            map(dateArray as string[], (rawDate: string) => {
              const date = dayjs(rawDate).startOf('day').format(API_DATE_FORMAT);
              map(adjustmentsToRemove, (adjustment: AdjustmentSchemaKeys) => {
                if (
                  propertyLevel.includes(adjustment as ADJUSTMENT_DB) &&
                  !isNil(current(draft)[date])
                ) {
                  delete draft[date][adjustment];
                }
                if (roomLevel.includes(adjustment as ADJUSTMENT_DB)) {
                  map(current(draft)[date], (_, roomId) => {
                    if (isObject(current(draft)[date][roomId])) {
                      if ((adjustment as ADJUSTMENT_DB) === ADJUSTMENT_DB.MIN_STAY_KEY) {
                        draft[date][roomId][adjustment] =
                          pmsMinStayConfig?.min_stay_remove_value ?? 0;
                      } else {
                        delete draft[date][roomId][adjustment];
                      }
                    }
                  });
                }
              });
            });
          }
        );

        const newPricing = produce(pricingSettings, (draft: PricingSettings) => {
          draft.dates = newSettings;
        });

        const refinedPricing = produce(newPricing, (draft: PricingSettings) => {
          removeEmptyObjects(draft.dates);
        });

        return { newPricing: refinedPricing, dates: dateArray };
      } catch (error) {
        console.log(JSON.stringify(error, null, 2));
        return { newPricing: null, dates: [] };
      }
    },
    [pricingSettings]
  );
  return { transformData };
};
