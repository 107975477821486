import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { TabBar } from '@common/components/molecules/TabsBar';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  schema,
  schemaWithoutMinMax,
  schemaWithoutMinMaxAndOccupancyPricing,
  schemaWithoutOccupancyPricing
} from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import {
  FormContent,
  FormFooter,
  FormHeader,
  FormWrapper
} from '@pages/Client/PricingStrategy/RoomSetup/components/FormLayout';
import {
  saveHotelPricePerRate,
  saveHotelPricePerOccupancy,
  addHotelRoom,
  savePricingSettings
} from '@common/api/hotel';
import { HotelPricePerRateRequest } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useTranslation } from 'react-i18next';
import { useFeaturesStore } from '@common/store/features';
import { useWarnings } from '@common/store/warnings';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import PMSMapping from '@pages/Client/PricingStrategy/RoomSetup/components/PmsMapping';
import PricingSetup from '@pages/Client/PricingStrategy/RoomSetup/components/PricingSetupNewRoom';
import OccupancyPricing from '@pages/Client/PricingStrategy/RoomSetup/components/OccupancyPricing';
import DerivedRates from '@pages/Client/PricingStrategy/RoomSetup/components/DerivedRates';
import { HotelQueryKeys, PricingQueryKeys, RoomsQueryKeys } from '@common/types/query-keys';

enum Tabs {
  PMSMapping,
  PricingSetup,
  OccupancyPricing,
  DerivedRates
}

export const AddNewRoom: React.FC<{
  defaults: Partial<z.infer<typeof schema>>;
  isOccupancyBasedPricing: boolean;
  isDerivedRates: boolean;
  roomInPmsOptions: { label: string; value: number }[] | undefined;
  baseRateInPmsOptions: (pmsRoom: number) => { label: string; value: number }[] | undefined;
  roomOccupancyPricingOptions: (pmsRoom: number) => { label: string; value: number }[] | undefined;
  referenceRoomDefaultMin: number | undefined;
  referenceRoomDefaultMax: number | undefined;
  referenceRoomBasePrice: number | undefined;
  onDrawerClose: () => void;
}> = ({
  defaults,
  isOccupancyBasedPricing,
  isDerivedRates,
  roomInPmsOptions,
  baseRateInPmsOptions,
  roomOccupancyPricingOptions,
  referenceRoomDefaultMin,
  referenceRoomDefaultMax,
  referenceRoomBasePrice,
  onDrawerClose
}) => {
  const [activeTab, setActiveTab] = useState(Tabs.PMSMapping);
  const { createWarning } = useWarnings();
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const { features } = useFeaturesStore();
  const { addNotification } = useNotificationsStore();
  const { isApartment, isAbsoluteAdjustmentToReferenceRoom } = useRoomSetupStore();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { pmsList } = useGetHotelPmsList();
  const getConnectedPms = pmsList?.find((item) => item.primary);
  const isIntegrationV2 = getConnectedPms?.use_v2_integration;
  const {
    pricingSettings,
    pricingSettingsQuery: { refetch: refetchPricingSettings }
  } = usePricingSettings();
  const { hotelDetails } = useHotelDetails();
  const { hotelPmsDataMap } = useHotelPmsDataMap();
  const { mutateAsync: saveDerivedRates, isPending: isSaveDerivedRates } = useMutation({
    mutationKey: [PricingQueryKeys.SAVE_DERIVED_RATES],
    mutationFn: saveHotelPricePerRate
  });
  const { mutateAsync: updatePricingSettingsMutation, isPending: isUpdatePricingSettings } =
    useMutation({
      mutationKey: [PricingQueryKeys.UPDATE_PRICING_SETTINGS],
      mutationFn: savePricingSettings,
      onSuccess: () => {
        setIsFormSuccess(true);
        setTimeout(() => {
          setIsFormSuccess(false);
        }, 2000);
        queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
        queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_ROOMS_LIST] });
        queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_DATA_MAP] });
        queryClient.invalidateQueries({
          queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_RATE, defaults.pmsMapping?.roomInPms]
        });
        onDrawerClose();
        addNotification('success', 'Successfully added room');
      }
    });
  const { mutateAsync: addHotelRoomMutation, isPending: isAddHotelRoom } = useMutation({
    mutationKey: [RoomsQueryKeys.ADD_HOTEL_ROOM],
    mutationFn: addHotelRoom,

    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });

      const { pmsMapping, occupancyPricing, pricingSetup } = methods.getValues();

      if (occupancyPricing && occupancyPricing.occupancyDerivation) {
        await saveHotelPricePerOccupancyMutation({
          id: data.id,
          data: Object.values(occupancyPricing.occupancyDerivation)
        });
      }

      queryClient.invalidateQueries({
        queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_OCCUPANCY, data.id]
      });

      await updatePricingSettingsMutation(
        JSON.stringify({
          ...pricingSettings,
          hotel: {
            ...pricingSettings?.hotel,
            min_price: 0
          },
          rooms: {
            ...pricingSettings?.rooms,
            derived: {
              ...pricingSettings?.rooms?.derived,
              [data.id]: {
                name: pmsMapping.roomName || '',
                number_of_rooms: +(pmsMapping.numberOfRooms || 0),
                virtual_room_type: pmsMapping.virtualRoomType || false,
                number_of_beds_per_physical_room: pmsMapping.numberOfBedsPerPhysicalRoom
                  ? pmsMapping.virtualRoomType
                    ? +pmsMapping.numberOfBedsPerPhysicalRoom
                    : undefined
                  : undefined,
                variable_cost_per_room: 0
              }
            }
          },
          default: {
            ...pricingSettings?.default,
            [data.id]: {
              ...pricingSettings?.default?.[data.id],
              is_orphan_gap_correction_enabled: false,
              avg_price: +(pricingSetup.basePrice || 0),
              max_price: features?.includes(6)
                ? +(pricingSetup.defaultMaxPrice || 0)
                : pricingSettings?.default?.[data.id]?.max_price ?? undefined,
              min_price: features?.includes(6)
                ? +(pricingSetup.defaultMinPrice || 0)
                : pricingSettings?.default?.[data.id]?.min_price ?? undefined,
              adjustment_to_reference_room: +(pricingSetup.derivationFromReferenceRoom || 0)
            }
          }
        })
      );
    }
  });
  const {
    mutateAsync: saveHotelPricePerOccupancyMutation,
    isPending: isSaveHotelPricePerOccupancy
  } = useMutation({
    mutationKey: [PricingQueryKeys.SAVE_HOTEL_PRICE_PER_OCCUPANCY],
    mutationFn: saveHotelPricePerOccupancy
  });

  const hasMinMaxFeatures = features?.includes(6);

  let addNewRoomSchema;
  // Set Schema Specific to Occupancy Based Pricing
  if (hasMinMaxFeatures) {
    if (isOccupancyBasedPricing) {
      addNewRoomSchema = schema;
    } else {
      addNewRoomSchema = schemaWithoutOccupancyPricing;
    }
  } else {
    if (isOccupancyBasedPricing) {
      addNewRoomSchema = schemaWithoutMinMax;
    } else {
      addNewRoomSchema = schemaWithoutMinMaxAndOccupancyPricing;
    }
  }

  const methods = useForm<z.infer<typeof schema>>({
    mode: 'onSubmit',
    defaultValues: {
      pmsMapping: {
        roomInPms: null,
        baseRateInPms: null,
        roomName: '',
        priceType: 'unit_based',
        roomOccupancyForPricing: null,
        numberOfRooms: 0,
        virtualRoomType: false,
        numberOfBedsPerPhysicalRoom: undefined
      },
      pricingSetup: {
        basePrice: defaults.pricingSetup?.basePrice || 0,
        defaultMinPrice: defaults.pricingSetup?.defaultMinPrice || 0,
        defaultMaxPrice: defaults.pricingSetup?.defaultMaxPrice || 0,
        derivationFromReferenceRoom: defaults.pricingSetup?.derivationFromReferenceRoom || 0,
        dontCountUploads: false
      },
      derivedRates: []
    },
    resolver: zodResolver(addNewRoomSchema)
  });

  const CurrentTabContentComponent = useMemo(() => {
    switch (activeTab) {
      case Tabs.PMSMapping:
        return PMSMapping;
      case Tabs.PricingSetup:
        return PricingSetup;
      case Tabs.OccupancyPricing:
        return OccupancyPricing;
      case Tabs.DerivedRates:
        return DerivedRates;
      default:
        return null;
    }
  }, [activeTab]);

  const handleDerivedMinMaxCalculation = () => {
    const { pricingSetup } = methods.getValues();
    const { derivationFromReferenceRoom, basePrice } = pricingSetup;
    if (
      !derivationFromReferenceRoom ||
      !referenceRoomDefaultMin ||
      !referenceRoomDefaultMax ||
      !basePrice
    )
      return;

    const minPercentage = 0.7; // 70%
    const maxPercentage = 2.0; // 200%

    // Calculate minimum and maximum prices
    let calculatedMinPrice = isAbsoluteAdjustmentToReferenceRoom
      ? +referenceRoomDefaultMin + +derivationFromReferenceRoom
      : +referenceRoomDefaultMin * (1 + +derivationFromReferenceRoom / 100);
    let calculatedMaxPrice = isAbsoluteAdjustmentToReferenceRoom
      ? +referenceRoomDefaultMax + +derivationFromReferenceRoom
      : +referenceRoomDefaultMax * (1 + +derivationFromReferenceRoom / 100);

    // Adjust minimum price if below 70% of the base price
    calculatedMinPrice = Math.max(calculatedMinPrice, basePrice * minPercentage);

    // Adjust maximum price if below 200% of the base price
    calculatedMaxPrice = Math.max(calculatedMaxPrice, basePrice * maxPercentage);

    // Set the values in the form
    methods.setValue('pricingSetup.defaultMinPrice', Math.floor(calculatedMinPrice));
    methods.setValue('pricingSetup.defaultMaxPrice', Math.floor(calculatedMaxPrice));
  };

  const { pmsMapping, pricingSetup, occupancyPricing, derivedRates } = methods.getValues();
  const maxOccupancy =
    hotelPmsDataMap?.mapped_data.find((item) => +item.id === pmsMapping.roomInPms)?.max_occupancy ||
    0;

  // Set Default Occupancy as maxOccupancy when hotel have Occupancy Based Pricing
  useEffect(() => {
    if (isOccupancyBasedPricing) {
      methods.setValue('occupancyPricing.defaultOccupancy', maxOccupancy);
    }
  }, [maxOccupancy, isOccupancyBasedPricing]);

  // occupancyPricing.defaultOccupancy
  const handleSubmit = async () => {
    const derivedRatesRequest: HotelPricePerRateRequest[] =
      derivedRates
        ?.map((rate) => ({
          id: rate.id,
          derivation: rate.derivation || 0,
          is_absolute: !rate.percentDerivation || false,
          is_product_derivation: rate.productDerivation || false,
          pms_rate: rate.derivedRateInPms || null,
          hotel: hotelDetails?.id || 0,
          pms_room: defaults.pmsMapping?.roomInPms || null
        }))
        .filter((item) => item.pms_rate !== null) || [];

    const submitFn = async () => {
      if (derivedRates && pmsMapping.roomInPms) {
        await saveDerivedRates({ id: pmsMapping?.roomInPms, data: derivedRatesRequest });
      }

      await addHotelRoomMutation({
        data: {
          name: pmsMapping.roomName || '',
          max_occupancy: maxOccupancy,
          default_occupancy: isOccupancyBasedPricing
            ? occupancyPricing?.defaultOccupancy || maxOccupancy
            : 0,
          configurations: isIntegrationV2
            ? {
                price_type: pmsMapping.priceType || 'unit_based'
              }
            : {},
          extra: !isIntegrationV2
            ? pmsMapping.priceType
            : hotelPmsDataMap?.mapped_data.find((value) => value.id === pmsMapping.roomInPms)
                ?.extra || null,
          discount_per_person: occupancyPricing?.additionalAdultPrice ?? 0,
          pms_price_applicable_occupancy: pmsMapping.roomOccupancyForPricing || null,
          ignore_upload_count: pricingSetup.dontCountUploads || false,
          pms_rate:
            hotelPmsDataMap?.mapped_data
              .find((value) => value.id === pmsMapping.roomInPms)
              ?.rates.find((rate) => rate.id === pmsMapping.baseRateInPms)?.id || '',
          pms_room: pmsMapping.roomInPms || null
        }
      });

      await refetchPricingSettings();
    };

    const warningsToProcess: Promise<void>[] = [];

    const addWarningIfNeeded = ({
      condition,
      warningMessage,
      confirmationMessage,
      title
    }: {
      condition: boolean;
      warningMessage: string;
      confirmationMessage: string;
      title: string;
    }) => {
      if (condition) {
        warningsToProcess.push(
          createWarning({
            message: t(warningMessage),
            ignore: false,
            ignoreLabel: t('Cancel') as string,
            dismissLabel: t(confirmationMessage) as string,
            title: t(title) as string
          })
        );
      }
    };

    if (features?.includes(6)) {
      addWarningIfNeeded({
        condition:
          pricingSetup.defaultMinPrice < (pricingSetup.basePrice || 0) * 0.5 &&
          pricingSetup.defaultMinPrice > (pricingSetup.basePrice || 0) * 0.3,
        warningMessage:
          'This is unusually low for a minimum price - normally we would set this to around 60% of the base price',
        confirmationMessage: 'Confirm',
        title: 'Default Minimum Price'
      });

      addWarningIfNeeded({
        condition: pricingSetup.defaultMinPrice < (pricingSetup.basePrice || 0) * 0.3,
        warningMessage:
          'We would normally recommend not setting a minimum price this much lower than your base price. Are you sure?',
        confirmationMessage: 'Confirm',
        title: 'Default Minimum Price'
      });

      addWarningIfNeeded({
        condition:
          pricingSetup.defaultMinPrice > (pricingSetup.basePrice || 0) * 0.8 &&
          pricingSetup.defaultMinPrice < (pricingSetup.basePrice || 0) * 0.9,
        warningMessage:
          'This is unusually high for a minimum price, compared to your base price. We would recommend going lower to allow more bookings in low-demand times.',
        confirmationMessage: 'Confirm',
        title: 'Default Minimum Price'
      });

      addWarningIfNeeded({
        condition: pricingSetup.defaultMinPrice > (pricingSetup.basePrice || 0) * 0.9,
        warningMessage:
          'We would strongly recommend a lower minimum price when you have this base price set. Are you sure?',
        confirmationMessage: 'Confirm',
        title: 'Default Minimum Price'
      });

      addWarningIfNeeded({
        condition: pricingSetup.defaultMaxPrice < (pricingSetup.basePrice || 0) * 1.2,
        warningMessage:
          'We would strongly recommend a higher maximum price when you have this base price set. Are you sure?',
        confirmationMessage: 'Confirm',
        title: 'Default Maximum Price'
      });

      addWarningIfNeeded({
        condition:
          pricingSetup.defaultMaxPrice < (pricingSetup.basePrice || 0) * 1.4 &&
          pricingSetup.defaultMaxPrice >= (pricingSetup.basePrice || 0) * 1.2,
        warningMessage:
          'This is unusually low for a maximum price, compared to your base price. We would recommend going higher to allow more profit in busy times.',
        confirmationMessage: 'Confirm',
        title: 'Default Maximum Price'
      });

      addWarningIfNeeded({
        condition: pricingSetup.defaultMaxPrice > (pricingSetup.basePrice || 0) * 10,
        warningMessage:
          'This is unusually high for a maximum price and may lead to unhappy clients. Could you try a lower maximum but put a Min Stay restriction in instead?',
        confirmationMessage: 'Confirm',
        title: 'Default Maximum Price'
      });
    }

    const processWarnings = async () => {
      if (warningsToProcess.length > 0) {
        try {
          await warningsToProcess.shift();
          processWarnings();
        } catch (error) {
          console.log('User cancelled warning');
        }
      } else {
        submitFn();
      }
    };

    await processWarnings();
  };

  return (
    <FormProvider {...methods}>
      <form
        key="add-new-room"
        className="relative h-full"
        onSubmit={methods.handleSubmit(handleSubmit)}>
        <FormWrapper>
          <FormHeader>
            <Typography variant="h5" element="h3" color="darkGrey">
              {t(`Add New ${isApartment ? 'Apartment' : 'Room'}`)}
            </Typography>

            <div className="mt-3">
              <TabBar
                onTabClick={setActiveTab}
                options={[
                  {
                    label: hotelDetails?.is_channel_manager
                      ? t('Channel Manager Mapping')
                      : t(`PMS Mapping`),
                    value: Tabs.PMSMapping
                  },
                  { label: t('Pricing Setup'), value: Tabs.PricingSetup },
                  ...(isOccupancyBasedPricing ||
                  methods.watch('pmsMapping.priceType') === 'occupancy_based'
                    ? [{ label: t('Occupancy Pricing'), value: Tabs.OccupancyPricing }]
                    : []),
                  ...(isDerivedRates
                    ? [{ label: t('Derived Rates'), value: Tabs.DerivedRates }]
                    : [])
                ]}
                activeTab={activeTab}
              />
            </div>
          </FormHeader>

          <FormContent>
            {CurrentTabContentComponent && (
              <CurrentTabContentComponent
                roomInPmsOptions={roomInPmsOptions || []}
                baseRateInPmsOptions={
                  baseRateInPmsOptions(methods.watch('pmsMapping.roomInPms') || 0) || []
                }
                referenceRoomBasePrice={referenceRoomBasePrice}
                roomOccupancyPricingOptions={
                  roomOccupancyPricingOptions(methods.watch('pmsMapping.roomInPms') || 0) || []
                }
              />
            )}
          </FormContent>

          <FormFooter>
            <Button intent="text" className="text-darkGrey">
              {t('Cancel')}
            </Button>
            {activeTab === Tabs.PricingSetup ? (
              <Button
                intent="outline"
                type="button"
                onClick={() => handleDerivedMinMaxCalculation()}>
                {t('Derived Min/Max')}
              </Button>
            ) : null}
            <Button
              intent="primary"
              type="submit"
              isLoading={
                isAddHotelRoom ||
                isSaveDerivedRates ||
                isUpdatePricingSettings ||
                isSaveHotelPricePerOccupancy
              }
              isSuccess={isFormSuccess}>
              {t('Save')}
            </Button>
          </FormFooter>
        </FormWrapper>
      </form>
    </FormProvider>
  );
};
