import { useEffect, useMemo } from 'react';
import { Icon } from '@common/components/foundations/icons';
import { KPILarge, KPILargeExtraOverviewContent, KPISmall } from '@common/components/molecules/KPI';
import { API_DATE_FORMAT } from '@common/constants/date';
import { Button } from '@common/components/atoms/Button';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { NotesSection } from '@pages/Client/Calendar/components/PriceDrawerContent/Overview/NotesSection';
import { DrawerTabProps } from '@pages/Client/Calendar/components/PriceDrawerContent/types';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { EventSection } from '@pages/Client/Calendar/components/PriceDrawerContent/Overview/EventSection';
import { usePerformanceDashboardReservationKPI } from '@pages/Client/Dashboard/hooks/usePerformanceDashboardReservationKPI';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Feature, useFeaturesStore } from '@common/store/features';
import { usePriceKpiContent } from '@pages/Client/Calendar/hooks/usePriceKpiContent';
import { useOccupancyKpiContent } from '@pages/Client/Calendar/hooks/useOccupancyKpiContent';
import { useTranslation } from 'react-i18next';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { getADRKey, getRevenueKey } from '@pages/Client/Calendar/utils/lookupKeys';
import { isUTCYesterdayInUserTZ } from '@common/utils/isUTCYesterdayInUserTZ';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { Tabs, usePriceDrawerStore } from '@common/store/priceDrawer';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import dayjs from 'dayjs';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { Dropdown } from '@common/components/molecules/Dropdown/Dropdown';
import { truncate } from 'lodash-es';
import { useDisclosure } from '@mantine/hooks';
import { PriceDecayChartModal } from '@pages/Client/PricingStrategy/SurgeProtection/components/PriceDecayChartModal';
import {
  Box,
  Flex,
  Group,
  Stack,
  Text,
  Title,
  Tooltip,
  rem,
  ActionIcon,
  SimpleGrid
} from '@mantine/core';
import { IconTrendingDown2 } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { priceTrendStatus } from '@pages/Client/Calendar/utils/priceTrendStatus';
import { getOccupancyColor } from '@pages/Client/Calendar/pages/Occupancy/utils/getOccupancyColor';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';
import { useUploadPMSPriceModalState } from '@pages/Client/Calendar/components/BulkEdit/store/useUploadPMSPriceModalState';
import { useViewStore } from '@common/store/view';

export const Overview = ({ currentViewingDate, drawerProps }: DrawerTabProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pricingSettings, roomPrices } = useRoomPrices();
  const { features } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();
  const { hasUploadPriceButton, hasNoUploadPricesButton } = useGetDetailProviderConfig();
  const { getSurgeEventByDay, handleDismissSurgeEvent } = useSurgeEvents();
  const { isReadOnly } = useViewStore();

  const [decayChartOpened, { open: decayChartOpen, close: decayChartClose }] = useDisclosure(false);

  const isHideUploadPricesButton =
    !hasUploadPriceButton ||
    isUTCYesterdayInUserTZ(currentViewingDate.format(API_DATE_FORMAT)) ||
    hasNoUploadPricesButton ||
    isReadOnly;

  const { setModalState } = useUploadPMSPriceModalState();

  const {
    performanceDashboardReservationKPI: { getKPITableDailyByMonth }
  } = usePerformanceDashboardReservationKPI();
  const { selectedHotelRoomId, setSelectedHotelRoomId } = useCalendarPageStore();
  const { defaultRoomId } = usePriceDrawerStore();
  const { selectedHotelRoom, sortedHotelRooms } = useHotelRoomsList();
  const { setActiveTab } = usePriceDrawerStore();
  const { performanceFilter } = useDashboardPageStore();

  useEffect(() => {
    if (!defaultRoomId || selectedHotelRoomId) return;
    setSelectedHotelRoomId(defaultRoomId);
  }, []);

  const key = currentViewingDate.format(API_DATE_FORMAT);

  const { cachedPriceData, propertyData } = useMemo(() => {
    if (!selectedHotelRoomId) return {};

    return {
      cachedPriceData: roomPrices?.prices.data?.[key]?.[selectedHotelRoomId],
      propertyData: roomPrices?.prices.data?.[key]?.property
    };
  }, [selectedHotelRoomId, currentViewingDate, roomPrices]);

  const { fixPrice, isFixPrice } = useMemo(() => {
    if (!selectedHotelRoomId) return {};

    return {
      fixPrice: Math.round(cachedPriceData?.price ?? 0),
      isFixPrice: !!pricingSettings?.dates?.[key]?.[selectedHotelRoomId]?.fix_price
    };
  }, [cachedPriceData, pricingSettings, key, selectedHotelRoomId]);

  const { getSurgePriceForDay } = useSurgePrice();

  const { surgePrice, isSurgePrice } = getSurgePriceForDay(key, selectedHotelRoomId);

  const priceKpiContent = usePriceKpiContent(key, {
    onYourAdjustmentsNavigate: () => setActiveTab(Tabs.EditPrices, true)
  });

  const occupancyKpiContent = useOccupancyKpiContent(key);

  const extraOverviewContent = useMemo(() => {
    const content: KPILargeExtraOverviewContent[] = [];

    if (isSurgePrice || isFixPrice) {
      content.push({
        title: CurrencyFormatter.format(cachedPriceData?.suggested_price, '-'),
        subtitle: t('Recommended Price')
      });

      return content;
    }
  }, [fixPrice, pricingSettings, currentViewingDate]);

  const priceKpiTitleData = useMemo(() => {
    if (features?.includes(Feature.BarLevels)) {
      return {
        title: CurrencyFormatter.format(
          isSurgePrice ? surgePrice : cachedPriceData?.suggested_price
        ),
        subtitle: isSurgePrice ? t('Protection Price') : t('Recommended BAR Level')
      };
    }

    return {
      title: CurrencyFormatter.format(
        isSurgePrice ? surgePrice : isFixPrice ? fixPrice : cachedPriceData?.price
      ),
      subtitle: isSurgePrice
        ? t('Protection Price')
        : isFixPrice
          ? t('Fix Price')
          : t('Recommended Price')
    };
  }, [pricingSettings, cachedPriceData]);

  function handleModal() {
    setModalState(true);
  }

  const surgeEvent = getSurgeEventByDay(currentViewingDate.toDate());
  const isSurgeEvent = surgeEvent?.active;
  const hasDecayStep = selectedHotelRoomId
    ? (surgeEvent?.configurations?.decay_step?.[selectedHotelRoomId] ?? 0) > 0
    : false;

  const basePrice = selectedHotelRoomId
    ? pricingSettings?.default?.[selectedHotelRoomId]?.avg_price ?? 0
    : 0;

  const decayStep = selectedHotelRoomId
    ? surgeEvent?.configurations?.decay_step?.[selectedHotelRoomId] ?? 0
    : 0;
  const initialFixPrice = selectedHotelRoomId
    ? surgeEvent?.configurations?.fix_price?.[selectedHotelRoomId] ?? 0
    : 0;
  const surgeEventDecaySpeed = Math.round((initialFixPrice - basePrice) / decayStep);

  const handleNavigateToSurgeSettings = () => {
    navigate(`/client/surge-event/${hotelDetails?.id}/price-settings`);
    drawerProps.onClose?.();
  };

  const rawPrice = (isSurgePrice && surgePrice ? surgePrice : cachedPriceData?.price) ?? 0;
  const priceChange = ((rawPrice - (cachedPriceData?.original_price ?? 0)) / rawPrice) * 100;

  const trending = priceTrendStatus({
    isLocked: isFixPrice || isSurgePrice,
    priceChange,
    rawPrice
  });

  const IconComponent = trending
    ? trending === 'down'
      ? Icon.TrendingDown
      : trending === 'lock'
        ? Icon.Lock
        : Icon.TrendingUp
    : Icon.AllDone;

  const syncedWithPMSTooltipMessage = t('Synced with {{pmsOrCm}}', {
    pmsOrCm: hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
  });

  const trendingTooltip = trending
    ? trending === 'down'
      ? t('Lower than Price in {{pmsOrCm}}', {
          pmsOrCm: hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
        })
      : trending === 'up'
        ? t('Higher than Price in {{pmsOrCm}}', {
            pmsOrCm: hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
          })
        : trending === 'lock'
          ? t('Fixed Price Adjustment applied')
          : syncedWithPMSTooltipMessage
    : syncedWithPMSTooltipMessage;

  return (
    <Stack gap="xl">
      <Stack pb={rem(64)} gap="lg">
        <Box w="100%">
          <Stack gap="md">
            <Flex
              direction={{
                xs: 'column',
                sm: 'row'
              }}
              align="flex-start"
              gap="lg"
            >
              <Stack
                gap="xs"
                w={{
                  xs: '100%',
                  sm: '60%'
                }}
              >
                <Box w="fit-content">
                  <Dropdown
                    options={sortedHotelRooms.map((room) => ({
                      label: room.name,
                      value: room.id
                    }))}
                    onOptionClick={(option) => {
                      setSelectedHotelRoomId(option.value);
                    }}
                    selectedOption={
                      selectedHotelRoom
                        ? {
                            label: truncate(selectedHotelRoom.name, { length: 30 }),
                            value: selectedHotelRoom.id
                          }
                        : undefined
                    }
                  />
                </Box>

                <KPILarge
                  isOpen
                  collapsible={false}
                  headerIconColor={
                    isSurgeEvent
                      ? 'darkGreen'
                      : trending === 'down'
                        ? 'uiRed'
                        : trending === 'up'
                          ? 'uiGreen'
                          : trending === 'lock'
                            ? 'darkGrey'
                            : 'darkGreen'
                  }
                  headerIcon={
                    <Tooltip offset={10} label={trendingTooltip}>
                      <span>
                        {isSurgeEvent ? (
                          <Icon.Ripple className="h-8 w-8 text-white" />
                        ) : IconComponent ? (
                          <IconComponent className="text-white" />
                        ) : null}
                      </span>
                    </Tooltip>
                  }
                  headerContent={
                    <Stack gap="xs">
                      <Box>
                        {isSurgeEvent ? (
                          <Flex direction="column" align="flex-start">
                            <Text fw="bold" size="xs">
                              {t('Surge Event')}: {surgeEvent?.reservation_count}{' '}
                              {t('reservations in 24 hours')}
                            </Text>
                          </Flex>
                        ) : null}
                        <Group align="center" justify="space-between">
                          {extraOverviewContent ? (
                            <Group>
                              {extraOverviewContent.map((content) => (
                                <Stack gap={1} key={content.title}>
                                  <Title size={rem(20)} fw="normal" td="line-through">
                                    {content.title}
                                  </Title>
                                  <Text size="xs">{content.subtitle}</Text>
                                </Stack>
                              ))}
                            </Group>
                          ) : null}

                          <Stack gap={1}>
                            <Group gap="xs">
                              <Title size={rem(20)} fw="normal">
                                {priceKpiTitleData.title}{' '}
                              </Title>
                              {isSurgePrice && hasDecayStep ? (
                                <Tooltip
                                  label={t('Protection Price Decay over {{durationInDays}} days', {
                                    durationInDays: surgeEventDecaySpeed
                                  })}
                                >
                                  <ActionIcon size="xs" onClick={decayChartOpen}>
                                    <IconTrendingDown2 className="h-3 w-3" />
                                  </ActionIcon>
                                </Tooltip>
                              ) : null}
                              {isSurgePrice && !hasDecayStep ? (
                                <Tooltip
                                  label={
                                    <Flex direction="column" py={rem(3)}>
                                      <Text size="xs" c="white">
                                        {t('Protection Price expiring in {{expiryDays}} days', {
                                          expiryDays: dayjs(surgeEvent?.expiry_datetime).diff(
                                            dayjs().startOf('day'),
                                            'days'
                                          )
                                        })}
                                      </Text>
                                      <Text size="xxs" c="gray">
                                        {t('Click to view Protection Price settings')}
                                      </Text>
                                    </Flex>
                                  }
                                >
                                  <ActionIcon size="xs" onClick={handleNavigateToSurgeSettings}>
                                    <Icon.Timer40s className="h-3 w-3" />
                                  </ActionIcon>
                                </Tooltip>
                              ) : null}
                            </Group>
                            <Text size="xs">{priceKpiTitleData.subtitle}</Text>
                          </Stack>
                        </Group>
                      </Box>

                      <Group justify="flex-end">
                        {isSurgePrice && isSurgeEvent && !isReadOnly ? (
                          <Flex direction="column" align="flex-end">
                            <Tooltip
                              label={t(
                                'The price will be removed and the alert for this date will be deactivated.'
                              )}
                            >
                              <Button
                                onClick={() =>
                                  handleDismissSurgeEvent(surgeEvent?.id, currentViewingDate)
                                }
                                type="button"
                              >
                                {t('Remove Protection Price')}{' '}
                                {hasDecayStep ? <>& {t('Decay')}</> : null}
                              </Button>
                            </Tooltip>
                          </Flex>
                        ) : null}
                        {isSurgeEvent && !isSurgePrice && !isReadOnly ? (
                          <Tooltip
                            position="bottom"
                            openDelay={75}
                            label={t('The Surge Event alert for this date will be deactivated.')}
                          >
                            <Button
                              onClick={() =>
                                handleDismissSurgeEvent(surgeEvent?.id, currentViewingDate)
                              }
                              type="button"
                            >
                              {t('Dismiss Surge Event')}
                            </Button>
                          </Tooltip>
                        ) : null}
                      </Group>
                    </Stack>
                  }
                  content={priceKpiContent}
                />
              </Stack>

              <Stack
                gap="xs"
                w={{
                  xs: '100%',
                  sm: '40%'
                }}
              >
                <Box p={rem(7.5)}>
                  <Text size="sm" c="dark">
                    {t('Property')}
                  </Text>
                </Box>
                <Stack w="100%">
                  {occupancyKpiContent ? (
                    <KPILarge
                      headerIconColor={
                        getOccupancyColor(currentViewingDate.toDate(), roomPrices).color
                      }
                      headerIcon={<Icon.Bed className="text-white" />}
                      title={formattedPercentage(propertyData?.occupancy)}
                      subtitle={t('Occupancy') as string}
                      content={occupancyKpiContent}
                    />
                  ) : null}
                  <SimpleGrid cols={2}>
                    <KPISmall
                      title={propertyData?.inventory_remaining ?? '-'}
                      subtitle={t(`${hotelDetails?.room_apartment_space_name}s Left`)}
                    />

                    <KPISmall
                      title={roomPrices?.prices.data?.[key]?.property?.closed_rooms ?? '-'}
                      subtitle={t(`${hotelDetails?.room_apartment_space_name}s Closed`)}
                    />
                  </SimpleGrid>
                  {/* Limited Dashboard */}
                  {features?.includes(20) ? (
                    <SimpleGrid cols={2}>
                      <KPISmall
                        title={CurrencyFormatter.format(
                          Math.round(
                            getKPITableDailyByMonth(
                              'total',
                              getADRKey(performanceFilter),
                              currentViewingDate.format(API_DATE_FORMAT)
                            )
                          )
                        )}
                        subtitle={t('ADR')}
                      />

                      <KPISmall
                        title={CurrencyFormatter.format(
                          Math.round(
                            getKPITableDailyByMonth(
                              'total',
                              getRevenueKey(performanceFilter),
                              currentViewingDate.format(API_DATE_FORMAT)
                            )
                          )
                        )}
                        subtitle={t('Total Revenue')}
                      />
                    </SimpleGrid>
                  ) : null}
                </Stack>
              </Stack>
            </Flex>
          </Stack>
        </Box>
        <SimpleGrid
          cols={{
            xs: 1,
            md: 2
          }}
          spacing="lg"
        >
          <Box>
            <EventSection day={currentViewingDate} />
          </Box>
          {isReadOnly ? null : (
            <Box>
              <NotesSection day={currentViewingDate} />
            </Box>
          )}
        </SimpleGrid>
      </Stack>

      <Box
        pos="absolute"
        bottom={0}
        right={0}
        w="100%"
        p="lg"
        ta="right"
        className="rounded-bl-xl border-t border-grey-reduced bg-lightGrey"
      >
        <Flex justify="flex-end" gap="sm">
          <Button intent="text" className="text-darkGrey" onClick={() => drawerProps.onClose?.()}>
            {t('Cancel')}
          </Button>
          {isHideUploadPricesButton ? null : (
            <Button intent="primary" onClick={handleModal}>
              {t('Upload Prices')}
            </Button>
          )}
        </Flex>
      </Box>

      <PriceDecayChartModal
        opened={decayChartOpened}
        onClose={decayChartClose}
        surgeEvent={surgeEvent}
        selectedRoomId={selectedHotelRoomId}
      />
    </Stack>
  );
};
