import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLilius } from 'use-lilius';
import { useBanners } from '@common/store/banner';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { DashboardHeader } from '@pages/Client/Dashboard/components/DashboardHeader';
import { Page } from '@common/components/organisms/Page';
import { SetBudgetForm } from '@pages/Client/Dashboard/components/SetBudgetForm';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flags } from '@common/constants/flags';
import { useFeaturesStore } from '@common/store/features';

type DashboardType = 'performance' | 'analytics' | 'market';

const typeMapping: Record<string, DashboardType | undefined> = {
  performance: 'performance',
  analytics: 'analytics',
  market: 'market'
};

function getType(param: string | undefined): DashboardType | undefined {
  if (!param) return undefined;
  return typeMapping[param];
}

export function DashboardLayout() {
  const captureRef = useRef<HTMLDivElement>(null);
  const match = useMatch('/client/dashboard/:id/*');
  const paramsMatch = match?.params['*'];
  const { id } = useParams();
  const navigate = useNavigate();
  const { createBanner, removeBanner } = useBanners();
  const { pmsProviderId } = usePmsProvider();
  const { t } = useTranslation();
  const flags = useFlags();
  const { features, hasDashboardFeature, hasMarketInsightsFeature } = useFeaturesStore();

  const liliusInstance = useLilius();
  const { setLiliusInstance } = useDashboardPageStore();

  const [showSetBudget, setShowSetBudget] = useState(false);

  useEffect(() => {
    const pmsIssueExists = [PmsProvider.CLOUDBEDS];
    const isReportingDashboardV2 = flags[Flags.ReportingDashboardV2];

    // Temporary issue announcement for some PMS, will be removed later
    if (pmsIssueExists.includes(pmsProviderId) && !isReportingDashboardV2) {
      createBanner({
        id: 'issue-announcement',
        description: (
          <div>
            {t(
              'We have identified an issue that may affect the accuracy of some reports for certain clients. Please be advised that the numbers displayed might not reflect the most current data. Our team is actively working on a resolution to ensure the integrity and reliability of our reporting features. We apologize for any inconvenience this may cause and appreciate your patience and understanding.'
            )}{' '}
            <span className="font-bold text-darkGrey">
              {t('Your pricing recommendations are not affected.')}
            </span>{' '}
            {t('For any concerns or immediate assistance, please contact our support team.')}
          </div>
        ),
        type: 'warning',
        showAction: false
      });
      return () => removeBanner('issue-announcement');
    }
  }, [pmsProviderId]);

  /**
   * Sync lilius instance with Zustand store.
   */
  useEffect(() => {
    setLiliusInstance(liliusInstance);
  }, [liliusInstance.viewing]);

  const type = getType(paramsMatch);

  // Redirect to '/client/dashboard/:id/performance' if the type is not valid
  useEffect(() => {
    if (!type) {
      navigate(`/client/dashboard/${id}/performance`, { replace: true });
    }

    if (!hasDashboardFeature() && hasMarketInsightsFeature()) {
      navigate(`/client/dashboard/${id}/market`, { replace: true });
    }
  }, [type, id, navigate, features]);

  if (!type) return null;

  const handleScheduleReportClick = () => {
    navigate(`/client/account/${id}/schedule-reports`);
  };

  const handleOpenSetBudget = () => {
    setShowSetBudget(true);
  };

  const handleCloseSetBudget = () => {
    setShowSetBudget(false);
  };

  const renderHeader = () => {
    return (
      <DashboardHeader
        exportRef={captureRef}
        type={type}
        onScheduleReportClick={handleScheduleReportClick}
        onSetBudgetClick={handleOpenSetBudget}
      />
    );
  };

  return (
    <Page ref={captureRef} header={renderHeader()}>
      <Outlet />
      <SetBudgetForm open={showSetBudget} onClose={handleCloseSetBudget} />
    </Page>
  );
}
