import { createUserByInvitation } from '@common/api/account';
import { InvitationUserDataPayload } from '@common/api/account/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useErrorInviteUserPasswordStore } from '@pages/Auth/InviteUser/store/password';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const useInviteUserByInvitation = () => {
  const { addNotification } = useNotificationsStore();
  const navigate = useNavigate();
  const { setError, setMessage } = useErrorInviteUserPasswordStore();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: ([payload, email_token]: [InvitationUserDataPayload, string]) =>
      createUserByInvitation(email_token, payload),
    onSuccess: () => {
      addNotification('success', 'User created successfully');
      navigate('/login');
    },
    onError: (error: any) => {
      if (error.response.data.password) {
        setError(true);
        setMessage(error.response.data.password.join(','));
      }
      addNotification('fail', 'Failed to create User');
    }
  });

  return {
    createUserByInvitation: mutate,
    isLoading,
    isSuccess
  };
};
