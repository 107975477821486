import dayjs from 'dayjs';
import { RefObject, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { truncate, startCase } from 'lodash-es';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useDownloadCSV } from '@common/hooks/useDownloadCsv';
import { deletePostgresSnowflake, resetPostgresSnowflake } from '@common/api/dashboard';
import { useMutation } from '@tanstack/react-query';
import { usePerformanceDashboardReservationKPI } from '@pages/Client/Dashboard/hooks/usePerformanceDashboardReservationKPI';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { useViewStore } from '@common/store/view';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useUpselling } from '@pages/Client/hooks/useUpselling';
import { useAnalyticsDashboardCache } from '@pages/Client/Dashboard/hooks/useAnalyticsDashboardCache';
import { exportPDF } from '@common/utils/exportPDF';
import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Divider } from '@common/components/atoms/Divider';
import { ExportPDFHeader } from '@common/components/molecules/PDFExportHeader/PDFExportHeader';
import { Badge } from '@common/components/atoms/Badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { SecureNavLink } from '@pages/Client/components/SecureNavLink';
import { AnalyticsFilters } from '@pages/Client/Dashboard/pages/Analytics/components/AnalyticsFilters';
import styles from '@pages/Client/Dashboard/components/DashboardHeader.module.css';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { RemovableBadge } from '@common/components/atoms/RemovableBadge';
import {
  PopoverTrigger,
  Popover,
  PopoverContent
} from '@common/components/molecules/Popoverv2/Popover';
import { useDashboardExportExcel } from '@pages/Client/Dashboard/hooks/useDashboardExportExcel';
import { Center, SegmentedControl } from '@mantine/core';
import { YearlyMonthly } from '@common/types';
import { SnowflakeQueryKeys } from '@common/types/query-keys';
import { useFutureDaily } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useFutureDaily';
import { useAirbnbInsightsCache } from '@pages/Client/Dashboard/pages/Market/hooks/useAirbnbInsightsCache';

export const DashboardHeader = ({
  type,
  exportRef,
  onScheduleReportClick,
  onSetBudgetClick
}: {
  type: 'performance' | 'analytics' | 'market';
  exportRef: RefObject<HTMLDivElement>;
  onScheduleReportClick: () => void;
  onSetBudgetClick: () => void;
}) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { mutateAsync: getPerformanceDashboardReservationKPICsvMutation } = useDownloadCSV(
    '/performancedashboard/reservation-kpi-csv/?date_range=dashboard_kpis_full_date_range',
    'kpi_table_daily'
  );
  const { mutateAsync: resetPostgresSnowflakeMutation } = useMutation({
    mutationKey: [SnowflakeQueryKeys.RESET_POSTGRES_SNOWFLAKE],
    mutationFn: resetPostgresSnowflake
  });
  const { mutateAsync: deletePostgresSnowflakeMutation } = useMutation({
    mutationKey: [SnowflakeQueryKeys.DELETE_POSTGRES_SNOWFLAKE],
    mutationFn: deletePostgresSnowflake
  });
  const {
    performanceDashboardReservationKPI,
    query: { isLoading }
  } = usePerformanceDashboardReservationKPI(true);
  const lastReservationReceived =
    performanceDashboardReservationKPI?.data?.kpi_table_yearly?.last_reservation_received ?? '';
  const { getAllYears } = performanceDashboardReservationKPI;

  const isToday = lastReservationReceived
    ? dayjs().tz().isSame(lastReservationReceived, 'day')
    : false;
  let format = hotelDetails?.prefered_date_format;
  format = format?.replace(/([-./]?Y{2,4})/g, '').trim(); // remove year and possible separator before it
  format = format?.replace(/([-./]Y{2,4})/g, '').trim(); // remove year and possible separator after it
  const formattedTime = lastReservationReceived
    ? dayjs(lastReservationReceived).tz().format(`ddd, ${format}`)
    : '';
  const reservationDate = lastReservationReceived
    ? isToday
      ? `${t('Today')}, ${dayjs(lastReservationReceived).tz().format(format?.split(',')[1])}`
      : formattedTime
    : 'n.A.';

  const { liliusInstance, view, setView, advancedAnalyticsFilters, updateHotelAnalyticsFilters } =
    useDashboardPageStore();
  const { view: clientViewMode, isReadOnly } = useViewStore();
  const { features, hasDashboardFeature } = useFeaturesStore();
  const {
    SparkIcon,
    isProDashboardUpselling,
    isMarketInsightsUpselling,
    isDashboardUpselling,
    setUpsellingModalOpen,
    ProEntryPoints
  } = useUpselling();
  const { isCustomDateRange, customRangeText, getAllYearsFromAnalyticsCache } =
    useAnalyticsDashboardCache();
  const {
    airbnbInsightsCache,
    getAllYears: getAllYearAirbnbInsightsCache,
    query: { isLoading: isAirbnbInsightsLoading }
  } = useAirbnbInsightsCache();
  const { futureDaily, isLoading: isFutureDailyLoading } = useFutureDaily();

  const lastUpdateAt = airbnbInsightsCache?.updated_at ?? '';

  const updateDate = lastUpdateAt
    ? isToday
      ? `${t('Today')}, ${dayjs(lastUpdateAt).tz().format(format?.split(',')[1])}`
      : formattedTime
    : 'n.A.';

  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);
  const [isHovered, setIsHovered] = useState({
    yearlyView: false
  });
  const { exportExcel } = useDashboardExportExcel({ type });

  useEffect(() => {
    if (features?.includes(20)) setView('monthly');
  }, [features]);

  const allYears = () => {
    if (type === 'analytics') return getAllYearsFromAnalyticsCache();
    if (type === 'market') return getAllYearAirbnbInsightsCache();
    return getAllYears();
  };

  const minYear = allYears()[0];

  const viewingDate = liliusInstance?.viewing;
  const viewingYear = viewingDate?.getFullYear();
  const viewingMonth = viewingDate?.getMonth();

  const isPrevMonthDisabled = () => {
    if (viewingYear === undefined || viewingMonth === undefined) return false;

    if (type === 'performance' || type === 'analytics') {
      // For Pro dashboard users, there's no restriction
      if (features?.includes(Feature.ProfessionalDashboard) || clientViewMode === 'admin')
        return false;
    }

    if (type === 'market') {
      // For Pro dashboard users, there's no restriction
      if (features?.includes(Feature.ProMarketInsights) || clientViewMode === 'admin') return false;
    }

    const prevMonth = new Date(viewingYear, viewingMonth - 1);
    const currentMonth = new Date();

    // Calculate the earliest allowed month - which is one month before the current month
    const earliestAllowedMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1);

    // Disable if the previous month is before the earliest allowed month
    return prevMonth < earliestAllowedMonth;
  };

  const isNextMonthDisabled = () => {
    if (viewingYear === undefined || viewingMonth === undefined) return false;

    if (type === 'performance' || type === 'analytics') {
      // For Pro dashboard users, there's no restriction
      if (features?.includes(Feature.ProfessionalDashboard) || clientViewMode === 'admin')
        return false;
    }

    if (type === 'market') {
      // For Pro dashboard users, there's no restriction
      if (features?.includes(Feature.ProMarketInsights) || clientViewMode === 'admin') return false;
    }

    const nextMonth = new Date(viewingYear, viewingMonth + 1);
    const currentMonth = new Date();

    // Calculate the latest allowed month - which is one month after the current month
    const latestAllowedMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1);

    // Disable if the next month is after the latest allowed month
    return nextMonth > latestAllowedMonth;
  };

  const toPreviousMonth = () => {
    if (viewingYear === undefined || viewingMonth === undefined) return false;

    const prevMonth = new Date(viewingYear, viewingMonth - 1);

    if (isPrevMonthDisabled()) {
      return isProDashboardUpselling || isMarketInsightsUpselling
        ? setUpsellingModalOpen(true)
        : null;
    }

    // Pro users or within allowed range for non-pro users
    if (prevMonth.getFullYear() >= minYear) {
      liliusInstance?.viewPreviousMonth();
    }
  };

  const toNextMonth = () => {
    if (viewingYear === undefined || viewingMonth === undefined) return false;

    if (isNextMonthDisabled()) {
      return isProDashboardUpselling || isMarketInsightsUpselling
        ? setUpsellingModalOpen(true)
        : null;
    }
    liliusInstance?.viewNextMonth();
  };

  const toCurrentMonth = () => {
    liliusInstance?.setViewing(new Date());
  };

  const toCurrentYear = () => {
    liliusInstance?.viewYear(new Date().getFullYear());
  };

  const toPreviousYear = () => {
    if (viewingYear && viewingYear > minYear) {
      liliusInstance?.viewPreviousYear();
    }
  };

  const toNextYear = () => {
    liliusInstance?.viewNextYear();
  };

  const handleTabClick = useCallback(
    (newView: 'monthly' | 'yearly') => {
      const hotelTimezone =
        hotelDetails?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Convert current UTC date to hotel's timezone
      const currentDateInHotelTimezone = utcToZonedTime(new Date(), hotelTimezone);
      const currentYear = currentDateInHotelTimezone.getFullYear();

      // If liliusInstance.viewing is set, convert it to hotel's timezone; otherwise use current date in hotel's timezone
      const viewingDate = liliusInstance?.viewing
        ? utcToZonedTime(liliusInstance.viewing, hotelTimezone)
        : currentDateInHotelTimezone;
      const viewingYear = viewingDate.getFullYear();

      if (newView === 'yearly') {
        liliusInstance?.viewYear(viewingYear);
      } else if (newView === 'monthly') {
        // Determine month to view based on the year
        const monthToView = viewingYear !== currentYear ? 0 : viewingDate.getMonth();

        // Convert the new viewing date back to UTC before setting in liliusInstance
        const newViewingDate = zonedTimeToUtc(new Date(viewingYear, monthToView), hotelTimezone);
        liliusInstance?.setViewing(newViewingDate);
      }

      setView(newView);
    },
    [liliusInstance, setView, hotelDetails?.timezone]
  );

  const handleExportDashboard = () => {
    exportPDF({
      exportRef,
      fileName: `${dayjs().tz().format('YYYY-MM-DD')}-${
        hotelDetails?.name
      }-RoomPriceGenie-${startCase(type)}-Reporting.pdf`
    });
  };

  const handleScheduleReportClick = () => {
    if (!isProDashboardUpselling) {
      return onScheduleReportClick();
    }
    return setUpsellingModalOpen(true);
  };

  const handleExportExcelClick = () => {
    if (!isProDashboardUpselling) {
      return exportExcel();
    }
    return setUpsellingModalOpen(true);
  };

  const renderActions = () => {
    if (isReadOnly) return null;

    return (
      <div className="flex items-center justify-end gap-x-4" data-html2canvas-ignore="true">
        <div className="flex items-center">
          <Popover>
            <PopoverTrigger asChild>
              <Button icon>
                <Icon.MoreVertical />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="bg-white p-0 py-2">
              <div
                role="button"
                className="mt-1.5 flex cursor-pointer items-center gap-x-3 px-6 py-0.5 text-darkGrey hover:bg-mediumGrey-reduced"
                onClick={handleExportDashboard}
              >
                <Icon.Publish className="h-5 w-5" />
                <Typography color="darkGrey">{t('Export Reporting as PDF')}</Typography>
              </div>
              {ProEntryPoints.isProDashboardEntryPoint ? (
                <>
                  <div
                    role="button"
                    className="group mt-1.5 flex cursor-pointer items-center gap-x-3 px-6 py-0.5 text-darkGrey hover:bg-mediumGrey-reduced"
                    onClick={handleExportExcelClick}
                  >
                    <Icon.Excel className="h-5 w-5" />
                    <Typography color="darkGrey">{t('Export Data as Excel')}</Typography>
                    {isProDashboardUpselling ? (
                      <span className="hidden group-hover:block">
                        <SparkIcon className="-mr-1" />
                      </span>
                    ) : null}
                  </div>

                  <div
                    role="button"
                    className="group mt-1.5 flex cursor-pointer items-center gap-x-3 px-6 py-0.5 text-darkGrey hover:bg-mediumGrey-reduced"
                    onClick={handleScheduleReportClick}
                  >
                    <Icon.Timer40s className="h-5 w-5" />
                    <Typography color="darkGrey">{t('Schedule Reports')}</Typography>
                    {isProDashboardUpselling ? (
                      <span className="hidden group-hover:block">
                        <SparkIcon className="-mr-1" />
                      </span>
                    ) : null}
                  </div>
                </>
              ) : null}

              {clientViewMode === 'admin' ? (
                <>
                  <div className="my-4">
                    <Divider />
                  </div>
                  <div className="mx-4 mt-3 flex items-center justify-center">
                    <div className="mb-2 flex w-full flex-col gap-y-4">
                      <Button
                        intent="primary"
                        type="button"
                        onClick={() => getPerformanceDashboardReservationKPICsvMutation()}
                      >
                        Download CSV
                      </Button>
                      <Button
                        intent="danger"
                        type="button"
                        onClick={() => resetPostgresSnowflakeMutation()}
                      >
                        Reset Snowflake
                      </Button>
                      <Button
                        intent="danger"
                        type="button"
                        onClick={() => deletePostgresSnowflakeMutation()}
                      >
                        Remove Postgres
                      </Button>
                    </div>
                  </div>
                </>
              ) : null}
            </PopoverContent>
          </Popover>
        </div>
      </div>
    );
  };

  const handleRemoveStayDateFilters = () => {
    if (!hotelDetails?.id) return;
    let newFilters = { ...advancedAnalyticsFilters?.[hotelDetails.id] };
    // Delete stay date keys
    delete newFilters.filter_stay_date_from;
    delete newFilters.filter_stay_date_to;

    if (Object.keys(newFilters).length === 0) {
      newFilters = {};
    }
    updateHotelAnalyticsFilters(hotelDetails.id, newFilters);
  };

  return (
    <div className="sticky top-0 z-10 bg-appBackground bg-opacity-80 backdrop-blur">
      <ExportPDFHeader
        title={`RoomPriceGenie Reporting - ${truncate(hotelDetails?.name, { length: 50 })}`}
        className="mx-8 mb-20"
      />
      <div className="flex flex-col">
        <div className="mt-3 flex flex-col justify-between gap-y-4 pl-4 pr-4 md:flex-row md:pl-8 md:pr-8 min-[1755px]:pr-0">
          {isCustomDateRange && type === 'analytics' ? (
            <div className="flex flex-1 items-center gap-x-4">
              <Typography variant="h5" className="whitespace-nowrap font-medium" color="darkGrey">
                {t('Custom Stay Date Filter')}
              </Typography>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <RemovableBadge
                      onClick={() => setIsAdvancedFiltersOpen(true)}
                      onRemove={handleRemoveStayDateFilters}
                    >
                      {customRangeText}
                    </RemovableBadge>
                  </TooltipTrigger>
                  <TooltipContent side="bottom" align="start" className="max-w-xs">
                    {t(
                      'This filter displays reservations data within your specified stay-date range. To revert to the monthly or yearly overview, simply remove the filter.'
                    )}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          ) : (
            <div className="flex flex-1 items-center gap-x-4">
              <div
                role="button"
                className={clsx(
                  view === 'monthly'
                    ? 'min-w-[80px] md:min-w-[130px]'
                    : 'min-w-[40px] md:min-w-[80px]',
                  'cursor-pointer'
                )}
                onClick={view === 'monthly' ? toCurrentMonth : toCurrentYear}
              >
                <Typography variant="h5" className="font-medium" color="darkGrey">
                  {dayjs(liliusInstance?.viewing)
                    .tz()
                    .format(view === 'monthly' ? 'MMM YYYY' : 'YYYY')}
                </Typography>
              </div>
              <div className="flex gap-x-1 text-grey" data-html2canvas-ignore="true">
                <Button
                  icon
                  onClick={view === 'monthly' ? toPreviousMonth : toPreviousYear}
                  className="group"
                >
                  {isPrevMonthDisabled() &&
                  (isProDashboardUpselling || isMarketInsightsUpselling) ? (
                    <span className="hidden group-hover:block">
                      <SparkIcon className="-mr-1" />
                    </span>
                  ) : null}
                  <Icon.ChevronLeft />
                </Button>
                <Button
                  intent="text"
                  onClick={view === 'monthly' ? toCurrentMonth : toCurrentYear}
                  className="hidden whitespace-nowrap md:block"
                >
                  <Typography element="span" variant="meta-1">
                    {view === 'monthly' ? t('Current Month') : t('Current Year')}
                  </Typography>
                </Button>
                <Button
                  icon
                  onClick={view === 'monthly' ? toNextMonth : toNextYear}
                  className="group"
                >
                  <Icon.ChevronRight />
                  {isNextMonthDisabled() &&
                  (isProDashboardUpselling || isMarketInsightsUpselling) ? (
                    <span className="hidden group-hover:block">
                      <SparkIcon className="-ml-1" />
                    </span>
                  ) : null}
                </Button>
              </div>

              {(!isCustomDateRange || type === 'performance') &&
              ProEntryPoints.isProDashboardEntryPoint ? (
                <div data-html2canvas-ignore="true">
                  <SegmentedControl
                    key={view}
                    onChange={(value) => {
                      if (
                        ((type === 'performance' || type === 'analytics') &&
                          isProDashboardUpselling) ||
                        (type === 'market' &&
                          isMarketInsightsUpselling &&
                          value !== YearlyMonthly.Monthly)
                      ) {
                        return setUpsellingModalOpen(true);
                      }
                      return handleTabClick(value as 'monthly' | 'yearly');
                    }}
                    data={[
                      { label: t('Monthly'), value: YearlyMonthly.Monthly },
                      {
                        label: (
                          <Center
                            style={{ gap: 10 }}
                            onMouseEnter={() => setIsHovered({ ...isHovered, yearlyView: true })}
                            onMouseLeave={() => setIsHovered({ ...isHovered, yearlyView: false })}
                          >
                            <span>{t('Yearly')}</span>
                            {(((type === 'performance' || type === 'analytics') &&
                              isProDashboardUpselling) ||
                              (type === 'market' && isMarketInsightsUpselling)) &&
                            isHovered.yearlyView ? (
                              <SparkIcon className="ml-1" />
                            ) : null}
                          </Center>
                        ),
                        value: YearlyMonthly.Yearly
                      }
                    ]}
                    value={view}
                  />
                </div>
              ) : null}

              {clientViewMode === 'admin' ? (
                <div className="hidden md:flex" data-html2canvas-ignore="true">
                  <Badge>Admin</Badge>
                </div>
              ) : null}

              {isReadOnly ? (
                <div className="hidden md:flex" data-html2canvas-ignore="true">
                  <Badge>Read Only</Badge>
                </div>
              ) : null}

              <div className="flex-1" />
            </div>
          )}

          <div className="ml-0 flex w-full items-center justify-start gap-x-4 md:ml-6 md:justify-end">
            {renderActions()}

            {ProEntryPoints.isProDashboardEntryPoint && !isReadOnly ? (
              <Button
                intent="outline"
                className="group"
                data-html2canvas-ignore="true"
                onClick={() => {
                  if (isProDashboardUpselling) {
                    return setUpsellingModalOpen(true);
                  }
                  return onSetBudgetClick();
                }}
              >
                {t('Set Budget')}
                {isProDashboardUpselling ? (
                  <span className="hidden group-hover:block">
                    <SparkIcon />
                  </span>
                ) : null}
              </Button>
            ) : null}

            <div className="flex flex-1 sm:hidden" />
          </div>
        </div>

        <div className={styles.tabs} data-html2canvas-ignore="true">
          <div className="flex items-center gap-x-6 pl-4 pr-4 md:pl-8 md:pr-8 min-[1755px]:pr-0">
            {hasDashboardFeature() ? (
              <>
                <SecureNavLink to="performance">
                  {({ isActive }) => (
                    <div
                      onClick={(e) => {
                        if (isDashboardUpselling) {
                          e.preventDefault();
                          return setUpsellingModalOpen(true);
                        }
                      }}
                      className={clsx(
                        styles.tab,
                        isActive ? styles.active_tab : styles.inactive_tab,
                        'group'
                      )}
                    >
                      <Icon.Flash className="mr-2 inline-block h-5 w-5 align-middle" />
                      {t('Performance')}
                      {isDashboardUpselling ? (
                        <span className="hidden group-hover:block">
                          <SparkIcon className="ml-2" />
                        </span>
                      ) : null}
                    </div>
                  )}
                </SecureNavLink>

                {ProEntryPoints.isProDashboardEntryPoint ? (
                  <SecureNavLink to="analytics">
                    {({ isActive }) => (
                      <div
                        onClick={(e) => {
                          if (isProDashboardUpselling || isDashboardUpselling) {
                            e.preventDefault();
                            return setUpsellingModalOpen(true);
                          }
                        }}
                        className={clsx(
                          styles.tab,
                          isActive ? styles.active_tab : styles.inactive_tab,
                          'group'
                        )}
                      >
                        <Icon.ChartBarThree className="mr-2 inline-block h-5 w-5 align-middle" />
                        {t('Analytics')}
                        {isProDashboardUpselling || isDashboardUpselling ? (
                          <span className="hidden group-hover:block">
                            <SparkIcon className="ml-2" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </SecureNavLink>
                ) : null}
              </>
            ) : null}

            <SecureNavLink
              to="market"
              feature={[Feature.ProMarketInsights, Feature.LimitedMarketInsights]}
            >
              {({ isActive }) => (
                <div
                  className={clsx(
                    styles.tab,
                    isActive ? styles.active_tab : styles.inactive_tab,
                    'group'
                  )}
                >
                  <Icon.Molecule className="mr-2 inline-block h-5 w-5 align-middle" />
                  {t('Vacation Rental Benchmarking')}
                </div>
              )}
            </SecureNavLink>
            <div className="flex-1" />
            <div className="hidden py-3 sm:block">
              {isLoading || isFutureDailyLoading || isAirbnbInsightsLoading ? (
                <div className="h-5 w-48 animate-pulse rounded-sm bg-grey-reduced" />
              ) : type === 'market' ? (
                <>
                  <Typography variant="nav" color="copyTextGrey" className="mr-2 text-opacity-40">
                    {t('Vacation Rental Market:')} {futureDaily?.city_name || 'n.A.'}
                    {';'}
                  </Typography>
                  <Typography variant="nav" color="copyTextGrey" className="text-opacity-40">
                    {t('Last Update:')} {updateDate}
                  </Typography>
                </>
              ) : (
                <Typography variant="nav" color="copyTextGrey" className="text-opacity-40">
                  {t('Last Reservation:')} {reservationDate}
                </Typography>
              )}
            </div>

            <AnalyticsFilters
              isModalOpen={isAdvancedFiltersOpen}
              onClose={() => setIsAdvancedFiltersOpen(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
