import { getEnvVar } from '@common/utils/env';

const generatePlaceHolder = 'Click Update to auto-generate';

export const integrations: Integration[] = [
  {
    label: 'Apaleo',
    value: 'apaleo',
    key: 1,
    fields: [
      {
        type: 'btn',
        label: 'Get Property List',
        property: true,
        endpoint: {
          path: '/hotel/pms/properties/',
          fields: ['token'],
          method: 'GET'
        },
        key: 'btnkey',
        password: false,
        noValidate: true
      },
      {
        type: 'multiSelectProperty',
        options: 'propertyList',
        label: 'PropertyID',
        key: 'hotel_code',
        password: false,
        noValidate: true
      }
      // {
      //   type: 'text',
      //   label: 'Client ID',
      //   key: 'username',
      //   password: false,
      //   hideNoData: true,
      //   noValidate: true
      // }
      // {
      //   type: 'text',
      //   label: 'Client Secret',
      //   key: 'password',
      //   password: true,
      //   hideNoData: true,
      //   noValidate: true
      // }
    ]
  },
  {
    label: 'Cover',
    value: 'cover',
    key: 14,
    fields: [
      {
        type: 'text',
        label: 'Public Key From Developer Keys',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Secret Key From Developer Keys',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'API Key',
        key: 'token',
        password: false
      },
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Mews',
    value: 'mews',
    key: 2,
    fields: [
      {
        type: 'text',
        label: 'Token',
        key: 'token',
        password: false
      },
      {
        type: 'btn',
        label: 'Get Mews Property List',
        property: true,
        key: 'btnkey',
        endpoint: {
          path: '/hotel/mews/get-properties/',
          fields: ['token'],
          method: 'POST'
        },
        password: false,
        noValidate: true
      },
      {
        type: 'multiSelectProperty',
        label: 'PropertyID',
        options: 'propertyList',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Booking Factory',
    value: 'bookingfactory',
    key: 3,
    fields: [
      {
        type: 'text',
        label: 'Public key From Developer keys',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Secret key From Developer keys',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'API key',
        key: 'token',
        password: false
      },
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Little Hotelier',
    value: 'littlehotelier',
    key: 4,
    fields: [
      {
        type: 'text',
        label: 'Little Hotelier Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Little Hotelier password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false,
        noValidate: true
      },
      {
        type: 'select',
        options: 'regionList',
        label: 'Region',
        key: 'region',
        password: false
      }
    ]
  },
  {
    label: 'SiteMinder',
    value: 'siteminder',
    key: 5,
    fields: [
      {
        type: 'text',
        label: 'SiteMinder Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'SiteMinder password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false,
        noValidate: true
      },
      {
        type: 'select',
        options: 'siteminderRegionList',
        label: 'Region',
        key: 'region',
        password: false
      }
    ]
  },
  {
    label: 'Clock System',
    value: 'clock',
    key: 7,
    fields: [
      {
        type: 'text',
        label: 'Clock System Username',
        key: 'username'
      },
      {
        type: 'text',
        label: 'API Access key',
        key: 'token'
      },
      {
        type: 'text',
        label: 'PMS URL',
        key: 'pms_url',
        password: false
      }
    ]
  },
  {
    label: 'Hotel Spider(Deprecated)',
    value: 'hotelspiderDeprecated',
    key: 10,
    fields: [
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Protel',
    value: 'protel',
    key: 15,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'select',
        options: 'protelConn',
        label: 'Protel type',
        key: 'region',
        password: false
      },
      {
        type: 'text',
        label: 'Token',
        key: 'token',
        password: false
      },
      {
        type: 'select',
        options: 'availability',
        label: 'Availability Count type',
        key: 'extra',
        password: false
      }
    ]
  },
  {
    label: 'Channex',
    value: 'channex',
    key: 20,
    fields: [
      {
        type: 'text',
        label: 'API key',
        key: 'token',
        password: false
      },
      {
        type: 'btn',
        label: 'Get Channex Property List',
        key: 'btnkey',
        property: true,
        endpoint: {
          path: '/hotel/channex/get-properties/',
          fields: ['token', 'username', 'password'],
          method: 'GET'
        },
        password: false,
        noValidate: true
      },
      {
        type: 'select',
        label: 'PropertyID',
        options: 'propertyList',
        key: 'hotel_code',
        password: false,
        noValidate: true
      }
    ]
  },
  {
    label: 'GlobeRes',
    value: 'globeres',
    key: 21,
    fields: [
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      }
    ]
  },
  {
    label: 'Guestline',
    value: 'guestline',
    key: 22,
    fields: [
      {
        type: 'text',
        label: 'HotelID',
        readonly: true,
        copyable: true,
        key: 'hotel_code',
        password: false,
        noValidate: true,
        placeholder: generatePlaceHolder
      },
      {
        type: 'select',
        options: 'guestLineRegions',
        label: 'Region',
        key: 'region',
        password: false
      }
    ]
  },
  {
    label: 'Bocco',
    value: 'bocco',
    key: 23,
    fields: [
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Seekom',
    value: 'seekom',
    key: 24,
    fields: [
      {
        type: 'btn',
        label: 'Connect with Seekom',
        key: 'btnkey',
        pms: 'seekom',
        url: false,
        btn: true,
        endpoint: {
          connection: true,
          path: `${getEnvVar('VITE_CONNECTION_SEEKOM')}`,
          fields: []
        }
      },
      {
        type: 'text',
        label: 'API key ',
        key: 'token',
        password: false
      },
      {
        type: 'text',
        label: 'Refresh key',
        key: 'extra',
        password: false
      },
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Beds24(Deprecated)',
    value: 'beds24deprecated',
    key: 25,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'text',
        label: 'API key',
        key: 'token',
        password: false
      },
      {
        type: 'multiSelectAdd',
        label: 'Multiple Properties',
        key: 'extra',
        password: false,
        noValidate: true
      }
    ]
  },
  {
    label: 'Booking Automation Partner',
    value: 'bookingautomationnew',
    key: 26,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'text',
        label: 'API key',
        key: 'token',
        password: false
      },
      {
        type: 'multiSelectAdd',
        label: 'Multiple Properties',
        key: 'extra',
        password: false,
        noValidate: true
      }
    ]
  },
  {
    label: 'RMS',
    value: 'rms',
    key: 27,
    fields: [
      {
        type: 'text',
        label: 'ClientID',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Webservice password',
        key: 'password',
        password: true
      },
      {
        type: 'select',
        options: 'regionListRms',
        label: 'Region',
        key: 'region',
        password: false
      },
      {
        type: 'btn',
        label: 'Get Property List',
        property: true,
        endpoint: {
          path: '/hotel/rms/get-properties/',
          fields: ['token', 'client_id', 'client_password', 'region'],
          method: 'POST'
        },
        endpointV2: {
          path: '/hotel/pms/properties/',
          fields: ['token'],
          method: 'GET'
        },
        key: 'btnkey',
        password: false,
        noValidate: true
      },
      {
        type: 'multiSelectProperty',
        isV2: true,
        label: 'PropertyID',
        options: 'propertyList',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'select',
        label: 'PropertyID',
        options: 'propertyList',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'btn',
        label: 'Get Agent List',
        property: false,
        endpoint: {
          path: '/hotel/rms/get-online-agents/',
          fields: ['token', 'client_id', 'client_password', 'property_id', 'region'],
          method: 'POST'
        },
        key: 'btnkey',
        password: false,
        noValidate: true
      },
      {
        type: 'select',
        label: 'AgentID',
        options: 'agentList',
        key: 'extra',
        password: false,
        noValidate: true
      }
    ]
  },
  {
    label: 'Web Hotelier',
    value: 'webhotelier',
    key: 28,
    fields: [
      {
        type: 'text',
        label: 'Property Code',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'text',
        label: 'password',
        key: 'token',
        password: false
      },
      {
        type: 'checkbox',
        label: 'Children Pricing',
        key: 'extra',
        password: false,
        noValidate: true
      }
    ]
  },
  {
    label: 'Kognitiv',
    value: 'kognitiv',
    key: 29,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Vertical Booking',
    value: 'vertical_booking',
    key: 34,
    fields: [
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'select',
        options: 'verticalBookingList',
        label: 'Price type',
        key: 'extra',
        password: false
      }
    ]
  },
  {
    label: 'CultSwitch',
    value: 'cultswitch',
    key: 35,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'select',
        options: 'currency',
        label: 'Currency',
        key: 'currency_id',
        password: false
      },
      {
        type: 'select',
        options: 'pricingType',
        label: 'Occupancy- or Room-Based Pricing',
        key: 'extra',
        password: false
      }
    ]
  },
  {
    label: 'SiteMinder RMS API',
    value: 'siteMinder_RMS_API',
    key: 37,
    fields: [
      {
        type: 'text',
        label: 'RMS property ID',
        key: 'channel_password',
        password: false
      },
      {
        type: 'select',
        options: 'currency',
        label: 'Currency',
        key: 'currency_id',
        password: false
      },
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'Siteminder Property ID',
        key: 'hotel_code',
        password: false,
        noValidate: true
      },
      {
        type: 'select',
        options: 'regionList',
        label: 'Region',
        key: 'region',
        password: false
      }
    ]
  },
  {
    label: 'Semper',
    value: 'semper',
    key: 38,
    fields: [
      {
        type: 'text',
        label: 'VenueID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'MappingMaster',
    value: 'mappingMaster',
    key: 39,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'select',
        options: 'currency',
        label: 'Currency',
        key: 'currency_id',
        password: false
      },
      {
        type: 'select',
        options: 'pricing type',
        label: 'Occupancy or Room-Based Pricing',
        key: 'extra',
        password: false
      }
    ]
  },
  {
    label: 'ibelsa',
    value: 'ibelsa',
    key: 40,
    fields: [
      {
        type: 'text',
        label: 'Token',
        key: 'token',
        password: false
      },
      {
        type: 'text',
        label: 'Username (for optional dashboard)',
        key: 'username',
        password: false,
        noValidate: true
      },
      {
        type: 'text',
        label: 'Password (for optional dashboard)',
        key: 'password',
        password: true,
        noValidate: true
      },
      {
        type: 'select',
        options: 'currency',
        label: 'Currency',
        key: 'currency_id',
        password: false
      }
    ]
  },
  {
    label: 'RoomRaccoon',
    value: 'RoomRaccoon',
    key: 41,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Guesty',
    value: 'Guesty',
    key: 42,
    fields: [
      {
        type: 'text',
        label: 'Integration Token',
        key: 'extra',
        password: false
      }
    ]
  },
  {
    label: 'Reconline',
    value: 'Reconline',
    key: 44,
    fields: [
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'D-edge',
    value: 'dedge',
    key: 45,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'text',
        label: 'Client ID',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Client Secret',
        key: 'password',
        password: true
      }
    ]
  },
  {
    label: 'eviivo',
    value: 'eviivo',
    key: 46,
    fields: [
      {
        type: 'text',
        label: 'Client ID',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Client Secret',
        key: 'password',
        password: true
      },
      {
        type: 'btn',
        label: 'Get Property List',
        property: true,
        endpoint: {
          path: '/hotel/eviivo/get-properties/',
          fields: ['token', 'username', 'password'],
          method: 'POST'
        },
        key: 'btnkey',
        password: false,
        noValidate: true
      },
      {
        type: 'multiSelectProperty',
        label: 'PropertyID',
        options: 'propertyList',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Ghix',
    value: 'Ghix',
    key: 47,
    fields: [
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'OneHotel',
    value: 'OneHotel',
    key: 48,
    fields: [
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'HLS',
    value: 'HLS',
    key: 49,
    fields: [
      {
        type: 'text',
        label: 'Client ID',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Client Secret',
        key: 'password',
        password: true
      },
      {
        type: 'btn',
        label: 'Get Property List',
        property: true,
        endpoint: {
          path: '/hotel/hls/get-properties/',
          fields: ['token', 'username', 'password'],
          method: 'POST'
        },
        key: 'btnkey',
        password: false,
        noValidate: true
      },
      {
        type: 'select',
        options: 'propertyList',
        label: 'PropertyID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Cubilis',
    value: 'Cubilis',
    key: 50,
    fields: [
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'password',
        key: 'password',
        password: true
      }
    ]
  },
  {
    label: 'Roomcloud',
    value: 'Roomcloud',
    key: 51,
    fields: [
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Freetobook',
    value: 'Freetobook',
    key: 52,
    fields: [
      {
        type: 'btn',
        label: 'Connect with Freetobook',
        key: 'btnkey',
        pms: 'freetobook',
        url: false,
        btn: true,
        endpoint: {
          connection: true,
          path: `${getEnvVar('VITE_CONNECTION_FREETOBOOK')}`,
          fields: []
        }
      },
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Profitroom',
    value: 'Profitroom',
    key: 53,
    fields: [
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'select',
        options: 'pricingType',
        label: 'Occupancy- or Room-Based Pricing',
        key: 'extra',
        password: false
      }
    ]
  },
  {
    label: 'ZimmerSoftware',
    value: 'Zimmersoftware',
    key: 54,
    fields: [
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'password',
        key: 'password',
        password: true
      }
    ]
  },
  {
    label: 'Noovy',
    value: 'Noovy',
    key: 57,
    fields: [
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Dirs21',
    value: 'Dirs21',
    key: 58,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Hotsoft',
    value: 'Hotsoft',
    key: 59,
    fields: [
      {
        type: 'text',
        label: 'Service URL',
        key: 'pms_url',
        password: false,
        placeholder: 'https://hsapi.hoistcloud.com/'
      },
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Password',
        key: 'password',
        password: false
      }
    ]
  },
  {
    label: 'Stayntouch',
    value: 'Stayntouch',
    key: 60,
    fields: [
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'text',
        label: 'Client ID',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Client Secret',
        key: 'password',
        password: false
      },
      {
        type: 'select',
        options: 'regionListStayntouch',
        label: 'Region',
        key: 'region',
        password: false
      }
    ]
  },
  {
    label: 'OperaCloud',
    value: 'OperaCloud',
    key: 61,
    fields: [
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'text',
        label: 'Client ID',
        key: 'client_id',
        password: false
      },
      {
        type: 'text',
        label: 'Client Secret',
        key: 'channel_password',
        password: false
      },
      {
        type: 'text',
        label: 'PMS URL',
        key: 'pms_url',
        password: false
      },
      {
        type: 'select',
        options: 'operaAvailability',
        label: 'Availability Count type',
        key: 'availability_count_type',
        password: false
      },
      {
        type: 'select',
        options: 'versionList',
        label: 'Version',
        key: 'region',
        password: false
      }
    ]
  },
  {
    label: 'Hotelavailabilities',
    value: 'Hotelavailabilities',
    key: 62,
    fields: [
      {
        type: 'text',
        label: 'Hotel ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'RateTiger',
    value: 'RateTiger',
    key: 63,
    fields: [
      {
        type: 'text',
        label: 'Hotel ID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'Hotel Name',
        key: 'extra',
        password: false
      }
    ]
  },
  {
    label: 'SiteMinder New',
    value: 'siteminder New',
    key: 64,
    fields: [
      {
        type: 'text',
        label: 'SiteMinder Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'SiteMinder password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false,
        noValidate: true
      },
      {
        type: 'select',
        options: 'regionList',
        label: 'Region',
        key: 'region',
        password: false
      }
    ]
  },
  {
    label: 'SiteMinder RMS API New',
    value: 'siteMinder_RMS_API_New',
    key: 65,
    fields: [
      {
        type: 'text',
        label: 'RMS property ID',
        key: 'channel_password',
        password: false
      },
      {
        type: 'select',
        options: 'currency',
        label: 'Currency',
        key: 'currency_id',
        password: false
      },
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'Siteminder Property ID',
        key: 'hotel_code',
        password: false,
        noValidate: true
      },
      {
        type: 'select',
        options: 'regionList',
        label: 'Region',
        key: 'region',
        password: false
      }
    ]
  },
  {
    label: 'Thais',
    value: 'thais',
    key: 66,
    fields: [
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'password',
        key: 'password',
        password: true
      },
      {
        type: 'text',
        label: 'PMS URL',
        key: 'pms_url',
        password: false
      }
    ]
  },
  {
    label: 'Resly',
    value: 'resly',
    key: 68,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'text',
        label: 'Token',
        key: 'token',
        password: false
      }
    ]
  },
  {
    label: 'Wincloud',
    value: 'wincloud',
    key: 69,
    fields: [
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'SabeeApp',
    value: 'sabeeapp',
    key: 71,
    fields: [
      {
        type: 'select',
        options: 'sabeeappList',
        label: 'Price type',
        key: 'extra',
        password: false
      },
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'STAAH',
    value: 'staah',
    key: 73,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Cloudbeds',
    value: 'Cloudbeds',
    key: 70,
    fields: [
      {
        type: 'btn',
        label: 'Connect with cloudbeds',
        url: false,
        btn: true,
        pms: 'cloudbeds',
        key: 'btnkey',
        endpoint: {
          connection: true,
          path: `${getEnvVar('VITE_CONNECTION_CLOUDBEDS')}`,
          fields: []
        }
      },
      {
        type: 'btn',
        label: 'Get Property List',
        property: true,
        endpoint: {
          path: '/account/integrations/properties/',
          fields: ['token'],
          method: 'GET'
        },
        key: 'btnkey_2',
        password: false,
        noValidate: true,
        pms: 'cloudbeds'
      },
      {
        type: 'multiSelectProperty',
        label: 'PropertyID',
        options: 'propertyList',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Newbook',
    value: 'newbook',
    key: 72,
    fields: [
      {
        type: 'text',
        label: 'HotelID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'select',
        options: 'newbookRegions',
        label: 'Region',
        key: 'region',
        password: false
      }
    ]
  },
  {
    label: 'Anand System',
    value: 'anand_system',
    key: 74,
    fields: [
      {
        type: 'text',
        label: 'Client ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Rezcontrol',
    value: 'rezcontrol',
    key: 76,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'RoomMaster Anywhere',
    value: 'room_master',
    key: 77,
    fields: [
      {
        type: 'text',
        label: 'HotelID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Hotline',
    value: 'hotline',
    key: 78,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: '3RPMS',
    value: '3RPMS',
    key: 79,
    fields: [
      {
        type: 'text',
        label: 'API Key',
        key: 'token',
        password: false
      }
    ]
  },
  {
    label: 'CASABLANCA',
    value: 'casablanca',
    key: 80,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Lobbi',
    value: 'lobbi',
    key: 82,
    fields: [
      {
        type: 'btn',
        label: 'Connect with Lobbi',
        url: false,
        btn: true,
        pms: 'lobbi',
        key: 'btnkey',
        endpoint: {
          connection: true,
          new_sso: true,
          path: `${getEnvVar('VITE_API')}account/sso/lobbi/redirect`,
          fields: []
        }
      },
      {
        type: 'btn',
        label: 'Get Locations',
        property: true,
        endpoint: {
          path: '/hotel/pms/properties/',
          fields: ['token'],
          method: 'GET'
        },
        key: 'btnkey',
        password: false,
        noValidate: true
      },
      {
        type: 'multiSelectProperty',
        options: 'propertyList',
        label: 'Locations',
        key: 'hotel_code',
        password: false,
        noValidate: true
      }
    ]
  },
  {
    label: 'Hotel Spider',
    value: 'hotelspider',
    key: 83,
    fields: [
      {
        type: 'text',
        label: 'Hotel Code',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Beds24',
    value: 'beds24',
    key: 84,
    fields: [
      {
        type: 'text',
        label: 'Invite Code',
        key: 'token',
        password: false
      },
      {
        type: 'btn',
        label: 'Get Property List',
        property: true,
        endpoint: {
          path: '/hotel/pms/properties/',
          fields: ['token'],
          method: 'GET'
        },
        key: 'btnkey',
        password: false,
        noValidate: true
      },
      {
        type: 'multiSelectProperty',
        label: 'Multiple Properties',
        key: 'hotel_code',
        options: 'propertyList',
        password: false,
        noValidate: true
      }
    ]
  },
  {
    label: 'WebRezPro',
    value: 'webrezpro',
    key: 85,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        key: 'hotel_code',
        password: false
      },
      {
        type: 'text',
        label: 'Username',
        key: 'username',
        password: false
      },
      {
        type: 'text',
        label: 'Password',
        key: 'password',
        password: true
      }
    ]
  },
  {
    label: 'HS/3',
    value: 'hs/3',
    key: 86,
    fields: [
      {
        type: 'text',
        label: 'Property ID',
        readonly: true,
        key: 'hotel_code',
        copyable: true,
        noValidate: true,
        password: false,
        placeholder: generatePlaceHolder
      },
      {
        type: 'text',
        label: 'Password',
        readonly: true,
        copyable: true,
        key: 'password',
        password: true,
        noValidate: true,
        placeholder: generatePlaceHolder
      }
    ]
  },
{
    label: 'VIPS CloudPMS',
    value: 'vips_cloudpms',
    key: 88,
    fields: [
      {
        type: 'text',
        label: 'Site Code',
        key: 'hotel_code',
        password: false
      }
    ]
  },
  {
    label: 'Skytouch',
    value: 'skytouch',
    key: 87,
    fields: [
      {
        type: 'text',
        label: 'Hotel ID',
        key: 'hotel_code',
        password: false
      }
    ]
  }
];

// get all unique field types
export const fieldTypes = [
  ...new Set(
    integrations
      .map((integration) => integration.fields)
      .flat()
      .map((field) => field.type)
  )
];

export interface Integration {
  label: string;
  value: string;
  key: number;
  fields: Field[];
}

type METHOD = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';

interface Field {
  type?:
    | 'text'
    | 'select'
    | 'checkbox'
    | 'multiSelectAdd'
    | 'evMultiSelect'
    | 'btn'
    | 'multiSelectProperty';
  label: string;
  key?: string;
  isV2?: boolean;
  readonly?: boolean;
  copyable?: boolean;
  placeholder?: string;
  endpoint?: {
    path: string;
    fields: string[];
    connection?: boolean;
    new_sso?: boolean;
    method?: METHOD;
  };
  endpointV2?: {
    path: string;
    fields: string[];
    connection?: boolean;
    new_sso?: boolean;
    method?: METHOD;
  };
  password?: boolean;
  hideNoData?: boolean;
  noValidate?: boolean;
  options?: string;
  url?: boolean;
  btn?: boolean;
  pms?: string;
  property?: boolean;
}
