import { Icon } from '@common/components/foundations/icons';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Accordion,
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Group,
  Paper,
  rem,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Title,
  Tooltip
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import {
  useAddRatePlansRestriction,
  useRateRestrictions,
  useRoomRestrictions
} from '@pages/Client/PricingStrategy/MinStay/hooks/useRateRestrictions';
import { useFormSubmitStore } from '@pages/Client/PricingStrategy/MinStay/store/formSubmitStore';
import useRatePlanStore from '@pages/Client/PricingStrategy/MinStay/store/ratePlanStore';
import { RatePlan, ratePlanSchema } from '@pages/Client/PricingStrategy/MinStay/types';
import { IconArrowDown } from '@tabler/icons-react';
import { difference, flatMap, isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const MinStayMultipleRatePlans = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Restrictions on Rates'));
  const navigate = useNavigate();
  const { hotelDetails } = useHotelDetails();

  const { trackEvent } = useEventTracking();
  const setSubmitHandler = useFormSubmitStore((state) => state.setSubmitHandler);
  const setIsSaving = useFormSubmitStore((state) => state.setIsSaving);

  const [roomId, setRoomId] = useState<string | null>();
  const { roomTypes, isGetRoomListLoading, hasNextPage, fetchNextPage, refetchRooms } =
    useRoomRestrictions();
  const { hasMinStayMultipleRates } = usePmsProvider();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!hotelDetails) return;
    if (!hasMinStayMultipleRates()) {
      navigate(`/client/calendar/${hotelDetails.id}/pricing`, { replace: true });
    }
  }, [, hotelDetails, pathname, navigate, hasMinStayMultipleRates]);

  const { enableRatePlanIds, initializeRatePlans } = useRatePlanStore();

  const {
    saveRatePlansRestrictions,
    isLoading: isSavingRatePlans,
    isSuccess: isSuccessSavingRatePlans
  } = useAddRatePlansRestriction();

  const { ratePlans, isGetRateLoading, ratesHasNextPage, fetchNextPageRates } =
    useRateRestrictions(roomId);
  const { control, handleSubmit, setValue, watch } = useForm<RatePlan>({
    resolver: zodResolver(ratePlanSchema)
  });

  const ratePlanMapping = roomTypes?.reduce(
    (acc, roomType) => {
      acc[roomType.code] = roomType.enable_rate_plan_ids.map(String);
      return acc;
    },
    {} as Record<string, string[]>
  );

  useEffect(() => {
    initializeRatePlans(ratePlanMapping);
  }, [initializeRatePlans, ratePlanMapping]);

  useEffect(() => {
    const currentRatePlanValues = watch('enable_rate_plan_ids') || {};
    if (!isEqual(currentRatePlanValues, enableRatePlanIds)) {
      setValue('enable_rate_plan_ids', enableRatePlanIds);
    }
  }, [enableRatePlanIds, setValue, watch]);

  useEffect(() => {
    if (isSuccessSavingRatePlans) {
      refetchRooms();
    }
  }, [isSuccessSavingRatePlans, refetchRooms]);

  useEffect(() => {
    if (isSavingRatePlans) {
      setIsSaving(true);
      setRoomId(null);
    } else {
      setIsSaving(false);
    }
  }, [isSavingRatePlans]);

  const ROOM_RATES_OPTIONS =
    ratePlans?.map((rate) => {
      return {
        label: rate?.name,
        value: rate?.id?.toString(),
        code: rate?.code
      };
    }) || [];

  // Submit logic
  const onSubmit = async (data: RatePlan) => {
    // Flatten all enable rate plan ids across room types and ensure they are all numbers
    const enableRatePlanIds = flatMap(data.enable_rate_plan_ids, (rateIds) => rateIds).map(Number);

    // Flatten all rate plan ids from roomTypes for comparison
    const allRatePlanIds = flatMap(roomTypes, (roomType) =>
      roomType.enable_rate_plan_ids.map((id) => Number(id))
    );

    // Find the difference between allRatePlanIds and enableRatePlanIds to get disable_rate_plan_ids
    const disableRatePlanIds = difference(allRatePlanIds, enableRatePlanIds);

    await saveRatePlansRestrictions({
      enable_rate_plan_ids: enableRatePlanIds,
      disable_rate_plan_ids: disableRatePlanIds
    });

    trackEvent('MinStayMultipleRates', {
      tags: {
        enable_rate_plan_ids: enableRatePlanIds.length.toString(),
        disable_rate_plan_ids: disableRatePlanIds.length.toString()
      }
    });
  };

  useEffect(() => {
    setSubmitHandler(handleSubmit(onSubmit));
  }, [handleSubmit, onSubmit, setSubmitHandler]);

  return (
    <Box>
      <SimpleGrid
        cols={{
          sm: 1,
          md: 2
        }}
      >
        <Stack maw="80%">
          <Title order={3} fw={400}>
            {t('Manage Minimum Stay Restrictions on Rates')}
          </Title>

          <Text c="gray.9">
            {t(
              'In RoomPriceGenie we only show the restrictions applied to your main mapped rate (Mapped Rate).'
            )}
          </Text>

          <Text c="gray.9">
            {t(
              'However, we can also send Minimum Stay restrictions to other rates. Select which rates you would like restrictions set in RoomPriceGenie to be applied to on the right.'
            )}
          </Text>
        </Stack>

        <Box>
          <Paper w="100%" p="md">
            <Stack>
              <Text fw={400}>{t('Rates Min Stay restrictions should be applied to:')} </Text>
              <Divider />
              {isGetRoomListLoading ? (
                Array.from({ length: 3 }).map((_, index) => <Skeleton h={42} key={index} />)
              ) : (
                <Box>
                  <ScrollArea.Autosize type="always" mah="70vh" offsetScrollbars scrollbars="y">
                    <Accordion
                      variant="separated"
                      styles={{
                        item: {
                          border: '1px solid var(--mantine-color-gray-3)',
                          borderRadius: rem(16)
                        }
                      }}
                      value={roomId}
                      onChange={(value) => setRoomId(value)}
                    >
                      {roomTypes?.map((roomType) => {
                        const isEqualCode = isEqual(
                          watch(`enable_rate_plan_ids.${roomType.code}`)?.map(String),
                          roomType.enable_rate_plan_ids.map(String)
                        );
                        return (
                          <Accordion.Item key={roomType.code} value={roomType.code}>
                            <Accordion.Control>
                              <Group>
                                <Text>{roomType.name}</Text>
                                <Badge
                                  size="sm"
                                  radius="xl"
                                  styles={{
                                    root: {
                                      border: !isEqualCode
                                        ? '1px solid var(--mantine-color-orange-3)'
                                        : '1px solid var(--mantine-color-dark-9)'
                                    }
                                  }}
                                  variant="outline"
                                  c={!isEqualCode ? 'orange.3' : 'dark.9'}
                                >
                                  {watch(`enable_rate_plan_ids.${roomType.code}`)?.length}{' '}
                                  {t('Rates Selected')}
                                </Badge>
                              </Group>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <Controller
                                name={`enable_rate_plan_ids.${roomType.code}`}
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                  <Checkbox.Group
                                    value={value}
                                    defaultValue={roomType.enable_rate_plan_ids.map(String)}
                                    error={error?.message}
                                    onChange={onChange}
                                  >
                                    <ScrollArea.Autosize
                                      type="always"
                                      mah="800px"
                                      offsetScrollbars
                                      scrollbars="y"
                                    >
                                      <Stack pt="xs" gap="xs">
                                        {isGetRateLoading
                                          ? Array.from({ length: 2 }).map((_, index) => (
                                              <Skeleton h={40} key={index} />
                                            ))
                                          : ROOM_RATES_OPTIONS.map((ratePlan) => (
                                              <Checkbox.Card
                                                px="md"
                                                py="xs"
                                                radius="lg"
                                                value={ratePlan.value}
                                                key={ratePlan.value}
                                                className="group bg-white hover:bg-[var(--mantine-color-default-hover)] disabled:cursor-not-allowed disabled:opacity-50 data-[checked]:border-[var(--mantine-primary-color-filled)]"
                                              >
                                                <Group
                                                  wrap="nowrap"
                                                  align="center"
                                                  justify="space-between"
                                                >
                                                  <Flex gap="sm">
                                                    <Text c="dark" size="sm">
                                                      {ratePlan.label}
                                                    </Text>
                                                    <Tooltip label={ratePlan.code}>
                                                      <Tooltip label={ratePlan.code}>
                                                        <Text c="dark" size="sm" truncate="end">
                                                          (
                                                          {ratePlan.code?.length > 20
                                                            ? `${ratePlan.code.slice(0, 20)}...`
                                                            : ratePlan.code}
                                                          )
                                                        </Text>
                                                      </Tooltip>
                                                    </Tooltip>
                                                  </Flex>
                                                  <div className="hidden shrink-0 text-indigo group-data-[checked]:flex">
                                                    <Icon.CheckList className="h-5 w-5" />
                                                  </div>
                                                </Group>
                                              </Checkbox.Card>
                                            ))}
                                        {ratesHasNextPage && (
                                          <Center my="md">
                                            <Button
                                              rightSection={<IconArrowDown />}
                                              variant="transparent"
                                              onClick={() => fetchNextPageRates()}
                                              disabled={isGetRateLoading}
                                              loading={isGetRateLoading}
                                            >
                                              {isGetRateLoading
                                                ? t('Loading...')
                                                : t('See More Rates')}
                                            </Button>
                                          </Center>
                                        )}
                                      </Stack>
                                    </ScrollArea.Autosize>
                                  </Checkbox.Group>
                                )}
                              />
                            </Accordion.Panel>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                    {hasNextPage && (
                      <Center my="md">
                        <Button
                          rightSection={<IconArrowDown />}
                          variant="transparent"
                          onClick={() => fetchNextPage()}
                          disabled={isGetRoomListLoading}
                          loading={isGetRoomListLoading}
                        >
                          {isGetRoomListLoading ? t('Loading...') : t('See More Room Types')}
                        </Button>
                      </Center>
                    )}
                  </ScrollArea.Autosize>
                </Box>
              )}
            </Stack>
          </Paper>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default MinStayMultipleRatePlans;
