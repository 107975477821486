import { ErrorResponse } from '@common/api/auth';
import { getForecastingCache } from '@common/api/dashboard';
import { ForecastingCache } from '@common/api/dashboard/types';
import { useNotificationsStore } from '@common/store/notifications';
import { ForecastQueryKeys } from '@common/types/query-keys';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useForecastingCache = (supportMultipleHotels = false) => {
  const { hotelDetails } = useHotelDetails();
  const { propertyIds } = useDashboardPageStore();
  const { addNotification } = useNotificationsStore();
  const { t } = useTranslation();

  const query = useQuery<ForecastingCache, ErrorResponse>({
    queryKey: [ForecastQueryKeys.GET_FORECASTING_CACHE, propertyIds, supportMultipleHotels],
    queryFn: () =>
      hotelDetails
        ? getForecastingCache({
            ...(supportMultipleHotels && !isEmpty(propertyIds?.[hotelDetails.id])
              ? {
                  filter_hotels: propertyIds?.[hotelDetails.id]
                }
              : {})
          })
        : Promise.reject('No hotel ID'),
    enabled: !!hotelDetails?.id
  });

  if (query.isError) addNotification('fail', t('Failed to fetch forecasting data'));

  const forecastingCache = useMemo(() => {
    return query.data;
  }, [query.data]);

  return { forecastingCache, query };
};
