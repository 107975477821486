import { ErrorResponse } from '@common/api/auth';
import { getErrorTicketTagList } from '@common/api/errors';
import { ParamsTicketTags, TicketTagsResponse } from '@common/api/errors/types';
import { useNotificationsStore } from '@common/store/notifications';
import { ErrorTicketsQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useErrorTicketsTags = ({ ticket_type = 1 }: ParamsTicketTags) => {
  const { addNotification } = useNotificationsStore();

  const query = useQuery<TicketTagsResponse, ErrorResponse>({
    queryKey: [ErrorTicketsQueryKeys.ERROR_TICKET_TAGS, ticket_type],
    queryFn: () => getErrorTicketTagList({ ticket_type })
  });

  if (query.isError) addNotification('fail', 'Failed to fetch error ticket tags');

  const errorTicketsTags = useMemo(() => {
    return query.data?.results?.map(({ id, name }) => ({ label: name, value: id })) || [];
  }, [query.data]);

  return { errorTicketsTags, query };
};
