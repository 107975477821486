import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { useNotificationsStore } from '@common/store/notifications';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { zodResolver } from '@hookform/resolvers/zod';
import { isArray } from 'lodash-es';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

const schema = z.object({
  price: z
    .number()
    .or(z.string().transform(Number))
    .refine((val) => typeof val === 'number' && val > 0, {
      message: 'Price should be a number greater than 0'
    })
});

export const AddNewBarModal = ({ isOpen, onClose }: ModalProps) => {
  const { addNotification } = useNotificationsStore();
  const { t } = useTranslation();

  const { control, reset, handleSubmit, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const { pricingSettingsQuery } = useRoomPrices();
  const { data: pricingSettings } = pricingSettingsQuery;
  const { savePricingSettings } = useUpdatePricingSettings();

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const oldPrices = pricingSettings?.predefined_rates;
    const newPrice = data.price;
    const newPrices = isArray(oldPrices) ? oldPrices?.concat(newPrice) : [newPrice];
    const settings = {
      ...pricingSettings,
      predefined_rates: newPrices
    };
    // check if the new price is already in the list
    if (isArray(oldPrices) && oldPrices?.includes(newPrice)) {
      addNotification('fail', 'Price already exists');
    } else {
      await savePricingSettings(JSON.stringify(settings));
      pricingSettingsQuery.refetch();
    }
  };

  return (
    <Modal
      okText={`${t('Save')}`}
      continueIntent="primary"
      size="sm"
      open={isOpen}
      onClick={handleSubmit(onSubmit)}
      disableCloseOnOk={!formState.isValid}
      onClose={onClose}
      children={
        <div className="w-full">
          <Typography className="mb-8" element="h6" color="darkGrey" variant="h6">
            {t('Add New BAR Level')}
          </Typography>
          <Controller
            name="price"
            control={control}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <Input
                name={name}
                background="grey"
                className="w-full"
                type="number"
                value={value}
                label={`${t('Price')}*`}
                leadingAddon={CurrencyFormatter.currencySymbol()}
                placeholder={`${t('Please enter price')}`}
                onChange={onChange}
                onClear={() => reset({ price: 0 })}
                error={!!error}
                helperMessage={
                  error && (
                    <div className="flex items-center gap-2 text-error">
                      {error ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {error ? error.message?.toString() : ''}
                      </Typography>
                    </div>
                  )
                }
              />
            )}
          />
        </div>
      }
    />
  );
};
