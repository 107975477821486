import { PricingSettings } from '@common/api/hotel/types';
import { Typography } from '@common/components/foundations/Typography';
import { Accordion } from '@common/components/molecules/Accordion';
import { API_DATE_FORMAT } from '@common/constants/date';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import styles from '@pages/Client/Calendar/components/PriceDrawerContent/EditPrices/PricingStrategy.module.css';
import { useTranslation } from 'react-i18next';
import { Feature, useFeaturesStore } from '@common/store/features';
import { NO_MARKET_DATA } from '@pages/Client/Calendar/constants';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { useInflation } from '@pages/Client/Calendar/hooks/useInflation';
import { cn } from '@common/utils/cn';
import { useUpselling } from '@pages/Client/hooks/useUpselling';
import { Box, Group } from '@mantine/core';

interface PricingStrategyProps {
  currentViewingDate: Dayjs;
}

export const PricingStrategy = ({ currentViewingDate }: PricingStrategyProps) => {
  const { t } = useTranslation();
  const { selectedHotelRoomId, setSelectedHotelRoomId } = useCalendarPageStore();
  const { shouldOpenPricingStrategy } = usePriceDrawerStore();
  const { pricingSettings, getRoomData, roomPrices } = useRoomPrices();
  const { selectedHotelRoom, sortedHotelRooms } = useHotelRoomsList();
  const { features } = useFeaturesStore();
  const { SparkIcon, setUpsellingModalOpen } = useUpselling();

  const isLeadTimeAdjustmentEnabled = features?.includes(7);

  const viewingDateKey = useMemo(
    () => currentViewingDate.startOf('day').format(API_DATE_FORMAT),
    [currentViewingDate]
  );

  const roomData = useMemo(() => {
    if (!selectedHotelRoomId || !pricingSettings) return;

    return {
      price: getRoomData(selectedHotelRoomId, viewingDateKey),
      overallSettings: pricingSettings.dates[viewingDateKey] ?? {},
      settings: pricingSettings.dates[viewingDateKey]?.[selectedHotelRoomId] ?? {}
    };
  }, [selectedHotelRoomId, viewingDateKey, pricingSettings]);

  const weekdayKey = useMemo(() => {
    return currentViewingDate
      .locale('en')
      .format('dddd')
      .toLowerCase() as keyof PricingSettings['adjustment']['weekday'];
  }, [currentViewingDate]);

  const monthKey = useMemo(() => {
    return currentViewingDate
      .locale('en')
      .format('MMMM') as keyof PricingSettings['adjustment']['monthly'];
  }, [currentViewingDate]);

  const leadTimeAdjustment = useMemo(() => {
    const localDateTime = dayjs().tz().startOf('day');
    const daysDiff = Math.round(Math.abs(currentViewingDate.diff(localDateTime, 'days', true)));

    if (daysDiff == 0) {
      return pricingSettings?.adjustment.lead_time.last_day;
    } else if (daysDiff >= 1 && daysDiff <= 2) {
      return pricingSettings?.adjustment.lead_time.two_days_to_three_days;
    } else if (daysDiff >= 3 && daysDiff <= 6) {
      return pricingSettings?.adjustment.lead_time.four_days_to_seven_days;
    } else if (daysDiff >= 7 && daysDiff <= 13) {
      return pricingSettings?.adjustment.lead_time.one_week_to_two_weeks;
    } else if (daysDiff >= 14 && daysDiff <= 27) {
      return pricingSettings?.adjustment.lead_time.two_weeks_to_four_weeks;
    } else if (daysDiff >= 28 && daysDiff <= 43) {
      return pricingSettings?.adjustment.lead_time.four_weeks_to_six_weeks;
    } else if (daysDiff >= 44 && daysDiff <= 88) {
      return pricingSettings?.adjustment.lead_time.one_half_months_to_three_months;
    } else if (daysDiff >= 89 && daysDiff <= 178) {
      return pricingSettings?.adjustment.lead_time.three_months_to_six_months;
    } else if (daysDiff >= 178) {
      return pricingSettings?.adjustment.lead_time.six_months_plus;
    } else {
      return 0;
    }
  }, [currentViewingDate]);

  const roomTags = useMemo(() => {
    if (!pricingSettings || !selectedHotelRoomId) return [];

    const roomTypeTags = pricingSettings.room_type_tags;
    const yieldTagAdjustments =
      roomPrices?.prices.data?.[viewingDateKey]?.property?.yield_tag_adjustment;

    if (!roomTypeTags) return [];

    return Object.keys(roomTypeTags)
      .map((key) => {
        if (roomTypeTags[key].room_types.includes(selectedHotelRoomId)) {
          return {
            name: roomTypeTags[key].tag_name,
            speed: roomTypeTags[key].speed,
            adj: yieldTagAdjustments ? yieldTagAdjustments?.[key] : ''
          };
        }
      })
      .filter((val): val is Exclude<typeof val, undefined> => !!val);
  }, [selectedHotelRoomId, pricingSettings, roomPrices, currentViewingDate]);

  const isNoMarketData =
    roomPrices?.prices.data?.[viewingDateKey]?.property?.note === NO_MARKET_DATA;

  const { isAutomaticInflationIncrease, inflationByMonth } = useInflation(
    currentViewingDate.toDate()
  );

  if (!roomData?.price) return null;

  const suggestedPriceWithoutRestriction = Math.round(
    isAutomaticInflationIncrease && inflationByMonth
      ? roomData.price.suggested_price_without_restriction * inflationByMonth
      : roomData.price.suggested_price_without_restriction
  );

  const isAbsoluteAdjustment = pricingSettings?.hotel?.adjustment_to_reference_room_is_absolute;

  return (
    <Accordion
      disclosureProps={{
        defaultOpen: shouldOpenPricingStrategy
      }}
      borderless
      className="px-0 text-paragraph-3"
      toggleButtonClassName="pt-4 pb-0"
      title={
        <Typography element="h5" color="grey" className="text-nav-medium">
          {t('Pricing Strategy')}
        </Typography>
      }>
      <div className="mb-2">
        <SelectDropdown
          fullWidth
          background="grey"
          name="roomType"
          value={selectedHotelRoom?.id}
          options={sortedHotelRooms.map((room) => ({
            label: room.name,
            value: room.id
          }))}
          onChange={(value) => setSelectedHotelRoomId(value)}
        />
      </div>
      <div className={cn(shouldOpenPricingStrategy && styles.highlight)}>
        <div className="mb-4 flex justify-between border-b border-b-lightGrey pb-4">
          <Typography color="darkGrey">
            {t('Base Price')} +{' '}
            {features?.includes(Feature.NoMarketData) ? (
              <Group
                role="button"
                gap="4"
                component="span"
                display="inline-flex"
                onClick={() => setUpsellingModalOpen(true)}>
                <Box className="blur-[2px]">
                  {t('Market Factor')} ({t('Disabled')})
                </Box>
                <SparkIcon />
              </Group>
            ) : (
              t('Market Factor')
            )}{' '}
            + {t('Occupancy/Pick-Up Factor')}
          </Typography>

          <Typography color="darkGrey" className="font-medium">
            {isNoMarketData ? 'n.A.' : CurrencyFormatter.format(suggestedPriceWithoutRestriction)}
          </Typography>
        </div>

        <div className="mb-1 flex items-center justify-between">
          <Typography color="darkGrey">
            {t('Day-of-the-Week')} ({currentViewingDate.format('ddd')})
          </Typography>
          <Typography color="darkGrey" className="inline-flex items-center gap-x-5 font-medium">
            {pricingSettings?.adjustment?.weekday?.[weekdayKey]?.standard ?? 'n.A'}%
          </Typography>
        </div>

        {currentViewingDate.isAfter(dayjs().add(365, 'days')) ? null : (
          <div className="mb-1 flex items-center justify-between">
            <Typography color="darkGrey">
              {t('Monthly')} ({currentViewingDate.format('MMM')})
            </Typography>
            <Typography color="darkGrey" className="inline-flex items-center gap-x-5 font-medium">
              {pricingSettings?.adjustment?.monthly?.[monthKey]?.standard ?? 'n.A'}%
            </Typography>
          </div>
        )}

        {isLeadTimeAdjustmentEnabled ? (
          <div className="mb-1 flex items-center justify-between">
            <Typography color="darkGrey">{t('Lead Time Adjustments')}</Typography>
            <Typography color="darkGrey" className="inline-flex items-center gap-x-5 font-medium">
              {leadTimeAdjustment ?? 'n.A'}%
            </Typography>
          </div>
        ) : null}

        {pricingSettings?.features?.includes(9) && (
          <>
            {roomTags.map((tag) => (
              <div key={tag.name} className="mb-1 flex items-center justify-between">
                <Typography color="darkGrey">
                  {t(tag.name)} ({t('Yielding Tag')})
                </Typography>
                <Typography
                  color="darkGrey"
                  className="inline-flex items-center gap-x-5 font-medium">
                  {tag.adj ?? 'n.A'}%
                </Typography>
              </div>
            ))}
          </>
        )}

        <div className="mb-1 flex justify-between">
          <Typography color="darkGrey">{t('Percent Adjustment')}</Typography>
          <Typography color="darkGrey" className="inline-flex items-center gap-x-5 font-medium">
            {roomData?.overallSettings?.[String(selectedHotelRoomId)]?.percentage_adjustment ?? 0}%
          </Typography>
        </div>

        {!selectedHotelRoom?.is_reference_room &&
        roomData?.overallSettings?.[String(selectedHotelRoomId)]?.adjustment_to_reference_room ? (
          <div className="mb-1 flex justify-between">
            <Typography color="darkGrey">
              {t('Derivation (Originally {{originalDerivation}})', {
                originalDerivation: isAbsoluteAdjustment
                  ? CurrencyFormatter.format(
                      Math.round(
                        pricingSettings?.default[String(selectedHotelRoomId)]
                          ?.adjustment_to_reference_room
                      )
                    )
                  : pricingSettings?.default[String(selectedHotelRoomId)]
                      ?.adjustment_to_reference_room + '%'
              })}
            </Typography>
            <Typography color="darkGrey" className="inline-flex items-center gap-x-5 font-medium">
              {isAbsoluteAdjustment
                ? CurrencyFormatter.format(
                    roomData.overallSettings?.[String(selectedHotelRoomId)]
                      .adjustment_to_reference_room
                  )
                : roomData.overallSettings?.[String(selectedHotelRoomId)]
                    .adjustment_to_reference_room + '%'}
            </Typography>
          </div>
        ) : null}

        <div className="mb-1 flex justify-between">
          <Typography color="darkGrey">{t('Minimum Price')}</Typography>
          <Typography color="darkGrey" className="inline-flex items-center gap-x-5 font-medium">
            {CurrencyFormatter.format(
              roomData?.settings?.min_price,
              CurrencyFormatter.format(
                pricingSettings?.default[String(selectedHotelRoomId)]?.min_price
              )
            )}
          </Typography>
        </div>

        <div className="mb-1 flex justify-between">
          <Typography color="darkGrey">{t('Maximum Price')}</Typography>
          <Typography color="darkGrey" className="inline-flex items-center gap-x-5 font-medium">
            {CurrencyFormatter.format(
              roomData?.settings?.max_price,
              CurrencyFormatter.format(
                pricingSettings?.default[String(selectedHotelRoomId)]?.max_price
              )
            )}
          </Typography>
        </div>

        <div className="mb-1 flex justify-between">
          <Typography color="darkGrey">{t('Recommended Price')}</Typography>
          <Typography color="darkGrey" className="inline-flex items-center gap-x-5 font-medium">
            {CurrencyFormatter.format(roomData?.price?.suggested_price)}
          </Typography>
        </div>

        {features?.includes(13) ? (
          <div className="mb-1 flex justify-between">
            <Typography color="darkGrey">{t('Recommended BAR Level')}</Typography>
            <Typography color="darkGrey" className="inline-flex items-center gap-x-5 font-medium">
              {CurrencyFormatter.format(roomData?.price?.suggested_price)}
            </Typography>
          </div>
        ) : null}

        {roomData?.settings.fix_price ? (
          <div className="mb-1 flex justify-between">
            <Typography color="darkGrey">{t('Fix Price')}</Typography>
            <Typography color="darkGrey" className="inline-flex items-center gap-x-5 font-medium">
              {CurrencyFormatter.format(roomData?.price?.price)}
            </Typography>
          </div>
        ) : null}
      </div>
    </Accordion>
  );
};
