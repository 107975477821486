import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import { Page } from '@common/components/organisms/Page';
import { PusherEventNames } from '@common/constants/pusher';
import { useModal } from '@common/hooks/useModal';
import { useNotificationsStore } from '@common/store/notifications';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, join, find } from 'lodash-es';
import { usePusherStore } from '@common/store/pusher';
import { PriceCalculationLoadingModal } from '@pages/Client/Calendar/components/PriceCalculationLoadingModal';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { AddNewSegmentModal } from '@pages/Client/PricingStrategy/Segmentation/components/AddNewSegmentModal';
import { DeleteSegmentModal } from '@pages/Client/PricingStrategy/Segmentation/components/DeleteSegmentModal';
import { EditSegmentModal } from '@pages/Client/PricingStrategy/Segmentation/components/EditSegmentModal';
import { useGetRunPricingRunningStore } from '@pages/Client/PricingStrategy/Segmentation/store/segmentation';
import { useDocumentTitle } from '@mantine/hooks';
import { useAnalyticsDashboardFilters } from '@pages/Client/Dashboard/hooks/useAnalyticsDashboardCache';
import { Skeleton } from '@common/components/atoms/Skeleton';

interface SegmentData {
  id: string;
  name: string;
  segment_tags: string[];
  segments_settings_corrections: boolean;
  segments_price_estimate: boolean;
  segments_pickupboost: boolean;
}

export type SegmentationData = {
  [key: string]: string;
};

export type SegmentationOption = {
  value: string;
  label: string;
};

export const convertToOptionsArray = (segmentationData: SegmentationData): SegmentationOption[] => {
  return Object.keys(segmentationData)?.map((key) => ({
    value: key,
    label: segmentationData[key]
  }));
};

export const Segmentation = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Segmentation'));
  const { channelBind, channelUnbind, channel } = usePusherStore();
  const { pricingSettingsQuery, cachePriceQuery } = useRoomPrices();
  const { data: pricingSettings, isLoading: isPricingLoading } = pricingSettingsQuery;
  const { savePricingSettings, isLoading: isSaveLoading } = useUpdatePricingSettings();
  const {
    analyticsDashboardFilters,
    query: { isLoading: isSegmentDataLoading }
  } = useAnalyticsDashboardFilters();

  const { addNotification } = useNotificationsStore();
  const { isRunPricingRunning, setLoading } = useGetRunPricingRunningStore();

  const [selectedSegment, setSelectedSegment] = useState<SegmentData>();

  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();

  const rawSegmentation = pricingSettings?.segmentation ? pricingSettings?.segmentation : {};

  const segmentData = Object.keys(rawSegmentation).map((id) => ({
    id,
    ...rawSegmentation[id]
  }));

  const segmentationData = (analyticsDashboardFilters?.segments as SegmentationData) || {};
  const availableTags = convertToOptionsArray(segmentationData);

  const handleDeleteSegment = () => {
    const segmentation = pricingSettings?.segmentation;

    if (selectedSegment?.id === undefined || segmentation === undefined) {
      return;
    }
    const updateDataSegment = Object.fromEntries(
      Object.entries(segmentation).filter(([key]) => key !== selectedSegment?.id.toString())
    );
    const settings = {
      ...pricingSettings,
      segmentation: updateDataSegment
    };
    if (selectedSegment?.id) {
      savePricingSettings(JSON.stringify(settings));
      pricingSettingsQuery.refetch();
    } else {
      return;
    }
  };

  const initRecalculationPusher = useCallback(() => {
    channelBind(PusherEventNames.RunPricingMessage, async (data: any) => {
      if (!data.error) {
        const message = `Segments pricing are updated`;
        addNotification('success', message);
        setLoading(false);
        pricingSettingsQuery.refetch();
        cachePriceQuery.refetch();
      }
      if (data.error) {
        const message = data.message ?? `Error while Applying the Changes`;
        addNotification('fail', message);
        setLoading(false);
        pricingSettingsQuery.refetch();
        cachePriceQuery.refetch();
      }
    });
  }, [channel]);

  // Listen to Pusher events
  useEffect(() => {
    channel && initRecalculationPusher();
    return () => {
      channelUnbind(PusherEventNames.RunPricingMessage);
    };
  }, [channel]);

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  const columns = [
    {
      header: t('Segment Name'),
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Segment Tags'),
      accessorKey: 'segment_tags',
      cell: (row: any) => {
        const rowData = row
          ?.getValue()
          .map((value: string) => find(availableTags, { value })?.label);

        const validTags = filter(rowData, (tag) => tag);
        const tagsLabel = join(validTags, ', ');
        return (
          <div className="flex items-center gap-3">
            {!isSegmentDataLoading ? (
              <Typography element="span" color="copyTextGrey">
                {tagsLabel}
              </Typography>
            ) : (
              <Skeleton className="h-5 w-16" />
            )}
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Not representing demand'),
      accessorKey: 'excludeFromDemand',
      cell: (row: any) => {
        const segments_settings_corrections = row.row.original.segments_settings_corrections;
        const segments_pickupboost = row.row.original.segments_pickupboost;
        return (
          <div className="flex items-center gap-3">
            {segments_settings_corrections && segments_pickupboost ? (
              <div className="flex h-6 w-6 items-center justify-center rounded-full bg-uiGreen">
                <Icon.CheckList className="text-white" />
              </div>
            ) : (
              <div className="flex h-6 w-6 items-center justify-center rounded-full bg-uiRed ">
                <Icon.Clear className="text-white" />
              </div>
            )}
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Treat like out of order'),
      accessorKey: 'segments_price_estimate',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            {row?.getValue() ? (
              <div className="flex h-6 w-6 items-center justify-center rounded-full bg-uiGreen">
                <Icon.CheckList className="text-white" />
              </div>
            ) : (
              <div className="flex h-6 w-6 items-center justify-center rounded-full bg-uiRed ">
                <Icon.Clear className="text-white" />
              </div>
            )}
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Delete'),
      accessorKey: 'id',
      cell: (row: any) => {
        const selected = row.row.original;
        return (
          <div className="flex items-center gap-3">
            <Button
              icon
              onClick={() => {
                setSelectedSegment(selected);
                deleteClick();
              }}>
              <Icon.Delete className="text-copyTextGrey" />
            </Button>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const HeaderSegmentation = () => {
    return (
      <div className="flex w-full justify-between py-2">
        <Typography element="h6" variant="h6" color="darkGrey">
          {t('Segmentation')}
        </Typography>
        <div className="flex gap-3">
          <Button onClick={addClick}>{t('Add New Segment')}</Button>
        </div>
      </div>
    );
  };
  return (
    <Page
      header={
        <Header
          title={`${t('Segmentation')}`}
          description={`${t(
            "RoomPriceGenie's Segmentation feature tells the algorithm to treat different types of bookings in different ways. You can set up your segments here, and then all bookings with the relevant codes will be placed in the correct segment. You can then choose that bookings from these segments are excluded from the demand and/or supply calculations within the algorithm."
          )}`}
        />
      }>
      <Table
        isHover
        isRowClickable
        isFetching={isPricingLoading || isSaveLoading}
        headerComponent={<HeaderSegmentation />}
        onClickRow={(row) => {
          setSelectedSegment(row.row.original);
          editClick();
        }}
        columns={columns}
        data={segmentData ? segmentData : []}
      />
      <AddNewSegmentModal isOpen={addOpen} onClose={addClose} />
      <DeleteSegmentModal
        isOpen={deleteOpen}
        onClose={deleteClose}
        name={selectedSegment?.name}
        onDelete={() => handleDeleteSegment()}
      />
      <EditSegmentModal isOpen={editOpen} onClose={editClose} selectedSegment={selectedSegment} />
      <PriceCalculationLoadingModal
        open={isRunPricingRunning}
        progressMessage={t('Applying segments to your prices…') as string}
      />
    </Page>
  );
};
