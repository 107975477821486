import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ErrorResponse } from '@common/api/auth';
import { getAnalyticsDashboardCache, getAnalyticsDashboardFilters } from '@common/api/dashboard';
import {
  AnalyticsDashboardCache,
  AnalyticsDashboardFiltersData
} from '@common/api/dashboard/types';
import { useNotificationsStore } from '@common/store/notifications';
import { ANALYTICS_TYPE_MAP, useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { format, isEqual } from 'date-fns';
import { AnalyticsDashboardQueryKeys } from '@common/types/query-keys';

export const useAnalyticsDashboardCache = () => {
  const { advancedAnalyticsFilters, analyticsFilter } = useDashboardPageStore();
  const { addNotification } = useNotificationsStore();
  const { hotelDetails } = useHotelDetails();
  const { t } = useTranslation();

  const filters = hotelDetails && advancedAnalyticsFilters?.[hotelDetails.id];

  // Check and assign filter_booking_date_to if not present
  if (filters?.filter_booking_date_from && !filters?.filter_booking_date_to) {
    filters.filter_booking_date_to = filters.filter_booking_date_from;
  }
  // Check and assign filter_stay_date_to if not present
  if (filters?.filter_stay_date_from && !filters?.filter_stay_date_to) {
    filters.filter_stay_date_to = filters.filter_stay_date_from;
  }

  const queryKey = advancedAnalyticsFilters
    ? [
        AnalyticsDashboardQueryKeys.GET_ANALYTICS_DASHBOARD_CACHE,
        advancedAnalyticsFilters,
        hotelDetails?.id,
        ANALYTICS_TYPE_MAP[analyticsFilter]
      ]
    : [
        AnalyticsDashboardQueryKeys.GET_ANALYTICS_DASHBOARD_CACHE,
        ANALYTICS_TYPE_MAP[analyticsFilter]
      ];

  const query = useQuery<AnalyticsDashboardCache, ErrorResponse>({
    queryKey,
    queryFn: () =>
      getAnalyticsDashboardCache({
        filters,
        type: ANALYTICS_TYPE_MAP[analyticsFilter]
      }),
    enabled: !!hotelDetails?.id && !!ANALYTICS_TYPE_MAP[analyticsFilter]
  });

  if (query.isError) addNotification('fail', t('Failed to fetch analytics data'));

  const analyticsDashboardCache = useMemo(() => {
    return query.data;
  }, [query.data]);

  const customRangeText = useMemo(() => {
    const from = analyticsDashboardCache?.stay_date_from
      ? new Date(analyticsDashboardCache.stay_date_from)
      : null;
    const to = analyticsDashboardCache?.stay_date_to
      ? new Date(analyticsDashboardCache.stay_date_to)
      : null;

    if (from && to) {
      // Check if the dates are the same, then return only one
      if (isEqual(from, to)) {
        return format(from, 'E, dd LLL y');
      } else {
        return `${format(from, 'E, dd LLL y')} - ${format(to, 'E, dd LLL y')}`;
      }
    }
  }, [analyticsDashboardCache?.stay_date_from, analyticsDashboardCache?.stay_date_to]);

  const getAllYearsFromAnalyticsCache = (): number[] => {
    const data = query.data?.report_table_yearly;
    const yearsSet = new Set<number>();

    if (!data) return [];
    for (const year in data) {
      yearsSet.add(parseInt(year));
    }
    return Array.from(yearsSet).sort((a, b) => a - b);
  };

  return {
    analyticsDashboardCache,
    isCustomDateRange: analyticsDashboardCache?.uses_stay_date_range,
    customRangeText,
    query,
    getAllYearsFromAnalyticsCache
  };
};

export const useAnalyticsDashboardFilters = () => {
  const query = useQuery<AnalyticsDashboardFiltersData, ErrorResponse>({
    queryKey: [AnalyticsDashboardQueryKeys.GET_ANALYTICS_DASHBOARD_FILTERS],
    queryFn: getAnalyticsDashboardFilters
  });

  const analyticsDashboardFilters = useMemo(() => {
    return query.data?.data;
  }, [query.data]);

  return { analyticsDashboardFilters, query };
};
