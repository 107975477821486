import { FormEvent, SyntheticEvent } from 'react';
import { Button } from '@common/components/atoms/Button';
import { useTranslation } from 'react-i18next';
import { Typography } from '@common/components/foundations/Typography';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { EditDateRangeSelect } from '@pages/Client/Calendar/components/BulkEdit/Fragments/EditDateRangeSelect';
import { WeekDayChips } from '@pages/Client/Calendar/components/BulkEdit/Fragments/WeekDayChips';
import { FormProvider, useForm } from 'react-hook-form';
import {
  BulkRemoveSchema,
  bulkRemoveSchema
} from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useBulkEditsStore } from '@pages/Client/Calendar/components/BulkEdit/store/editAdjustmentsStore';
import { AdjustmentsCheckboxes } from '@pages/Client/Calendar/components/BulkEdit/Fragments/AdjustmentsCheckboxes';
import { useNotificationsStore } from '@common/store/notifications';
import { useBulkRemoveTransformData } from '@pages/Client/Calendar/components/BulkEdit/hooks/useTransformFormData';
import { applyIndividualAdjustment, savePricingSettings } from '@common/api/hotel';
import { filter, first, flatten, includes, last, map, some, sortBy } from 'lodash-es';
import { useMutation } from '@tanstack/react-query';
import { ADJUSTMENT_DB } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { BulkEditTabs } from '@pages/Client/Calendar/components/BulkEditTab/BulkEditTabs';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { BulkEditUserflowIds } from '@common/types/userflow-ids';

export const BulkRemove = ({ runPricing }: { runPricing: () => Promise<void> }) => {
  const formDebug = import.meta.env.DEV ? true : false;
  const { t } = useTranslation();
  const { trackEvent } = useEventTracking();
  const { addNotification } = useNotificationsStore();
  const { transformData } = useBulkRemoveTransformData();
  const { cachePriceQuery, pricingSettingsQuery } = useRoomPrices();
  const { mutateAsync: savePricing } = useMutation({ mutationFn: savePricingSettings });
  const { setBulkEditDrawerState, setDateRangeError, setBulkEditViewingDates } =
    useBulkEditsStore();

  const form = useForm<BulkRemoveSchema>({
    mode: 'onChange',
    resolver: async (data, context, options) => {
      const resolver = await zodResolver(bulkRemoveSchema)(data, context, options);
      if (formDebug) console.info({ data, resolver });
      return resolver;
    },
    defaultValues: {
      dateRange: {},
      weekDays: [],
      adjustments: []
    }
  });

  function shouldRerunPricing(formDataRaw: BulkRemoveSchema) {
    const adjustments = flatten(map(filter(formDataRaw.adjustments, { value: true }), 'meta'));
    const shouldTriggerReRun = some(adjustments, (key) =>
      includes(
        [ADJUSTMENT_DB.AGGRESSIVENESS_KEY, ADJUSTMENT_DB.MEDIAN_KEY, ADJUSTMENT_DB.CLOSED_KEY],
        key
      )
    );
    if (shouldTriggerReRun) runPricing();
  }

  function handleClose() {
    setBulkEditDrawerState(false);
    setDateRangeError(undefined);
    setBulkEditViewingDates(undefined);
    form.reset();
  }

  async function handleFormSave(formDataRaw: BulkRemoveSchema) {
    try {
      const { newPricing, dates } = await transformData(formDataRaw);
      const newPricingString = JSON.stringify(newPricing);
      await savePricing(newPricingString);
      await applyIndividualAdjustment({
        end_date: last(sortBy(dates)) as string,
        start_date: first(sortBy(dates)) as string,
        json_settings: newPricingString
      });
      addNotification('success', 'Data Updated Successfully');
    } catch (error) {
      addNotification('fail', String(t('Something went wrong, please try again later')));
    } finally {
      cachePriceQuery.refetch();
      pricingSettingsQuery.refetch();
      handleClose();
      shouldRerunPricing(formDataRaw);
      trackEvent('BulkRemoveApplied');
    }
  }

  async function onSubmit(e: FormEvent) {
    e?.preventDefault();
    await form.handleSubmit(handleFormSave)(e);
  }

  function handleCancel(e: SyntheticEvent) {
    e?.preventDefault();
    handleClose();
  }

  return (
    <FormProvider {...form}>
      <div className="relative h-full">
        <div className="h-full px-5 pb-10 md:px-10">
          <div className="h-full overflow-auto px-0.5">
            <div className="sticky top-0 z-10 mb-5 bg-white bg-opacity-70 backdrop-blur">
              <BulkEditTabs />
            </div>
            <div className="flex flex-col pb-16">
              <div className="flex gap-x-7">
                <div className="flex flex-1 flex-col gap-1">
                  <Typography color="darkGrey">{t('Select a Date Range')}</Typography>
                  <EditDateRangeSelect />
                </div>
              </div>

              <ScrollArea>
                <div
                  className="mb-2 mt-6 grid w-full grid-cols-7 gap-2"
                  data-userflow-id={BulkEditUserflowIds.WEEKDAY_CHIPS}>
                  <WeekDayChips />
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>

              <div className="mt-6">
                <Typography
                  color="darkGrey"
                  variant="paragraph-1"
                  element="div"
                  className="mb-2 text-paragraph-1-medium">
                  {t('Select adjustments to remove')}
                </Typography>
                <AdjustmentsCheckboxes />
              </div>
            </div>

            <div className="absolute bottom-0 right-0 w-full rounded-bl-xl border-t border-grey-reduced bg-lightGrey p-5 text-right">
              <div className="flex justify-end gap-2">
                <Button
                  type="button"
                  intent="text"
                  onClick={(e: SyntheticEvent) => handleCancel(e)}
                  data-userflow-id={BulkEditUserflowIds.CANCEL_BUTTON}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="button"
                  onClick={(e: SyntheticEvent) => onSubmit(e)}
                  disabled={form?.formState?.isSubmitting}
                  isLoading={form?.formState?.isSubmitting}
                  data-userflow-id={BulkEditUserflowIds.REMOVE_BUTTON}>
                  {t('Remove')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};
